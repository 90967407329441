import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import PartnerEngine from "core/engine/PartnerEngine";
import PartnerReducer from "core/redux/partner/PartnerReducer";
import {PartnerState} from "core/state/PartnerState";

export class PartnerService implements Service<PartnerEngine, any> {

    private static _instance: PartnerService;

    private constructor() {
    }

    public static instance(): PartnerService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Partner;
    }

    public reducers(): Array<Reducer<any>> {
        return [PartnerReducer.instance()];
    }

    public engine(): PartnerEngine {
        return PartnerEngine.instance();
    }

    public state(): PartnerState {
        return PartnerState.instance();
    }
}
