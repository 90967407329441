enum TutorialType {

    HowToAutomateTrade = "how-to-automate-trade",
    HowToOpenTrade = "how-to-open-trade",
    HowToCloseTrade = "how-to-close-trade",
    HowToUseTradeBtn = "how-to-use-trade-button",
    HowToUseSlTp = "how-to-use-stop-loss-and-take-profit",
}

export {
    TutorialType
};
