import {Action, LazyDispatch, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {TSMap} from "typescript-map";
import Utils from "platform/util/Utils";
import {OnAccountChanged, OnLoggedOut} from "core/redux/auth/AuthReduxActions";
import {FilterDateState, FilterDateType, FilterReduxState, FilterSymbolState} from "core/redux/filter/FilterReduxState";
import {
    OnFilterReset,
    PortfolioDateFilterPayload,
    ResetFilter,
    ResetFilterPayload,
    ResetFilterType,
    SetClosedTradesDefaultFilter,
    SetClosedTradesDefaultFilterPayload,
    SetFilterVisible,
    SetFilterVisiblePayload,
    SetHistoryOrderStatus,
    SetHistoryOrderStatusPayload,
    SetMarketsStatus,
    SetMarketsStatusPayload,
    SetPortfolioDateFilter,
    SetSymbolFilterContinent,
    SetSymbolFilterContinentPayload,
    SetSymbolFilterContinentType,
    UpdatePortfolioDateFilter,
} from "core/redux/filter/FilterReduxActions";
import {PortfolioType} from "enum/PortfolioType";
import {Routes, RouteType} from "core/router/Routes";
import DateTimeFormat from "core/format/DateTimeFormat";
import {SetPortfolioTab, SetPortfolioTabPayload} from "core/redux/portfolio/PortfolioReduxActions";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import FilterEngine from "core/engine/FilterEngine";
import {EntryOrderStatus} from "platform/protocol/trading/EntryOrderStatus";
import {SetPartnerPortfolioTabPayload, SetPartnerTab} from "core/redux/partner/PartnerReduxActions";

const CreateDateFilters = (isPartner?: boolean): TSMap<PortfolioType, FilterDateState> => {
    const defaultDateState: FilterDateState = {
        dateType: FilterDateType.All,
    };
    const filters: TSMap<PortfolioType, FilterDateState> =  new TSMap();
    filters.set(PortfolioType.OpenTrades, {...defaultDateState});
    filters.set(PortfolioType.EntryOrders, {...defaultDateState});
    filters.set(PortfolioType.ClosedTrades, {...defaultDateState, ...{
            dateType: isPartner ? FilterDateType.ThisWeek : FilterDateType.Today
        }});
    filters.set(PortfolioType.ActivityLog, {...defaultDateState, ...{
            dateType: FilterDateType.Today
        }});
    filters.set(PortfolioType.OrdersHistory, {...defaultDateState, ...{
            dateType: FilterDateType.ThisWeek,
            orderStatus: [EntryOrderStatus.Executed]
        }});
    return filters;
};

const initialState = (): FilterReduxState => {
    return {
        symbolFilter: {
            continents: []
        },
        portfolioDateFilter: CreateDateFilters(),
        partnerDateFilter: CreateDateFilters(true),
    };
};

export default class FilterReducer extends Reducer<FilterReduxState> {

    private static _instance: FilterReducer;

    public static instance(): FilterReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const filterEngine: FilterEngine = Platform.engine(ServiceType.Filter);
        this._middlewareActions.set("@@router5/TRANSITION_START", filterEngine.onChangeRoute);
        this._middlewareActions.set(SetSymbolFilterContinentType, filterEngine.doSetSymbolFilterContinent);
        this._middlewareActions.set(ResetFilterType, filterEngine.doResetFilter);
    }

    public get name(): string {
        return "filter";
    }

    protected setup(builder: ReducerBuilder<FilterReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: FilterReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    symbolFilter: state.symbolFilter
                };
            })
            .handle(OnAccountChanged, (state: FilterReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(SetSymbolFilterContinent, (state: FilterReduxState, {payload}: Action<SetSymbolFilterContinentPayload>) => {
                const newState: FilterReduxState = Utils.merge({}, state);
                const filter: FilterSymbolState = {...newState.symbolFilter};
                filter.continents = payload.continents;
                newState.symbolFilter = filter;
                return newState;
            })
            .handle(SetMarketsStatus, (state: FilterReduxState, {payload}: Action<SetMarketsStatusPayload>) => {
                const newState: FilterReduxState = Utils.merge({}, state);
                const {marketStatus} = payload;
                const filter: FilterSymbolState = {...newState.symbolFilter};
                filter.marketStatus = marketStatus;
                newState.symbolFilter = filter;
                return newState;
            })
            .handle(SetFilterVisible, (state: FilterReduxState, {payload}: Action<SetFilterVisiblePayload>) => {
                const newState: FilterReduxState = Utils.merge({}, state);
                const {route, visible, activePortfolioTab} = payload;
                newState.filterVisible = visible;
                const isPartnerRoute: boolean = Routes.IsPartnerRoute(route);
                if (visible && (route === RouteType.MyTrades || isPartnerRoute)) {
                    const Filters: TSMap<PortfolioType, FilterDateState> = isPartnerRoute ? newState.partnerDateFilter : newState.portfolioDateFilter;
                    const filter: FilterDateState = Filters.get(activePortfolioTab);
                    if (Utils.isNull(filter.from) || Utils.isNull(filter.to)) {
                        Filters.set(activePortfolioTab, Object.assign({}, filter, {
                            from: DateTimeFormat.now().startOf("day"),
                            to: DateTimeFormat.now().endOf("day")
                        }));
                    }
                }
                return newState;
            })
            .handle(SetPortfolioTab, (state: FilterReduxState, {payload}: Action<SetPortfolioTabPayload>) => {
                return this.InitPortfolioFilter(state, RouteType.MyTrades, payload.portfolioType);
            })
            .handle(SetPartnerTab, (state: FilterReduxState, {payload}: Action<SetPartnerPortfolioTabPayload>) => {
                return this.InitPortfolioFilter(state, RouteType.FollowedAccounts, payload.portfolioType);
            })
            .handle(SetPortfolioDateFilter, (state: FilterReduxState, {payload}: Action<PortfolioDateFilterPayload>) => {
                return this.UpdatePortfolioFilter(state, payload);
            })
            .handle(UpdatePortfolioDateFilter, (state: FilterReduxState, {payload}: Action<PortfolioDateFilterPayload>) => {
                return this.UpdatePortfolioFilter(state, payload);
            })
            .handle(SetClosedTradesDefaultFilter, (state: FilterReduxState, {payload}: Action<SetClosedTradesDefaultFilterPayload>) => {
                return Object.assign({}, state, {
                    closedTradesDefaultFilter: payload.dateType,
                });
            })
            .handle(ResetFilter, (state: FilterReduxState, {payload}: Action<ResetFilterPayload>, dispatch: LazyDispatch) => {
                const newState: FilterReduxState = Utils.merge({}, state);
                const {routeName, activePortfolioTab} = payload;
                const isPartnerRoute: boolean = Routes.IsPartnerRoute(routeName);
                if (routeName === RouteType.MyTrades || routeName === RouteType.Filters || isPartnerRoute) {
                    const defaultDateType: FilterDateType = activePortfolioTab === PortfolioType.ClosedTrades ? isPartnerRoute ? FilterDateType.ThisWeek : state.closedTradesDefaultFilter :
                        activePortfolioTab === PortfolioType.OrdersHistory ? FilterDateType.ThisWeek :
                            activePortfolioTab === PortfolioType.ActivityLog ? FilterDateType.Today : FilterDateType.All;

                    const Filters: TSMap<PortfolioType, FilterDateState> = isPartnerRoute ? newState.partnerDateFilter : newState.portfolioDateFilter;
                    const filter: FilterDateState = Object.assign({}, Filters.get(activePortfolioTab), {
                        dateType: defaultDateType,
                        from: DateTimeFormat.now().startOf("day"),
                        to: DateTimeFormat.now().endOf("day")
                    });
                    if (activePortfolioTab === PortfolioType.OrdersHistory) {
                        filter.orderStatus = [EntryOrderStatus.Executed];
                    }
                    Filters.set(activePortfolioTab, filter);
                    dispatch(OnFilterReset({
                        portfolioType: activePortfolioTab,
                        dateType: defaultDateType,
                        routeName
                    })).catch(() => {
                    });
                } else if (routeName === RouteType.Symbols) {
                    newState.symbolFilter = {
                        continents: []
                    };
                }
                return newState;
            })
            .handle(SetHistoryOrderStatus, (state: FilterReduxState, {payload}: Action<SetHistoryOrderStatusPayload>) => {
                const newState: FilterReduxState = Utils.merge({}, state);
                const {orderStatus, routeName} = payload;
                const Filters: TSMap<PortfolioType, FilterDateState> = Routes.IsPartnerRoute(routeName) ? newState.partnerDateFilter : newState.portfolioDateFilter;
                const filter: FilterDateState = Filters.get(PortfolioType.OrdersHistory);
                filter.orderStatus = orderStatus;
                return newState;
            });
    }

    private InitPortfolioFilter(state: FilterReduxState, route: RouteType, portfolioType: PortfolioType): FilterReduxState {
        if (portfolioType !== PortfolioType.FinancialSummary && portfolioType !== PortfolioType.AvailableFeatures) {
            const isPartnerRoute: boolean = Routes.IsPartnerRoute(route);
            const Filters: TSMap<PortfolioType, FilterDateState> = isPartnerRoute ? state.partnerDateFilter : state.portfolioDateFilter;
            const filter: FilterDateState = Filters.get(portfolioType);
            if (Utils.isNotNull(filter) && (Utils.isNull(filter.from) || Utils.isNull(filter.to))) {
                const newState: FilterReduxState = Utils.merge({}, state);
                const NewFilters: TSMap<PortfolioType, FilterDateState> = isPartnerRoute ? newState.partnerDateFilter : newState.portfolioDateFilter;
                NewFilters.set(portfolioType, Object.assign({}, filter, {
                    from: DateTimeFormat.now().startOf("day"),
                    to: DateTimeFormat.now().endOf("day")
                }));
                return newState;
            }
        }
        return state;
    }

    private UpdatePortfolioFilter(state: FilterReduxState, payload: PortfolioDateFilterPayload): FilterReduxState {
        const {routeName, portfolioType, dateType, from, to} = payload;
        const newState: FilterReduxState = Utils.merge({}, state);
        const Filters: TSMap<PortfolioType, FilterDateState> = Routes.IsPartnerRoute(routeName) ? newState.partnerDateFilter : newState.portfolioDateFilter;
        const filter: FilterDateState = Object.assign({}, Filters.get(portfolioType), {
            dateType
        });
        if (from && to) {
            filter.from = from.startOf("day");
            filter.to = to.endOf("day");
        }
        this._logger.debug(`Set portfolio date filter for: ${portfolioType}, route: ${routeName}. From: ${filter.from} To: ${filter.to}`);
        Filters.set(portfolioType, filter);
        return newState;
    }
}
