export type StylesKeys = 'popover';

export type StylesObj = {
    [key in StylesKeys]?: StyleFn
}

export type StyleFn = (
    props: { [key: string]: any },
    rtl: boolean,
    state?: { [key: string]: any },
) => React.CSSProperties

export type Styles = {
    popover: StyleFn
}

export type StyleKey = keyof Styles

export const defaultStyles: Styles = {
    popover: (props, rtl, state) => ({
        position: 'fixed',
        padding: 0,
        color: 'inherit',
        zIndex: 100000,
        transition: 'transform 0.3s',
        top: 0,
        left: 0,
    }),
}

export type getStylesType = (key: StylesKeys, extra?: any) => object;

export function stylesMatcher(styles: StylesObj) {
    return (key: StyleKey, rtl: boolean, state: object): object => {
        const base = defaultStyles[key]({}, rtl, state);
        const custom = styles[key];
        return custom ? custom(base, rtl, state) : base;
    }
}

type AdditionalStylesFn = (rtl: boolean) => { [key: string]: any; }

export const makeTourPopoverStyles = (additionalStylesFn: AdditionalStylesFn): StyleFn => {
    return (base: StylesObj, rtl, state) => {
        return ({
            padding: 0,
            ...base,
            ...(additionalStylesFn && additionalStylesFn(rtl)),
        })
    };
}

