import Utils from "platform/util/Utils";

export class PartnerState {

    private static _instance: PartnerState;
    private _connectingAccountIds: number[] = [];

    private constructor() {
    }

    public static instance(): PartnerState {
        return this._instance || (this._instance = new this());
    }

    public addConnectingAccount(accountId: number): void {
        this._connectingAccountIds.push(accountId);
    }

    public hasConnectingAccounts(accountId: number): boolean {
        return this._connectingAccountIds.indexOf(accountId) > -1;
    }

    public getConnectingAccounts(): number[] {
        return [...this._connectingAccountIds];
    }

    public removeConnectingAccount(accountId: number): void {
        Utils.remove(this._connectingAccountIds, accountId);
    }
}
