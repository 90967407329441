import {Currency} from "platform/enum/Currency";

export enum SymbolType {

    FX = 1,
    CFD = 2,
    Invest = 3,
}

export interface TradeSymbol {

    category?: string; // "FX"
    decimals?: string; // "4"
    id?: string; // "AUDCAD|tradenetworks"
    isOverridden?: boolean; // For academy
    provider?: string; // "tradenetworks"
    symbol?: string; // "AUDCAD" Primary + Secondary asset names from BO config
    symbolId?: string; // "1"
    description?: string; // Description block from BO config
    text?: string; // "AUDCAD" Display Name from BO config,
    tradingCentralRICCode?: string; // "AUDCAD=R",
    countryCodes?: string[]; // list of country code that symbol belongs to
    SymbolId?: number; // 1 - Client internal field
    symbolCode?: string;
    symbolType?: string;
    secondaryAssetName?: Currency;
    marketData?: TradeSymbolMarketData;

    // TODO Need to drop this properties once all native app users migrated to 9.8 version with "marketData" object usage
    marketCap?: number;
    revenue?: number;
    industry?: string;
    revenueAssetName?: Currency;
}

export interface TradeSymbolMarketData {

    marketCap?: number;
    revenue?: number;
    industry?: string;
    revenueAssetName?: Currency;
    category?: string;
}
