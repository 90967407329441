import React from "react";
import {makeTourPopoverStyles} from "component/tour/popover/styles";
import {Tutorial,PopoverContentProps} from "component/tour/types";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "enum/TranslationKey";
import {TutorialIDs} from "core/tutorial/TutorialIDs";
import WebUtil from "platform/util/WebUtil";
import {TutorialType} from "enum/TutorialType";
import {onTutorialFinish} from "component/lessons/tutorials/TutorialHandler";

const isMobile: boolean = WebUtil.isMobile();

export const howToAutomateTrade: Tutorial = {
    id: TutorialType.HowToAutomateTrade,
    title: TranslationKey.tourLesson3Title,
    onFinish: onTutorialFinish,
    steps: [
        {
            id: TutorialIDs.howToAutomateTradeStep1,
            selector: isMobile ? '[id="_wt_mmnu_create"]' : '[id="_wt_mmnu_trade"]',
            content: () => Translations.text(TranslationKey.tourLesson3Step1Title),
            position: isMobile ? "top" : "right",
            disableActions: true,
            cancelOnOutside: true,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean)=> ({
                    left: isMobile ? "-6vw" : "0",
                    top: isMobile ? "0px" : "12px",
                })),
            }
        },
        {
            id: TutorialIDs.howToAutomateTradeStep2,
            selector: '[id="_wt_modal_symbol_Search"]',
            position: "top",
            disableActions: true,
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson3Step2Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson3Step2Text)}</div>
            </>,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean)=> ({
                    left: isMobile ? (rtl ? "0": "15px") : "0px",
                })),
                popupArrow:  (rtl) => ({
                    left: "12%",
                }),
            }
        },
        {
            id: TutorialIDs.howToAutomateTradeStep3,
            selector: '[class="btn-group"]',
            position: "bottom",
            disableActions: false,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean)=> ({
                    top: isMobile ? "-8px" : "0px",
                    width: isMobile ? "318px" : "initial",
                    left: isMobile ? "calc(50% - 150px)" : "25px",
                })),
            },
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson3Step3Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson3Step3Text)}</div>
            </>,
        },
        {
            id: TutorialIDs.howToAutomateTradeStep4,
            selector: '[id="_wt_sidebar_trade_type_name"]',
            position: "top",
            disableActions: false,
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson3Step4Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson3Step4Text)}</div>
            </>,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean)=> ({
                    width: isMobile ? "332px" : "initial",
                    left: isMobile ? "calc(50% - 172px)" : "0",
                })),
            }
        },
        {
            id: TutorialIDs.howToAutomateTradeStep5,
            selector: '.wt_buy_sell_btn',
            position: "top",
            disableActions: false,
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson3Step5Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson3Step5Text)}</div>
            </>,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean)=> ({
                    width: isMobile ? "332px" : "initial",
                    left: isMobile ? "calc(50% - 172px)" : rtl ? "60px" : "30px",
                })),
            },
        },
    ],
}
