import {Environment} from "platform/enum/Environment";

enum StorageKey {

    LoginUrl = "__lurl",
    AccountType = "__act",
    Accounts = "__acts",
    UserId = "__usdi",
    IsRealUser = "__isru",
    Environment = "__env",
    PortfolioSort = "__ps",
    AlertsSort = "__as",
    SymbolsSort = "__ss",
    SignalsSort = "__siso",
    SignalsSettings = "__sis",
    ShowChartDesktop = "__sciqdl",
    ShowChartMobile = "__sciqml",
    CompactListView = "__mclvl",
    ChartMobileY = "__cmyl",
    ChartMobileAcY = "__cmacyl",
    ChartMobileArY = "__cmaryl",
    PopupSound = "__pps",
    Emails = "__pem",
    PushNotifications = "__ppn",
    PhoneCalls = "__ppc",
    SMS = "__psms",
    AcademySelectedAccountId = "__asaid",
    AcademyWatchedVideos = "__awv",
    SelectedContinents = "_scont",
    SelectedBroker = "__sb",
    InboxTab = "__inbt",
    ExpandedView = "__expv",
    SignalsUnlockTime = "__sunt",
    BiometricAuthenticationPublicKey = "__bap",
    ShowFinancialInfoPanel = "__sfip",
    SuggestionNotifications = "__sn",
    NotificationPermissionsRequested = "__npr",
    AppleAuthorizedUsersInfo = "__aaui",
}

namespace StorageKey {

    export function hasKey(key: string): boolean {
        for (const sk in StorageKey) {
            if (StorageKey[sk] === key) {
                return true;
            }
        }
        return false;
    }

    export function UsernameKey(brandId: number, environment: Environment): string {
        return `__usn_${brandId}_${environment}`;
    }

    export function PasswordKey(brandId: number, environment: Environment): string {
        return `__#%$*()_${brandId}_${environment}`;
    }

    export function BiometricAuthenticationKey(environment?: string): string {
        return `__bioa_${environment || Environment.environment()}`;
    }

    export function AppleAuthorizedUsersInfoKey(bundleId: string, brandId: number): string {
        return `__aaui_${bundleId}_${brandId}_${Environment.environment()}`;
    }

    export function SelectedEnvironmentKey(): string {
        return `__useEnv_${Environment.environment()}`;
    }
}

export {
    StorageKey
};
