import React from "react";
import {Tutorial} from "component/tour/types";
import {TutorialIDs} from "core/tutorial/TutorialIDs";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "enum/TranslationKey";
import {makeTourPopoverStyles} from "component/tour/popover/styles";
import WebUtil from "platform/util/WebUtil";
import Platform from "platform/Platform";
import {SetPortfolioTab} from "core/redux/portfolio/PortfolioReduxActions";
import {PortfolioType} from "enum/PortfolioType";
import {TutorialType} from "enum/TutorialType";
import {onTutorialFinish} from "component/lessons/tutorials/TutorialHandler";

const isMobile: boolean = WebUtil.isMobile();

export const howToCloseTrade: Tutorial = {
    id: TutorialType.HowToCloseTrade,
    title: TranslationKey.tourLesson2Title,
    onFinish: onTutorialFinish,
    steps: [
        {
            id: TutorialIDs.howToCloseTradeStep1,
            selector: '[id="_wt_mmnu_myTrades"]',
            content: () => Translations.text(TranslationKey.tourLesson2Step1Title),
            position: isMobile ? "top" : "right",
            disableActions: true,
            cancelOnOutside: true,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean) => ({
                    left: isMobile ? "-6vw" : rtl ? "20px" : "-50px",
                })),
            }
        },
        {
            id: TutorialIDs.howToCloseTradeStep2,
            selector: isMobile ? '[id="_wt_portfolio_openTrades"]' : ".trading-page-title.myTrades .page-title",
            position: "bottom",
            disableActions: true,
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson2Step2Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson2Step2Text)}</div>
            </>,
            action: () => {
                Platform.dispatch(SetPortfolioTab({portfolioType: PortfolioType.OpenTrades}));
            },
            styles: {
                popover: makeTourPopoverStyles((rtl)=> ({
                    width: "280px",
                    top: isMobile ? "0px" : "-10px",
                    left: isMobile ? (rtl ? "-180px" : "15px") : (rtl ? "-190px": "0px"),
                })),
                popupArrow: (rtl) => ({
                    left: isMobile ? (rtl ? "initial" : "12%") : (rtl ? "initial" : "12%"),
                    right: isMobile ? (rtl ? "18%" : "initial") : (rtl ? "15%" : "initial"),
                }),
            }
        },
        {
            id: TutorialIDs.howToCloseTradeStep3,
            selector: '[class="details-container"]',
            position: "top",
            disableActions: false,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean) => ({
                    top: isMobile ? "-8px" : "18px",
                    width: isMobile ? "318px" : "initial",
                    left: isMobile ? "calc(50% - 150px)" : "23px",
                })),
                popupArrow: (rtl) => ({
                    left: rtl ? "initial" : "12%",
                    right: rtl ? "18%" : "initial",
                }),
            },
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson2Step3Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson2Step3Text)}</div>
            </>,
        },
        {
            id: TutorialIDs.howToCloseTradeStep4,
            selector: '[id="_wt_symPan_close_trade"]',
            position: "top",
            disableActions: true,
            cancelOnOutside: true,
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson2Step4Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson2Step4Text)}</div>
            </>,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean) => ({
                    top: isMobile ? "40px" : "0px",
                    width: isMobile ? "318px" : "initial",
                    left: isMobile ? (rtl ? "-100px" : "20px") : "0px",
                })),
                popupArrow: (rtl) => ({
                    left: isMobile ? (rtl ? "initial" : "12%") : "12%",
                    right: isMobile ? (rtl ? "34%" : "initial") : "initial",
                }),
            },
        },
        {
            id: TutorialIDs.howToCloseTradeStep5,
            selector: '.trading-modal-action.ok.done',
            position: "top",
            disableActions: true,
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson2Step5Title)}</div>
            </>,
        },
        {
            id: TutorialIDs.howToCloseTradeStep6,
            selector: '[id="_wt_portfolio_closedTrades"]',
            position: isMobile ? "bottom" : "top",
            disableActions: false,
            mutationObservables: ['[class="trading-tabs"]'],
            resizeObservables: ['[class="trading-tabs"]'],
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson2Step6Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson2Step6Text)}</div>
            </>,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean) => ({
                    left: isMobile ? "0px" : "-150px",
                })),
                popupArrow: (rtl) => ({
                    left: isMobile ? (rtl ? "initial" : "60%") : "calc(50% - 8px)",
                    right: isMobile ? (rtl ? "80%" : "initial") : "initial",
                }),
            },
        },
    ],
}
