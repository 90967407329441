import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {PartnerReduxState} from "core/redux/partner/PartnerReduxState";
import {PortfolioType} from "enum/PortfolioType";
import {
    CreateFollowedAccountType,
    GetFollowedAccountsType,
    SetPartnerTab,
    SetSelectedPartnerAccountId,
    SetFollowedAccounts,
    SetFollowedAccountsPayload,
    DeleteFollowedAccountType,
    SetPartnerAccountClosedTrades,
    SetPartnerAccountOrderHistory,
    SetPartnerLoading,
    SetPartnerLoadingPayload,
    RefreshFollowedAccountsType,
    SetLastRefreshDate,
    SetLastRefreshDatePayload,
    SetPartnerAccountLogs,
    SetPartnerPendingData,
    SetPartnerAccountsEnabled,
    SetPartnerPortfolioTabPayload,
    FollowedAccountPayload,
    SetFollowedAccount,
    SetFollowedAccountPayload,
    RemoveFollowedAccount,
    SetSelectedPartnerAccountIdType,
    SelectFollowedAccountPayload,
    SetPartnerAccountCounter,
    SetPartnerTabType,
    PartnerPortfolioCounterPayload,
    SetPartnerPositionsOrders,
    SetPartnerPositionsOrdersPayload,
    SetPartnerFinancialSummaryPromotions,
    SetPartnerFinancialSummaryPromotionsPayload,
    SetExtendedPosition,
    SetExtendedPositionPayload,
    GetExtendedPositionType,
    SetExtendedEntryOrder,
    SetExtendedEntryOrderPayload,
    SetPartnerQuotes,
    SetPartnerQuotesPayload,
    SetPartnerServerError,
    SetPartnerServerErrorPayload,
    SetPartnerAggregateData,
    SetPartnerAggregateDataPayload,
    SetPartnerAggregateDataShort,
    SetPartnerAggregateDataShortPayload
} from "core/redux/partner/PartnerReduxActions";
import {BooleanPayload} from "../StoreActions";
import {TSMap} from "typescript-map";
import {PartnerAccount} from "protocol/partner/GetFollowedAccountsResponse";
import PartnerEngine from "core/engine/PartnerEngine";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import Utils from "platform/util/Utils";
import {SetAccountLogsPayload, SetPortfolioTradeColumn, SetPortfolioTradeColumnPayload, TogglePortfolioColumnSort} from "core/redux/portfolio/PortfolioReduxActions";
import {PaginationDealsTablePayload} from "core/redux/trades/ClosedTradesReduxActions";
import {DefaultPaginationDealsTable, PaginationDealsTable} from "core/redux/trades/ClosedTradesReduxState";
import {SortTrades, SortTradesPayload} from "core/redux/trades/TradesReduxActions";
import {SortDeals} from "core/engine/TradesEngine";
import {OnFilterResetType, SetHistoryOrderStatusType, SetPortfolioDateFilterType} from "core/redux/filter/FilterReduxActions";
import {SortDirection} from "platform/enum/SortDirection";
import {DefaultPortfolioTablesStates, PortfolioTableState} from "../portfolio/PortfolioReduxState";
import {Routes} from "core/router/Routes";
import {TradeColumn} from "enum/TradeColumn";
import {SortTrades as EngineSortTrades} from "core/engine/TradesEngine";
import {Trade} from "core/redux/trades/TradesReduxState";
import {Quote} from "platform/protocol/trading/Quote";
import {FollowedAccountAggregateDataShort} from "protocol/account/GetAccountStateResponse";
import {Configuration} from "core/configuration/Configuration";
import WebUtil from "platform/util/WebUtil";

const EmptyAccountDetails = (): PartnerReduxState => {
    const {IsEduTrading} = Platform.config<Configuration>();
    return {
        activePortfolioTab: IsEduTrading && WebUtil.isMobile() ? null : PortfolioType.OpenTrades,
        counters: new TSMap(),
        lastRefreshDate: new TSMap(),
        serverError: new TSMap<PortfolioType, string>(),
        Quotes: new TSMap<number, Quote>(),
        Trades: [],
        EntryOrders: [],
        OpenPositionsMap: {},
        ActivityLogs: [],
        AggregateDataShort: new TSMap<number, FollowedAccountAggregateDataShort>(),
        AggregateData: {},
        AvailableFeatures: {
            PositionPromotionSettings: []
        }
    };
}

const initialState = (): PartnerReduxState => {
    return {
        tables: DefaultPortfolioTablesStates(),
        Accounts: new TSMap<number, PartnerAccount>(),
        ClosedTradesTable: Utils.deepCopy(DefaultPaginationDealsTable),
        OrdersHistoryTable: Utils.deepCopy(DefaultPaginationDealsTable),
        ...EmptyAccountDetails(),
    }
};

export default class PartnerReducer extends Reducer<PartnerReduxState> {

    private static _instance: PartnerReducer;

    public static instance(): PartnerReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const partnerEngine: PartnerEngine = Platform.engine(ServiceType.Partner);
        this._middlewareActions.set(GetFollowedAccountsType, partnerEngine.doGetFollowedAccounts);
        this._middlewareActions.set(CreateFollowedAccountType, partnerEngine.doCreateFollowedAccount);
        this._middlewareActions.set(DeleteFollowedAccountType, partnerEngine.doDeleteFollowedAccount);
        this._middlewareActions.set(SetSelectedPartnerAccountIdType, partnerEngine.OnSetSelectedPartnerAccountId);
        this._middlewareActions.set(RefreshFollowedAccountsType, partnerEngine.doRefreshFollowedAccounts);
        this._middlewareActions.set(SetPartnerTabType, partnerEngine.doSetPartnerTab);
        this._middlewareActions.set(SetPortfolioDateFilterType, partnerEngine.onSetPartnerDateFilter);
        this._middlewareActions.set(SetHistoryOrderStatusType, partnerEngine.onSetHistoryOrderStatus);
        this._middlewareActions.set(OnFilterResetType, partnerEngine.onSetPartnerDateFilter);
        this._middlewareActions.set(GetExtendedPositionType, partnerEngine.doGetExtendedPosition);
    }

    public get name(): string {
        return "partner";
    }

    protected setup(builder: ReducerBuilder<PartnerReduxState>): void {
        builder
            .init({
                ...initialState()
            })
            .handle(SetPartnerAccountsEnabled, (state: PartnerReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    IsEnabled: payload.value
                });
            })
            .handle(SetPartnerTab, (state: PartnerReduxState, {payload}: Action<SetPartnerPortfolioTabPayload>) => {
                return Object.assign({}, state, {
                    activePortfolioTab: payload.portfolioType
                });
            })
            .handle(SetSelectedPartnerAccountId, (state: PartnerReduxState, {payload}: Action<SelectFollowedAccountPayload>) => {
                this._logger.debug(`Set active partner account: ${payload.account.Id}`);
                return Object.assign({}, state, {
                    selectedPartnerAccountId: payload.account.Id,
                    ...EmptyAccountDetails()
                });
            })
            .handle(SetFollowedAccounts, (state: PartnerReduxState, {payload}: Action<SetFollowedAccountsPayload>) => {
                return Object.assign({}, state, {
                    Accounts: payload.Accounts
                });
            })
            .handle(SetFollowedAccount, (state: PartnerReduxState, {payload}: Action<SetFollowedAccountPayload>) => {
                const {account} = payload;
                const newState: PartnerReduxState = Utils.merge({}, state);
                const Accounts: TSMap<number, PartnerAccount> = new TSMap();
                let AccountReplaced: boolean;
                state.Accounts.forEach((ac: PartnerAccount) => {
                    if (ac.FollowedAccountId !== account.FollowedAccountId) {
                        Accounts.set(ac.Id, ac);
                    } else {
                        AccountReplaced = true;
                        Accounts.set(account.Id, account);
                    }
                });
                if (!AccountReplaced) {
                    Accounts.set(account.Id, account);
                }
                newState.Accounts = Accounts;
                return newState;
            })
            .handle(RemoveFollowedAccount, (state: PartnerReduxState, {payload}: Action<FollowedAccountPayload>) => {
                const newState: PartnerReduxState = Utils.merge({}, state);
                newState.Accounts.delete(payload.id);
                if (payload.id === state.selectedPartnerAccountId) {
                    newState.selectedPartnerAccountId = null;
                }
                return newState;
            })
            .handle(SetPartnerLoading, (state: PartnerReduxState, {payload}: Action<SetPartnerLoadingPayload>) => {
                return Object.assign({}, state, {
                    loading: payload.loading
                });
            })
            .handle(SetPartnerQuotes, (state: PartnerReduxState, {payload}: Action<SetPartnerQuotesPayload>) => {
                return Object.assign({}, state, {
                    Quotes: payload.Quotes,
                });
            })
            .handle(SetPartnerPositionsOrders, (state: PartnerReduxState, {payload}: Action<SetPartnerPositionsOrdersPayload>) => {
                return Object.assign({}, state, {
                    Trades: payload.Trades,
                    OpenPositionsMap: payload.OpenPositionsMap,
                    EntryOrders: payload.EntryOrders,
                });
            })
            .handle(SetPartnerAggregateDataShort, (state: PartnerReduxState, {payload}: Action<SetPartnerAggregateDataShortPayload>) => {
                return Object.assign({}, state, {
                    AggregateDataShort: payload.AggregateDataShort
                });
            })
            .handle(SetPartnerAggregateData, (state: PartnerReduxState, {payload}: Action<SetPartnerAggregateDataPayload>) => {
                return Object.assign({}, state, {
                    AggregateData: payload.AggregateData
                });
            })
            .handle(SetPartnerFinancialSummaryPromotions, (state: PartnerReduxState, {payload}: Action<SetPartnerFinancialSummaryPromotionsPayload>) => {
                return Object.assign({}, state, {
                    AggregateData: payload.AggregateData,
                    AvailableFeatures: payload.AvailableFeatures
                });
            })
            .handle(SetPartnerAccountCounter, (state: PartnerReduxState, {payload}: Action<PartnerPortfolioCounterPayload>) => {
                const newState: PartnerReduxState = Utils.merge({}, state);
                newState.counters.set(payload.portfolioType, {
                    count: payload.count,
                    filterCount: payload.filterCount
                });
                return newState;
            })
            .handle(SetLastRefreshDate, (state: PartnerReduxState, {payload}: Action<SetLastRefreshDatePayload>) => {
                const newState: PartnerReduxState = Utils.merge({}, state);
                payload.tabs.forEach(tab => newState.lastRefreshDate.set(tab, payload.date));
                return newState;
            })
            .handle(SetPartnerServerError, (state: PartnerReduxState, {payload}: Action<SetPartnerServerErrorPayload>) => {
                const newState: PartnerReduxState = Utils.merge({}, state);
                payload.tabs.forEach(tab => newState.serverError.set(tab, payload.error));
                return newState;
            })
            .handle(SetPartnerAccountLogs, (state: PartnerReduxState, {payload}: Action<SetAccountLogsPayload>) => {
                return Object.assign({}, state, {
                    ActivityLogs: payload.logs
                });
            })
            .handle(SetPartnerPendingData, (state: PartnerReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    pendingData: payload.value
                });
            })
            .handle(SetPartnerAccountClosedTrades, (state: PartnerReduxState, {payload}: Action<PaginationDealsTablePayload>) => {
                return Object.assign({}, state, {
                    ClosedTradesTable: payload.table
                });
            })
            .handle(SetPartnerAccountOrderHistory, (state: PartnerReduxState, {payload}: Action<PaginationDealsTablePayload>) => {
                return Object.assign({}, state, {
                    OrdersHistoryTable: payload.table
                });
            })
            .handle(SetPortfolioTradeColumn, (state: PartnerReduxState, {payload}: Action<SetPortfolioTradeColumnPayload>) => {
                const newState: PartnerReduxState = Utils.merge({}, state);
                if (Routes.IsPartnerRoute(payload.routeName)) {
                    const portfolioTab = state.activePortfolioTab;
                    const tableState: PortfolioTableState = newState.tables.get(portfolioTab);
                    tableState.activeColumn = payload.tradeColumn;
                    tableState.sort.set(payload.tradeColumn, SortDirection.Ask);
                    setTimeout(() => {
                        Platform.dispatch(SortTrades({
                            portfolioType: portfolioTab,
                            column: payload.tradeColumn,
                            sortDirection: SortDirection.Ask,
                            routeName: payload.routeName
                        }));
                    }, 0);
                }
                return newState;
            })
            .handle(TogglePortfolioColumnSort, (state: PartnerReduxState, {payload}: Action<SetPortfolioTradeColumnPayload>) => {
                const newState: PartnerReduxState = Utils.merge({}, state);
                if (Routes.IsPartnerRoute(payload.routeName)) {
                    const portfolioTab = state.activePortfolioTab;
                    const tableState: PortfolioTableState = newState.tables.get(portfolioTab);
                    const sort: TSMap<TradeColumn, SortDirection> = tableState.sort;
                    const sortDirection: SortDirection = sort.get(payload.tradeColumn);
                    const resultDirection: SortDirection = sortDirection ? SortDirection.reverse(sortDirection) : SortDirection.Ask;
                    sort.set(payload.tradeColumn, resultDirection);
                    setTimeout(() => {
                        Platform.dispatch(SortTrades({
                            portfolioType: portfolioTab,
                            column: payload.tradeColumn,
                            sortDirection: resultDirection,
                            routeName: payload.routeName
                        }));
                    }, 0);
                }
                return newState;
            })
            .handle(SortTrades, (state: PartnerReduxState, {payload}: Action<SortTradesPayload>) => {
                if (Routes.IsPartnerRoute(payload.routeName)) {
                    if (payload.portfolioType === PortfolioType.OpenTrades) {
                        const Trades: Trade[] = [...state.Trades];
                        Trades.sort(EngineSortTrades(payload.column, payload.sortDirection));
                        Trades.forEach((trade: Trade) => {
                            if (Utils.isArrayNotEmpty(trade.trades)) {
                                trade.trades = [...trade.trades];
                                trade.trades.sort(EngineSortTrades(payload.column, payload.sortDirection));
                            }
                        });
                        return Object.assign({}, state, {
                            Trades
                        });
                    } else if (payload.portfolioType === PortfolioType.EntryOrders) {
                        return Object.assign({}, state, {
                            EntryOrders: [...state.EntryOrders].sort(SortDeals(payload.column, payload.sortDirection))
                        });
                    } else if (payload.portfolioType === PortfolioType.ClosedTrades) {
                        const ClosedTradesTable: PaginationDealsTable = Utils.merge({}, state.ClosedTradesTable);
                        ClosedTradesTable.deals = [...ClosedTradesTable.deals].sort(SortDeals(payload.column, payload.sortDirection))
                        return Object.assign({}, state, {
                            ClosedTradesTable
                        });
                    } else if (payload.portfolioType === PortfolioType.OrdersHistory) {
                        const OrdersHistoryTable: PaginationDealsTable = Utils.merge({}, state.OrdersHistoryTable);
                        OrdersHistoryTable.deals = [...OrdersHistoryTable.deals].sort(SortDeals(payload.column, payload.sortDirection))
                        return Object.assign({}, state, {
                            OrdersHistoryTable
                        });
                    }
                }
                return state;
            })
            .handle(SetExtendedPosition, (state: PartnerReduxState, {payload}: Action<SetExtendedPositionPayload>) => {
                return Object.assign({}, state, {
                    extendedPosition: payload.position
                });
            })
            .handle(SetExtendedEntryOrder, (state: PartnerReduxState, {payload}: Action<SetExtendedEntryOrderPayload>) => {
                return Object.assign({}, state, {
                    extendedEntryOrder: payload.order
                });
            });
    }
}

