import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {TSMap} from "typescript-map";
import Utils from "platform/util/Utils";
import {OnLoggedOut, SetAuthStatusType} from "core/redux/auth/AuthReduxActions";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {LessonsReduxState} from "core/redux/lessons/LessonsReduxState";
import LessonsEngine from "core/engine/LessonsEngine";
import {
    FetchUIConfigurationType,
    SetLDAcademyConfiguration,
    SetLDAcademyConfigurationPayload,
    SetAcademyVideo,
    SetAcademyVideoPayload, SetAcademyVideoType,
    SetTour, SetTourPayload
} from "core/redux/lessons/LessonsReduxActions";
import {SetLDPlatformPropsType} from "core/redux/app/AppReduxActions";
import {LDAcademyVideo} from "core/props/LDPlatformProps";

const initialState = (): LessonsReduxState => {
    return {
        tutorials: [],
        academyVideoGroups: [],
        academyVideos: new TSMap<number, LDAcademyVideo[]>(),
        supportZendesk: {},
        academyVideo: {}
    };
};

export default class LessonsReducer extends Reducer<LessonsReduxState> {

    private static _instance: LessonsReducer;

    public static instance(): LessonsReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const lessonsEngine: LessonsEngine = Platform.engine(ServiceType.Lessons);
        this._middlewareActions.set(SetLDPlatformPropsType, lessonsEngine.onSetLDPlatformProps);
        this._middlewareActions.set(SetAuthStatusType, lessonsEngine.doSetAuthStatus);
        this._middlewareActions.set(FetchUIConfigurationType, lessonsEngine.doFetchUIConfiguration);
        this._middlewareActions.set(SetAcademyVideoType, lessonsEngine.doSetAcademyVideo);
    }

    public get name(): string {
        return "lessons";
    }

    protected setup(builder: ReducerBuilder<LessonsReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: LessonsReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    tutorials: state.tutorials,
                    academyVideoGroups: state.academyVideoGroups,
                    academyVideos: state.academyVideos,
                    supportZendesk: state.supportZendesk
                };
            })
            .handle(SetLDAcademyConfiguration, (state: LessonsReduxState, {payload}: Action<SetLDAcademyConfigurationPayload>) => {
                const newState: LessonsReduxState = Utils.merge({}, state);
                newState.tutorials = payload.tutorials;
                newState.academyVideoGroups = payload.academyVideoGroups;
                newState.academyVideos = payload.academyVideos;
                newState.supportZendesk = payload.supportZendesk;
                return newState;
            })
            .handle(SetAcademyVideo, (state: LessonsReduxState, {payload}: Action<SetAcademyVideoPayload>) => {
                const newState: LessonsReduxState = Utils.merge({}, state);
                newState.academyVideo = payload.video;
                return newState;
            })
            .handle(SetTour, (state: LessonsReduxState, {payload}: Action<SetTourPayload>) => {
                const newState: LessonsReduxState = Utils.merge({}, state);
                newState.tourId = payload.tourId;
                return newState;
            });
    }
}
