import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {
    OnAccountChanged, OnLoggedOut,
    SetAuthStatusType
} from "core/redux/auth/AuthReduxActions";
import {TSMap} from "typescript-map";
import Utils from "platform/util/Utils";
import {
    CategoryCounterState,
    SymbolReduxState,
    SymbolSelection,
} from "core/redux/symbols/SymbolReduxState";
import {
    AddSubscribedSymbols,
    AddSubscribedSymbolsPayload,
    AddSymbolToSubscribe,
    AddSymbolToSubscribePayload,
    ClearSymbolsToSubscribe,
    FailedFetchSymbols,
    RemoveSubscribedSymbol,
    RemoveSubscribedSymbolsPayload,
    SelectSymbol,
    SelectSymbolPayload,
    SetSortedSymbols,
    SetSortedSymbolsPayload,
    SetSubscribedSymbols,
    SetSubscribedSymbolsPayload,
    SetSymbols,
    SetSymbolsCounters,
    SetSymbolsCountersPayload,
    SetSymbolsPayload,
    SetSymbolTab,
    SetSymbolTablesLS,
    SetSymbolTablesLSPayload,
    SetSymbolTabPayload,
    SetSymbolTradeColumnType,
    SetTopSymbols,
    SetTopSymbolsPayload,
    SubscribeSymbolsType,
    ToggleSymbolColumnSortType,
    UnSubscribeSymbolType
} from "core/redux/symbols/SymbolsReduxActions";
import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import SymbolsEngine, {SortSymbols} from "core/engine/SymbolsEngine";
import {SymbolCategory} from "enum/SymbolCategory";
import {StorageKey} from "enum/StorageKey";
import {SetAccountInfoType} from "core/redux/account/AccountReduxActions";
import {ResetFilter, ResetFilterPayload, SetSymbolFilterContinentType} from "core/redux/filter/FilterReduxActions";
import {FilterUtil} from "core/util/FilterUtil";
import {RouteType} from "core/router/Routes";
import {TableSortState} from "core/redux/common/TableSortState";
import {SetLangCodeType} from "platform/redux/translation/TranslationActions";
import {SetSelectedPartnerAccountIdType} from "core/redux/partner/PartnerReduxActions";

const initialState = (): SymbolReduxState => {
    const categories: TSMap<string, TradeSymbol[]> = new TSMap();
    const counters: TSMap<string, CategoryCounterState> = new TSMap();
    categories.set(SymbolCategory.MyWatchlist, []);
    counters.set(SymbolCategory.MyWatchlist, {
        categoryName: SymbolCategory.MyWatchlist,
        count: 0
    });
    return {
        activeCategoryTab: SymbolCategory.MyWatchlist,
        symbols: new TSMap(),
        overview: [],
        topSymbols: [],
        categories,
        counters,
        selectedSymbols: new TSMap(),
        subscribedSymbols: new TSMap(),
        symbolsToSubscribe: new TSMap(),
        tables: new TSMap(),
    };
};

export default class SymbolsReducer extends Reducer<SymbolReduxState> {

    private static _instance: SymbolsReducer;

    public static instance(): SymbolsReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const symbolsEngine: SymbolsEngine = Platform.engine(ServiceType.Symbols);
        this._middlewareActions.set(SetAccountInfoType, symbolsEngine.doSetAccountInfo);
        this._middlewareActions.set(SetAuthStatusType, symbolsEngine.doSetAuthStatus);
        this._middlewareActions.set(SetLangCodeType, symbolsEngine.doSetLangCode);
        this._middlewareActions.set(SetSelectedPartnerAccountIdType, symbolsEngine.OnSetSelectedPartnerAccountId);
        this._middlewareActions.set(SubscribeSymbolsType, symbolsEngine.doSubscribeSymbols);
        this._middlewareActions.set(UnSubscribeSymbolType, symbolsEngine.doUnSubscribeSymbol);
        this._middlewareActions.set(SetSymbolTradeColumnType, symbolsEngine.doSetSymbolTradeColumn);
        this._middlewareActions.set(ToggleSymbolColumnSortType, symbolsEngine.doToggleSymbolTradeColumn);
        this._middlewareActions.set(SetSymbolFilterContinentType, symbolsEngine.doSetSymbolFilterContinent);
    }

    public get name(): string {
        return "symbols";
    }

    protected setup(builder: ReducerBuilder<SymbolReduxState>): void {
        builder
            .init({
                ...initialState(),
            })
            .handle(OnLoggedOut, (state: SymbolReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    activeCategoryTab: state.activeCategoryTab,
                    tables: state.tables,
                };
            })
            .handle(OnAccountChanged, (state: SymbolReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    activeCategoryTab: state.activeCategoryTab,
                    tables: state.tables,
                };
            })
            .handle(SetSymbolTablesLS, (state: SymbolReduxState, {payload}: Action<SetSymbolTablesLSPayload>) => {
                return Object.assign({}, state, {
                    tables: payload.tables,
                });
            })
            .handle(SetSymbols, (state: SymbolReduxState, {payload}: Action<SetSymbolsPayload>) => {
                const newState: SymbolReduxState = Utils.merge({}, state);
                newState.symbols = payload.symbols;
                newState.overview = payload.overview;
                newState.categories = payload.categories;
                newState.counters = payload.counters;
                newState.selectedSymbols = payload.selectedSymbols;
                newState.tables = payload.tables;
                newState.symbolsLoaded = true;
                return newState;
            })
            .handle(FailedFetchSymbols, (state: SymbolReduxState, {}: Action<any>) => {
                return Object.assign({}, state, {
                    symbolsLoaded: true
                });
            })
            .handle(SetTopSymbols, (state: SymbolReduxState, {payload}: Action<SetTopSymbolsPayload>) => {
                const newState: SymbolReduxState = Utils.merge({}, state);
                newState.topSymbols = payload.symbols;
                return newState;
            })
            .handle(SetSubscribedSymbols, (state: SymbolReduxState, {payload}: Action<SetSubscribedSymbolsPayload>) => {
                return Object.assign({}, state, {
                    subscribedSymbols: payload.symbols
                });
            })
            .handle(SetSymbolsCounters, (state: SymbolReduxState, {payload}: Action<SetSymbolsCountersPayload>) => {
                return Object.assign({}, state, {
                    counters: payload.counters
                });
            })
            .handle(ResetFilter, (state: SymbolReduxState, {payload}: Action<ResetFilterPayload>) => {
                if (payload.routeName === RouteType.Symbols) {
                    const counters: TSMap<string, CategoryCounterState> = new TSMap<string, CategoryCounterState>();
                    state.counters.forEach((counter: CategoryCounterState, categoryName: string) => {
                        const symbols: TradeSymbol[] = state.categories.get(categoryName);
                        counters.set(categoryName, {
                            categoryName,
                            count: symbols?.length,
                            filterCount: symbols?.length,
                        });
                    });
                    return Object.assign({}, state, {
                        counters
                    });
                }
                return state;
            })
            .handle(AddSubscribedSymbols, (state: SymbolReduxState, {payload}: Action<AddSubscribedSymbolsPayload>) => {
                const newState: SymbolReduxState = Utils.merge({}, state);
                const overview: TradeSymbol[] = [...payload.symbols, ...state.overview];
                const myWatchSymbols: TradeSymbol[] = [...payload.symbols, ...state.categories.get(SymbolCategory.MyWatchlist)];
                const myWatchTableState: TableSortState = state.tables.get(SymbolCategory.MyWatchlist);
                const selectedSymbol: SymbolSelection = newState.selectedSymbols.get(SymbolCategory.MyWatchlist);
                myWatchSymbols.sort(SortSymbols(myWatchTableState.activeColumn, myWatchTableState.sort.get(myWatchTableState.activeColumn), payload.quotes));
                newState.overview = overview;
                newState.categories.set(SymbolCategory.MyWatchlist, myWatchSymbols);
                newState.counters.set(SymbolCategory.MyWatchlist, {
                    categoryName: SymbolCategory.MyWatchlist,
                    count: myWatchSymbols.length,
                    filterCount: myWatchSymbols.filter((ts: TradeSymbol) => FilterUtil.filterSymbol(payload.symbolFilter, ts)).length
                });
                if (!selectedSymbol || !selectedSymbol.symbol) {
                    newState.selectedSymbols.set(SymbolCategory.MyWatchlist, {
                        symbol: payload.symbols[0],
                        index: 0
                    });
                }
                return newState;
            })
            .handle(RemoveSubscribedSymbol, (state: SymbolReduxState, {payload}: Action<RemoveSubscribedSymbolsPayload>) => {
                const newState: SymbolReduxState = Utils.merge({}, state);
                const myWatchSymbols: TradeSymbol[] = state.categories.get(SymbolCategory.MyWatchlist).filter((symbol: TradeSymbol) => symbol.SymbolId !== payload.symbol.SymbolId);
                newState.categories.set(SymbolCategory.MyWatchlist, myWatchSymbols);
                newState.overview = state.overview.filter((symbol: TradeSymbol) => symbol.SymbolId !== payload.symbol.SymbolId);
                newState.counters.set(SymbolCategory.MyWatchlist, {
                    categoryName: SymbolCategory.MyWatchlist,
                    count: myWatchSymbols.length,
                    filterCount: myWatchSymbols.filter((ts: TradeSymbol) => FilterUtil.filterSymbol(payload.symbolFilter, ts)).length
                });
                const selectedSymbol: SymbolSelection = newState.selectedSymbols.get(SymbolCategory.MyWatchlist);
                if (selectedSymbol?.symbol?.SymbolId === payload.symbol.SymbolId) {
                    newState.selectedSymbols.set(SymbolCategory.MyWatchlist, {
                        symbol: myWatchSymbols[0],
                        index: 0
                    });
                }
                return newState;
            })
            .handle(SetSymbolTab, (state: SymbolReduxState, {payload}: Action<SetSymbolTabPayload>) => {
                return Object.assign({}, state, {
                    activeCategoryTab: payload.category
                });
            })
            .handle(SetSortedSymbols, (state: SymbolReduxState, {payload}: Action<SetSortedSymbolsPayload>) => {
                const {category, tradeColumn, sortDirection, symbols} = payload;
                const newState: SymbolReduxState = Utils.merge({}, state);
                const tables: TSMap<string, TableSortState> = newState.tables;
                const tableState: TableSortState = tables.get(category);
                tableState.activeColumn = tradeColumn;
                tableState.sort.set(tradeColumn, sortDirection);
                SymbolCategory.serialize(StorageKey.SymbolsSort, tables);
                const map: TSMap<string, TradeSymbol[]> = newState.categories;
                map.set(category, symbols);
                return newState;
            })
            .handle(SelectSymbol, (state: SymbolReduxState, {payload}: Action<SelectSymbolPayload>) => {
                const category: string = payload.category ? payload.category : state.activeCategoryTab === SymbolCategory.MyWatchlist ? SymbolCategory.MyWatchlist : payload.symbol.category;
                const newState: SymbolReduxState = Utils.merge({}, state);
                const symbols: TradeSymbol[] = newState.categories.get(category);
                let index: number = 0;
                for (let i = 0; i < symbols.length; i++) {
                    if (symbols[i].SymbolId === payload.symbol.SymbolId) {
                        index = i;
                        break;
                    }
                }
                newState.selectedSymbols.set(category, {
                    symbol: payload.symbol,
                    index
                });
                return newState;
            })
            .handle(AddSymbolToSubscribe, (state: SymbolReduxState, {payload}: Action<AddSymbolToSubscribePayload>) => {
                const symbol: TradeSymbol = payload.symbol;
                const newState: SymbolReduxState = Utils.merge({}, state);
                const symbolsToSubscribe: TSMap<number, TradeSymbol> = newState.symbolsToSubscribe;
                if (!symbolsToSubscribe.has(symbol.SymbolId)) {
                    symbolsToSubscribe.set(symbol.SymbolId, symbol);
                } else {
                    symbolsToSubscribe.delete(symbol.SymbolId);
                }
                return Object.assign({}, state, {
                    symbolsToSubscribe
                });
            })
            .handle(ClearSymbolsToSubscribe, (state: SymbolReduxState, {payload}: Action<any>) => {
                const newState: SymbolReduxState = Utils.merge({}, state);
                newState.symbolsToSubscribe.clear();
                return newState;
            });
    }
}
