import {Logger, LogLevel} from "platform/logger/Logger";
import {ConfigurationLoader} from "platform/configuration/ConfigurationLoader";
import Utils from "platform/util/Utils";
import {LangCode} from "platform/enum/LangCode";
import {Http} from "platform/network/http/Http";
import {Configuration} from "core/configuration/Configuration";

export default class ConfigManager {

    private static _logger: Logger = Logger.Of("ConfigManager");
    private static _loader: ConfigurationLoader<Configuration>;
    private static _config: Configuration = {
        logLevel: LogLevel.DEBUG as any,
        brand: "-",
        supportedLanguages: [LangCode.EN],
        getAccountStateInterval: 500,
        activeTraderIdleTimeBeforeLogoff: 3600000,
        inActiveTraderIdleTimeBeforeLogoff: 1200000,
        disclaimerForExternalProviders: true,
        hideAnnualReport: false,
        blockedMenuRoutes: [],
        blockedTools: [],
        blockedBanners: [],
        signUp: {
            validation: {
                password: []
            }
        },
        ResetPassword: [],
        EconomicCalendar: {
            Filter: {
                countries: []
            }
        },
        LegalData: {},
        Settings: {
            MyTrades: {
                ExpandedListView: false
            }
        }
    };

    private constructor() {
    }

    public static config(): Configuration {
        return this._config;
    }

    public static loader(): ConfigurationLoader<Configuration> {
        if (Utils.isNull(this._loader)) {
            this._loader = {
                load(): Promise<Configuration> {
                    return new Promise<Configuration>(
                        (resolve, reject) => {
                            Http.getJson("resources/configuration/config.json").then((config: Configuration) => {
                                ConfigManager._logger.info("Configuration loaded");
                                try {
                                    const result: Configuration = Object.assign({}, ConfigManager._config, config);
                                    const parts: string [] = window?.location?.hostname?.split(".");
                                    const domain: string = `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
                                    const placeholder: string = "{@domain}";
                                    result.amazonPathIconSymbol = result.amazonPathIconSymbol.replace(placeholder, domain);
                                    result.amazonOrigin = result.amazonOrigin.replace(placeholder, domain);
                                    result.chartUrl = result.chartUrl.replace(placeholder, domain);
                                    if (result.servers) {
                                        Object.keys(result.servers).forEach((key: string) => {
                                            const url: string = result.servers[key];
                                            if (url?.indexOf(placeholder) > 0) {
                                                result.servers[key] = url?.replace(placeholder, domain);
                                            }
                                        });
                                    }
                                    ConfigManager._config = result;
                                    resolve(result);
                                } catch (e) {
                                    ConfigManager._logger.warn("Failed to merge configuration. " + JSON.stringify(e) + " Going to use hardcoded configuration");
                                    resolve(ConfigManager._config);
                                }
                            }).catch((e) => {
                                ConfigManager._logger.warn("Failed to load configuration: " + JSON.stringify(e));
                                reject(new Error());
                            });
                        }
                    );
                }
            };
        }
        return this._loader;
    }
}
