import DateTimeFormat from "core/format/DateTimeFormat";
import {FilterDateState, FilterDateType} from "core/redux/filter/FilterReduxState";
import {
    CreateFollowedAccountPayload,
    FollowedAccountPayload,
    GetExtendedPositionPayload,
    RemoveFollowedAccount,
    SelectFollowedAccountPayload,
    SetExtendedEntryOrder,
    SetExtendedPosition,
    SetFollowedAccount,
    SetPartnerPendingData,
    SetFollowedAccounts,
    SetLastRefreshDate,
    SetPartnerAccountClosedTrades,
    SetPartnerAccountCounter,
    SetPartnerAccountLogs,
    SetPartnerAccountOrderHistory,
    SetPartnerFinancialSummaryPromotions,
    SetPartnerLoading,
    SetPartnerPortfolioTabPayload,
    SetPartnerPositionsOrders,
    SetSelectedPartnerAccountId,
    SetPartnerQuotes,
    SetPartnerServerError,
    SetPartnerAggregateData,
} from "core/redux/partner/PartnerReduxActions";
import {StoreState} from "core/redux/StoreState";
import {RouteType} from "core/router/Routes";
import {AccountState} from "core/state/AccountState";
import {FilterUtil} from "core/util/FilterUtil";
import {XhrUtil} from "core/util/XhrUtil";
import {PortfolioType} from "enum/PortfolioType";
import {ServiceType} from "enum/ServiceType";
import moment from "moment-mini";
import {Engine} from "platform/engine/Engine";
import {HttpReject} from "platform/network/http/Http";
import Platform from "platform/Platform";
import {EntryOrderStatus} from "platform/protocol/trading/EntryOrderStatus";
import {OrderType} from "platform/protocol/trading/OrderType";
import {DoFetchBrandProps, HideLoader, NavigateTo, SetBrandPropsUrl, SetLoader} from "platform/redux/core/CoreActions";
import Utils from "platform/util/Utils";
import WebUtil from "platform/util/WebUtil";
import {AccountLog} from "protocol/account/AccountLog";
import {GetAccountLogRequest} from "protocol/account/GetAccountLogRequest";
import {CreateFollowedAccountRequest} from "protocol/partner/CreateFollowedAccountRequst";
import {DeleteFollowedAccountRequest} from "protocol/partner/DeleteFollowedAccountRequest";
import {GetFollowedAccountsResponse, PartnerAccount} from "protocol/partner/GetFollowedAccountsResponse";
import {GetClosedTradesRequest} from "protocol/trade/GetClosedTradesRequest";
import {GetClosedTradesResponse} from "protocol/trade/GetClosedTradesResponse";
import {GetEntryOrdersHistoryRequest} from "protocol/trade/GetEntryOrdersHistoryRequest";
import {GetEntryOrdersHistoryResponse} from "protocol/trade/GetEntryOrdersHistoryResponse";
import {TSMap} from "typescript-map";
import {LoaderType} from "platform/enum/LoaderType";
import {DoFetchPlatformProps, SetTradingModal, ToggleMenu} from "core/redux/app/AppReduxActions";
import TradingModalType from "enum/TradingModalType";
import {GetAccountStateRequest} from "protocol/account/GetAccountStateRequest";
import {AccountAggregateData, GetAccountStateResponse} from "protocol/account/GetAccountStateResponse";
import {PortfolioDateFilterPayload, SetHistoryOrderStatusPayload} from "core/redux/filter/FilterReduxActions";
import {ClosedPositionsThreshold, OrdersHistoryThreshold} from "core/Constants";
import {PortfolioTableState} from "core/redux/portfolio/PortfolioReduxState";
import {SortTrades as SortTradesAction} from "core/redux/trades/TradesReduxActions";
import {IsRollingEnabledResponse} from "protocol/account/IsRollingEnabledResponse";
import {Trade} from "core/redux/trades/TradesReduxState";
import {Exposure} from "protocol/trade/Exposure";
import {TradeUtil} from "core/util/TradeUtil";
import {Deal} from "platform/protocol/trading/Deal";
import {PositionDirection} from "platform/protocol/enum/PositionDirection";
import {SortDeals, SortTrades} from "core/engine/TradesEngine";
import {TradeColumn} from "enum/TradeColumn";
import {SortDirection} from "platform/enum/SortDirection";
import {TradeType} from "protocol/trade/TradeType";
import {GetExtendedPositionRequest} from "protocol/trade/GetExtendedPositionRequest";
import {GetVaultDetailsResponse} from "protocol/promo/GetVaultDetailsResponse";
import {GetVaultDetailsRequest} from "protocol/promo/GetVaultDetailsRequest";
import {Quote} from "platform/protocol/trading/Quote";
import {GetAccountLogResponse} from "protocol/account/GetAccountLogResponse";
import {Configuration} from "core/configuration/Configuration";
import {PartnerState} from "core/state/PartnerState";
import {SetAccountInfo} from "core/redux/account/AccountReduxActions";
import {StorageKey} from "enum/StorageKey";
import SettingsEngine from "core/engine/SettingsEngine";

export default class PartnerEngine extends Engine {

    private static _instance: PartnerEngine;

    public static instance(): PartnerEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
    }

    public static IsTokenValid = (token: string): boolean => {
        return Utils.isNotEmpty(token)
            && Utils.greaterThen0(parseInt(token))
            && (token.length === 18 || token.length === 20);
    };

    public doGetFollowedAccounts = async () => {
        Platform.dispatch(SetPartnerLoading({loading: true}));
        const answer: [HttpReject, GetFollowedAccountsResponse] = await Utils.to(this.sendToWebProfitService({}, "GetFollowedAccounts"));
        Platform.dispatch(SetPartnerLoading({loading: false}));

        if (answer[0]) {
            this._logger.debug("Failed fetch followed accounts");
        } else if (Utils.isArrayNotEmpty(answer[1].Accounts)) {
            const {partner} = Platform.reduxState<StoreState>();
            const Accounts: TSMap<number, PartnerAccount> = new TSMap();
            let SelectedAccount: PartnerAccount;
            answer[1].Accounts.forEach((account: PartnerAccount) => {
                if (account.IsEnabled || !Platform.config<Configuration>().IsEduTrading) {
                    Accounts.set(account.Id, account);
                    if (account.Id === partner.selectedPartnerAccountId) {
                        SelectedAccount = account;
                    }
                }
            });
            Platform.dispatch(SetFollowedAccounts({Accounts}));
            if (SelectedAccount) {
                await this.doGetPositionOrders({account: SelectedAccount});
            } else {
                const sAccountId: string = await Platform.storage().getItem(StorageKey.AcademySelectedAccountId);
                let account: PartnerAccount;
                if (sAccountId) {
                    const AccountId: number = parseInt(sAccountId);
                    account = Accounts.values().filter((ac: PartnerAccount) => ac.Id === AccountId)[0];
                }
                Platform.dispatch(SetSelectedPartnerAccountId({
                    account: account || Accounts.values()[0]
                }));
            }
        }
    }

    public doCreateFollowedAccount = async ({Code}: CreateFollowedAccountPayload) => {
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const request: CreateFollowedAccountRequest = {
            Code
        };
        const answer: [HttpReject, PartnerAccount] = await Utils.to(this.sendToWebProfitService(request, "CreateFollowedAccount"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            const {Accounts, selectedPartnerAccountId} = Platform.reduxState<StoreState>().partner;
            const account: PartnerAccount = Accounts.values().filter(a => a.ShareInformationTokenId === Code)[0];
            if (account) {
                Platform.dispatch(
                    SetTradingModal({
                        tradingModalType: TradingModalType.AddFollowedAccount,
                        info: {
                            visible: false,
                        },
                    }));
            }
            XhrUtil.notifyReject(answer[0], () => {
                if (account && account.Id !== selectedPartnerAccountId) {
                    Platform.dispatch(SetSelectedPartnerAccountId({
                        account
                    }));
                }
            });
        } else {
            PartnerState.instance().addConnectingAccount(answer[1].Id);
            Platform.dispatch(
                SetTradingModal({
                    tradingModalType: TradingModalType.AddFollowedAccount,
                    info: {
                        visible: false,
                    },
                }));
            Platform.dispatch(SetFollowedAccount({account: answer[1]}));
            Platform.dispatch(SetSelectedPartnerAccountId({
                account: answer[1]
            }));
        }
    }

    public doDeleteFollowedAccount = async ({id, action}: FollowedAccountPayload) => {
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const request: DeleteFollowedAccountRequest = {
            id
        };
        const answer: [HttpReject, any] = await Utils.to(this.sendToWebProfitService(request, "DeleteFollowedAccount"));
        Platform.dispatch(HideLoader({}));

        if (answer[0]) {
            this._logger.debug("Failed to delete followed account");
            XhrUtil.notifyReject(answer[0]);
        } else {
            const {IsEduTrading} = Platform.config<Configuration>();
            Platform.dispatch(RemoveFollowedAccount({id}));
            Platform.dispatch(
                SetTradingModal({
                    tradingModalType: TradingModalType.RemoveFollowedAccount,
                    info: {
                        visible: false,
                    },
                })
            );
            const SelectNextAccount = () => {
                const {partner} = Platform.reduxState<StoreState>();
                if (Utils.isMapNotEmpty(partner.Accounts)) {
                    const account: PartnerAccount = partner.Accounts.values()[0];
                    Platform.dispatch(SetSelectedPartnerAccountId({
                        account
                    }));
                } else if (IsEduTrading) {
                    Platform.dispatch(SetBrandPropsUrl({url: null}));
                    Platform.dispatch(DoFetchBrandProps());
                    Platform.dispatch(DoFetchPlatformProps());
                }
            };
            if (IsEduTrading) {
                SelectNextAccount();
            }
            if (action) {
                action(RouteType.FollowedAccounts);
            } else {
                if (WebUtil.isMobile()) {
                    Platform.dispatch(NavigateTo({route: RouteType.FollowedAccounts}));
                } else if (!IsEduTrading) {
                    setTimeout(SelectNextAccount, 0);
                }
            }
        }
    }

    public OnSetSelectedPartnerAccountId = async (payload: SelectFollowedAccountPayload) => {
        await this.doGetPositionOrders(payload);
        const {IsEduTrading, LegalData} = Platform.config<Configuration>();
        if (IsEduTrading) {
            const {Id, BrandId, Currency} = payload.account;
            Platform.storage().setItem(StorageKey.AcademySelectedAccountId, Id.toString());
            Platform.dispatch(SetAccountInfo({
                accountInfo: {
                    AccountId: null,
                    Currency
                }
            }));
            const LDUrlParts: string[] = LegalData[BrandId.toString()];
            if (Utils.isArrayNotEmpty(LDUrlParts)) {
                const url: string = LDUrlParts.join("");
                Platform.dispatch(SetBrandPropsUrl({url}));
                Platform.dispatch(DoFetchBrandProps());
                Platform.dispatch(DoFetchPlatformProps());
            }
            await SettingsEngine.FetchLocalizations(BrandId);
        }
    }

    public doGetPositionOrders = async ({account}: SelectFollowedAccountPayload) => {
        const {IsEduTrading} = Platform.config<Configuration>();
        if (account.IsEnabled && !IsEduTrading) {
            const request: GetAccountStateRequest = {
                FollowedAccountId: account.Id
            };
            const answer: [HttpReject, GetAccountStateResponse] = await Utils.to(XhrUtil.sendToGetAccountState(request, "GetAccountState"));
            if (answer[0]) {
                XhrUtil.notifyReject(answer[0]);
                Platform.dispatch(SetPartnerServerError({
                    tabs: [PortfolioType.OpenTrades, PortfolioType.EntryOrders],
                    error: XhrUtil.getRejectReason(answer[0])
                }));
                Platform.dispatch(SetPartnerQuotes({
                    Quotes: new TSMap<number, Quote>()
                }));
                Platform.dispatch(SetPartnerPositionsOrders({
                    Trades: [],
                    OpenPositionsMap: {},
                    EntryOrders: []
                }));
                Platform.dispatch(SetPartnerAccountCounter({
                    portfolioType: PortfolioType.OpenTrades,
                    count: 0,
                    filterCount: 0
                }));
                Platform.dispatch(SetPartnerAccountCounter({
                    portfolioType: PortfolioType.EntryOrders,
                    count: 0,
                    filterCount: 0
                }));
            } else {
                const {AggregateData, OpenPositions, EntryOrders, Exposures, Quotes} = answer[1];
                const AdditionalSymbolsIds: number[] = [];
                const quotes: TSMap<number, Quote> = this.processQuotes(Quotes);
                const trades: Trade[] = [];
                const OpenPositionsMap: {[key: number]: Deal} = {};
                const exposurePerSymbol: TSMap<number, Trade> = new TSMap();
                Exposures.forEach((exposure: Exposure) => {
                    if (exposure.OpenPositionsAmount > 1) {
                        const trade: Trade = TradeUtil.mapExposure(exposure);
                        trades.push(trade);
                        exposurePerSymbol.set(exposure.SymbolId, trade);
                    }
                });
                const storeState: StoreState = Platform.reduxState<StoreState>();
                const filterTrades: FilterDateState = storeState.filter.partnerDateFilter.get(PortfolioType.OpenTrades);
                const filterOrders: FilterDateState = storeState.filter.partnerDateFilter.get(PortfolioType.EntryOrders);
                const entryOrders: Deal[] = [];
                let filterCountTrades: number = 0;
                let filterCountOrders: number = 0;
                OpenPositions.forEach((deal: Deal) => {
                    OpenPositionsMap[deal.Id] = deal;
                    if (!quotes.has(deal.SymbolId)) {
                        AdditionalSymbolsIds.push(deal.SymbolId);
                    }
                    if (FilterUtil.filterDeal(filterTrades, deal)) {
                        filterCountTrades++;
                    }
                    const trade: Trade = TradeUtil.mapDeal(deal);
                    const exposure: Trade = exposurePerSymbol.get(deal.SymbolId);
                    if (exposure) {
                        exposure.SymbolRoundingDigits = trade.SymbolRoundingDigits;
                        exposure.trades.push(trade);
                        if (exposure.PositionDirections.indexOf(deal.PositionDirection) < 0) {
                            exposure.PositionDirections.push(deal.PositionDirection);
                            if (exposure.PositionDirections.length > 1) {
                                exposure.PositionDirections = [PositionDirection.Buy, PositionDirection.Sell];
                            }
                        }
                    } else {
                        trades.push(trade);
                    }
                });
                EntryOrders.forEach((deal: Deal) => {
                    if (!quotes.has(deal.SymbolId)) {
                        AdditionalSymbolsIds.push(deal.SymbolId);
                    }
                    if (FilterUtil.filterDeal(filterOrders, deal)) {
                        filterCountOrders++;
                        entryOrders.push(deal);
                    }
                });
                const {tables} = storeState.partner;
                const otState: PortfolioTableState = tables.get(PortfolioType.OpenTrades);
                const eoState: PortfolioTableState = tables.get(PortfolioType.EntryOrders);
                const otColumn: TradeColumn = otState.activeColumn;
                const otSort: SortDirection = otState.sort.get(otColumn);
                const filteredTrades: Trade[] = [];
                trades.forEach((t: Trade) => {
                    const trade: Trade = FilterUtil.filterTrade(filterTrades, t);
                    if (trade) {
                        filteredTrades.push(trade);
                    }
                });
                filteredTrades.sort(SortTrades(otColumn, otSort));
                exposurePerSymbol.values().forEach((trade: Trade) => {
                    trade.trades.sort(SortTrades(otColumn, otSort));
                });
                entryOrders.sort(SortDeals(eoState.activeColumn, eoState.sort.get(eoState.activeColumn)));
                Platform.dispatch(SetPartnerPositionsOrders({
                    Trades: filteredTrades,
                    OpenPositionsMap,
                    EntryOrders: entryOrders
                }));
                Platform.dispatch(SetPartnerAccountCounter({
                    portfolioType: PortfolioType.OpenTrades,
                    count: OpenPositions.length,
                    filterCount: filterCountTrades
                }));
                Platform.dispatch(SetPartnerAccountCounter({
                    portfolioType: PortfolioType.EntryOrders,
                    count: EntryOrders.length,
                    filterCount: filterCountOrders
                }));
                Platform.dispatch(SetPartnerServerError({
                    tabs: [PortfolioType.OpenTrades, PortfolioType.EntryOrders],
                    error: null
                }));
                Platform.dispatch(SetLastRefreshDate({
                    tabs: [PortfolioType.OpenTrades, PortfolioType.EntryOrders],
                    date: new Date()
                }));
                Platform.dispatch(SetPartnerAggregateData({AggregateData: AggregateData as AccountAggregateData}));
                if (Utils.isArrayNotEmpty(AdditionalSymbolsIds)) {
                    const request: GetAccountStateRequest = {
                        FollowedAccountId: account.Id,
                        AdditionalSymbolsIds
                    };
                    const answer: [HttpReject, GetAccountStateResponse] = await Utils.to(XhrUtil.sendToGetAccountState(request, "GetAccountState"));
                    if (answer[1]?.Quotes) {
                        this.processQuotes(answer[1].Quotes);
                    }
                }
            }
        }
    }

    private processQuotes = (Quotes: Quote[]): TSMap<number, Quote> => {
        const quotes: TSMap<number, Quote> = new TSMap<number, Quote>();
        if (Utils.isArrayNotEmpty(Quotes)) {
            Quotes.forEach((quote: Quote) => {
                quotes.set(quote.SymbolId, quote);
            });
        }
        Platform.dispatch(SetPartnerQuotes({
            Quotes: quotes
        }));
        return quotes;
    }

    public doGetExtendedPosition = async ({tradeType, DealId, action}: GetExtendedPositionPayload) => {
        const isMarketOrder: boolean = tradeType === TradeType.MarketOrder;
        const path: string = isMarketOrder ? "GetExtendedPositionv2" : "GetExtendedEntryOrderv2";
        const {partner} = Platform.reduxState<StoreState>();
        const account = partner.Accounts.get(partner.selectedPartnerAccountId);
        const request: GetExtendedPositionRequest = {
            EntryOrderId: isMarketOrder ? null : DealId,
            PositionId: isMarketOrder ? DealId : null,
            FollowedAccountId: account.Id
        }
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, any] = await Utils.to(XhrUtil.sendToAccountService(request, path));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
        } else {
            if (isMarketOrder) {
                Platform.dispatch(SetExtendedPosition({position: answer[1]}));
            } else {
                Platform.dispatch(SetExtendedEntryOrder({order: answer[1]}));
            }
            if (action) {
                action();
            } else {
                if (WebUtil.isMobile()) {
                    Platform.dispatch(
                        SetTradingModal({
                            tradingModalType: isMarketOrder ? TradingModalType.OpenTrade : TradingModalType.PendingOrder,
                            info: {
                                visible: true,
                            },
                        })
                    );
                } else {
                    Platform.dispatch(ToggleMenu({collapse: true}));
                }
            }
        }
    }

    private doGetFinancialSummaryPromotions = async ({account}: SelectFollowedAccountPayload) => {
        const {IsEduTrading} = Platform.config<Configuration>();
        if (account.IsEnabled && !IsEduTrading) {
            Platform.dispatch(SetPartnerPendingData({value: true}));
            const request: GetAccountStateRequest | GetVaultDetailsRequest = {
                FollowedAccountId: account.Id
            };
            const answer: [HttpReject, GetAccountStateResponse] = await Utils.to(XhrUtil.sendToGetAccountState(request, "GetAccountState"));
            const answerRolling: [HttpReject, IsRollingEnabledResponse] = await Utils.to(this.sendToWebProfitService(request, "IsRollingEnabledv2"));
            const answerVault: [HttpReject, GetVaultDetailsResponse] = await Utils.to(XhrUtil.sendToAccountService(request, "GetVaultDetailsv2"));

            Platform.dispatch(SetPartnerPendingData({value: false}));
            if (answer[0]) {
                XhrUtil.notifyReject(answer[0]);
                Platform.dispatch(SetPartnerServerError({
                    tabs: [PortfolioType.FinancialSummary, PortfolioType.AvailableFeatures],
                    error: XhrUtil.getRejectReason(answer[0])
                }));
                Platform.dispatch(SetPartnerFinancialSummaryPromotions({
                    AggregateData: {},
                    AvailableFeatures: {
                        PositionPromotionSettings: []
                    }
                }));
            } else {
                const {AggregateData , PositionPromotionSettings, AccountRebateData} = answer[1];
                Platform.dispatch(SetPartnerFinancialSummaryPromotions({
                    AggregateData: AggregateData as AccountAggregateData,
                    AvailableFeatures: {
                        PositionPromotionSettings,
                        AccountRebateData,
                        Vault: answerVault[1],
                        RollingContractsExpirationDate: answerRolling[1]?.RollingContractsExpirationDate,
                        RollingContractsEnabled: answerRolling[1]?.IsRollingEnabled
                    }
                }));
                Platform.dispatch(SetPartnerServerError({
                    tabs: [PortfolioType.FinancialSummary, PortfolioType.AvailableFeatures],
                    error: null
                }));
                Platform.dispatch(SetLastRefreshDate({
                    tabs: [PortfolioType.FinancialSummary, PortfolioType.AvailableFeatures],
                    date: new Date()
                }));
            }
        }
    }

    public doSetPartnerTab = async ({portfolioType, account}: SetPartnerPortfolioTabPayload) => {
        if (account?.IsEnabled) {
            setTimeout(async () => {
                await this.doGetPortfolioTabData(portfolioType);
            }, 0);
        }
    }

    public doRefreshFollowedAccounts = async () => {
        const {activePortfolioTab} = Platform.reduxState<StoreState>().partner;
        await this.doGetPortfolioTabData(activePortfolioTab);
    }

    private doGetPortfolioTabData = async (portfolioType: PortfolioType) => {
        const {Accounts, selectedPartnerAccountId} = Platform.reduxState<StoreState>().partner;
        const account: PartnerAccount = Accounts.get(selectedPartnerAccountId);
        switch (portfolioType) {
            case PortfolioType.OpenTrades:
            case PortfolioType.EntryOrders:
                await this.doGetPositionOrders({account});
                break;
            case PortfolioType.FinancialSummary:
            case PortfolioType.AvailableFeatures:
                await this.doGetFinancialSummaryPromotions({account});
                break;
            case PortfolioType.ActivityLog:
                await this.doFetchAccountLogs();
                break;
            case PortfolioType.ClosedTrades:
                await this.doFetchClosedTrades();
                break;
            case PortfolioType.OrdersHistory:
                await this.doFetchOrderHistory();
                break;
            default:
                break;
        }
    }

    public onSetPartnerDateFilter = async (payload: PortfolioDateFilterPayload) => {
        if (payload.routeName === RouteType.FollowedAccounts || payload.routeName === RouteType.FollowedAccount) {
            if (payload.portfolioType === PortfolioType.OpenTrades || payload.portfolioType === PortfolioType.EntryOrders) {
                setTimeout(async () => {
                    const {Accounts, selectedPartnerAccountId} = Platform.reduxState<StoreState>().partner;
                    const account: PartnerAccount = Accounts.get(selectedPartnerAccountId);
                    await this.doGetPositionOrders({account});
                }, 0);
            } else if (payload.portfolioType === PortfolioType.ClosedTrades) {
                await this.fetchClosedTradesInternal(1, payload.dateType, payload.from, payload?.to);
            } else if (payload.portfolioType === PortfolioType.OrdersHistory && !payload.isMobile) { // NOTE: next dispatch SetHistoryOrderStatus will fetch OrdersHistory
                const {partnerDateFilter} = Platform.reduxState<StoreState>().filter;
                const filter = partnerDateFilter.get(PortfolioType.OrdersHistory);
                await this.fetchOrdersHistory(1, filter.orderStatus, payload.dateType, payload.from, payload.to);
            } else if (payload.portfolioType === PortfolioType.ActivityLog) {
                await this.fetchAccountLogs(payload.dateType, payload.from, payload.to);
            }
        }
    }

    public onSetHistoryOrderStatus = async (payload: SetHistoryOrderStatusPayload) => {
        if (payload.routeName === RouteType.FollowedAccounts || payload.routeName === RouteType.FollowedAccount) {
            const {partnerDateFilter} = Platform.reduxState<StoreState>().filter;
            const filter: FilterDateState = partnerDateFilter.get(PortfolioType.OrdersHistory);
            await this.fetchOrdersHistory(1, payload.orderStatus, filter.dateType, filter.from, filter.to);
        }
    }

    private doFetchClosedTrades = async (): Promise<void> => {
        const {filter, partner} = Platform.reduxState<StoreState>();
        const {activePortfolioTab} = partner;
        const filterState: FilterDateState = filter.partnerDateFilter.get(activePortfolioTab);
        await this.fetchClosedTradesInternal(1, filterState.dateType, filterState.from, filterState.to);
    }

    private fetchClosedTradesInternal = async (page: number, dateType: FilterDateType, from?: moment.Moment, to?: moment.Moment): Promise<void> => {
        const {Accounts, selectedPartnerAccountId} = Platform.reduxState<StoreState>().partner;
        Platform.dispatch(SetPartnerAccountClosedTrades({table: {
            pending: true,
            page: page,
            deals: page === 1 ? [] : null
        }}));
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const account: PartnerAccount = Accounts.get(selectedPartnerAccountId);
        const range: {from: Date, to: Date} = FilterUtil.getDateRange(dateType, from, to);
        const request: GetClosedTradesRequest = {
            AccountIds: [accountState.accountId],
            ClosedDateFrom: dateType === FilterDateType.All ? null : DateTimeFormat.formatServerDate(range.from),
            ClosedDateTo: DateTimeFormat.formatServerDate(range.to),
            PageIndex: page,
            PageSize: ClosedPositionsThreshold,
            FollowedAccountId: account?.Id
        };

        const answer: [HttpReject, GetClosedTradesResponse] = await Utils.to(XhrUtil.sendToAccountService(request, "GetClosedPositions"));
        if (answer[0]) {
            this._logger.debug("Failed fetch partner account closed trades");
            XhrUtil.notifyReject(answer[0]);
            Platform.dispatch(SetPartnerServerError({
                tabs: [PortfolioType.ClosedTrades],
                error: XhrUtil.getRejectReason(answer[0])
            }));
            Platform.dispatch(SetPartnerAccountCounter({
                portfolioType: PortfolioType.ClosedTrades,
                count: 0
            }));
            Platform.dispatch(SetPartnerAccountClosedTrades({table: {
                pending: false,
                hasMore: false,
                deals: []
            }}));
        } else {
            const {Positions} = answer[1];
            Platform.dispatch(SetPartnerAccountCounter({
                portfolioType: PortfolioType.ClosedTrades,
                count: Positions.length + (page === 1 ? 0 : Platform.reduxState<StoreState>().partner.ClosedTradesTable.deals.length)
            }));
            Platform.dispatch(SetPartnerAccountClosedTrades({table: {
                pending: false,
                hasMore: ClosedPositionsThreshold === Positions?.length,
                deals: Positions
            }}));
            Platform.dispatch(SetPartnerServerError({
                tabs: [PortfolioType.ClosedTrades],
                error: null
            }));
            Platform.dispatch(SetLastRefreshDate({
                tabs: [PortfolioType.ClosedTrades],
                date: new Date()
            }));
            if (Utils.isArrayNotEmpty(Positions)) {
                const {tables} = Platform.reduxState<StoreState>().portfolio;
                const ctState: PortfolioTableState = tables.get(PortfolioType.ClosedTrades);
                if (ctState) {
                    Platform.dispatch(SortTradesAction({
                        portfolioType: PortfolioType.ClosedTrades,
                        column: ctState.activeColumn,
                        sortDirection: ctState.sort.get(ctState.activeColumn)
                    }));
                }
            }
        }
    }

    private doFetchOrderHistory = async () => {
        const {partnerDateFilter} = Platform.reduxState<StoreState>().filter;
        const filter: FilterDateState = partnerDateFilter.get(PortfolioType.OrdersHistory);
        await this.fetchOrdersHistory(1, filter.orderStatus, filter.dateType, filter.from, filter.to)
    }

    public fetchOrdersHistory = async (page: number, statuses: EntryOrderStatus[], dateType: FilterDateType, from?: moment.Moment, to?: moment.Moment) => {
        const {Accounts, selectedPartnerAccountId} = Platform.reduxState<StoreState>().partner;
        const {IsEduTrading} = Platform.config<Configuration>();
        const eduStatuses: EntryOrderStatus[] = [EntryOrderStatus.Cancelled, EntryOrderStatus.Rejected, EntryOrderStatus.CancelRequest];
        Platform.dispatch(SetPartnerAccountOrderHistory({table: {
            pending: true,
            page: page,
            deals: page === 1 ? [] : null
        }}));
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const account: PartnerAccount = Accounts.get(selectedPartnerAccountId);
        const range: {from: Date, to: Date} = FilterUtil.getDateRange(dateType, from, to);
        const request: GetEntryOrdersHistoryRequest = {
            AccountIds: [accountState.accountId],
            CreatedDateFrom: dateType === FilterDateType.All || IsEduTrading ? null : DateTimeFormat.formatServerDate(range.from),
            CreatedDateTo: DateTimeFormat.formatServerDate(range.to),
            EntryOrderStatuses: IsEduTrading ? eduStatuses : statuses,
            EntryOrderTypes: [OrderType.EntryLimit, OrderType.EntryStop, OrderType.Market],
            ShouldCalculateTotalCount: false,
            PageIndex: page,
            PageSize: OrdersHistoryThreshold,
            FollowedAccountId: account?.Id
        };

        const answer: [HttpReject, GetEntryOrdersHistoryResponse] = await Utils.to(XhrUtil.sendToAccountService(request, "GetEntryOrders"));
        if (answer[0]) {
            this._logger.debug("Failed fetch partner account order history");
            XhrUtil.notifyReject(answer[0]);
            Platform.dispatch(SetPartnerServerError({
                tabs: [PortfolioType.OrdersHistory],
                error: XhrUtil.getRejectReason(answer[0])
            }));
            Platform.dispatch(SetPartnerAccountCounter({
                portfolioType: PortfolioType.OrdersHistory,
                count: 0
            }));
            Platform.dispatch(SetPartnerAccountOrderHistory({table: {
                    pending: false,
                    hasMore: false,
                    deals: []
                }}));
        } else {
            const {EntryOrders} = answer[1];
            this._logger.debug("Fetched partner order history size: " + EntryOrders?.length);
            Platform.dispatch(SetPartnerAccountCounter({
                portfolioType: PortfolioType.OrdersHistory,
                count: EntryOrders.length + (page === 1 ? 0 : Platform.reduxState<StoreState>().partner.OrdersHistoryTable.deals.length)
            }));
            Platform.dispatch(SetPartnerAccountOrderHistory({table: {
                pending: false,
                hasMore: ClosedPositionsThreshold === EntryOrders?.length,
                deals: EntryOrders
            }}));
            Platform.dispatch(SetPartnerServerError({
                tabs: [PortfolioType.OrdersHistory],
                error: null
            }));
            Platform.dispatch(SetLastRefreshDate({
                tabs: [PortfolioType.OrdersHistory],
                date: new Date()
            }));
            if (Utils.isArrayNotEmpty(EntryOrders)) {
                const {tables} = Platform.reduxState<StoreState>().portfolio;
                const ctState: PortfolioTableState = tables.get(PortfolioType.OrdersHistory);
                if (ctState) {
                    Platform.dispatch(SortTradesAction({
                        portfolioType: PortfolioType.OrdersHistory,
                        column: ctState.activeColumn,
                        sortDirection: ctState.sort.get(ctState.activeColumn)
                    }));
                }
            }
        }
    }

    private doFetchAccountLogs = async () => {
        const {partnerDateFilter} = Platform.reduxState<StoreState>().filter;
        const filter: FilterDateState = partnerDateFilter.get(PortfolioType.ActivityLog);
        await this.fetchAccountLogs(filter.dateType, filter.from, filter.to);
    }

    private fetchAccountLogs = async (dateType: FilterDateType, from?: moment.Moment, to?: moment.Moment) => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const {Accounts, selectedPartnerAccountId} = Platform.reduxState<StoreState>().partner;
        const account: PartnerAccount = Accounts.get(selectedPartnerAccountId);
        const range: {from: Date, to: Date} = FilterUtil.getDateRange(dateType, from, to);
        const request: GetAccountLogRequest = {
            AccountId: accountState.accountId,
            from: dateType === FilterDateType.All ? null : DateTimeFormat.formatServerDate(range.from),
            to: DateTimeFormat.formatServerDate(range?.to),
            filterActionTypes: [],
            FollowedAccountId: account?.Id
        };
        Platform.dispatch(SetPartnerPendingData({value: true}));
        const answer: [HttpReject, GetAccountLogResponse] = await Utils.to(XhrUtil.sendAuthenticated(request, "WebProfitServer/AccountStatesService.svc/json/GetAccountDealingLog"));
        Platform.dispatch(SetPartnerPendingData({value: false}));

        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
            Platform.dispatch(SetPartnerServerError({
                tabs: [PortfolioType.ActivityLog],
                error: XhrUtil.getRejectReason(answer[0])
            }));
            Platform.dispatch(SetPartnerAccountCounter({
                portfolioType: PortfolioType.ActivityLog,
                count: 0
            }));
            Platform.dispatch(SetPartnerAccountLogs({logs: []}));
        } else {
            const logs: AccountLog[] = answer[1]?.Logs || [];
            logs.sort((l1: AccountLog, l2: AccountLog) => Utils.compareNumber(l2.DateTime, l1.DateTime));
            Platform.dispatch(SetPartnerAccountCounter({
                portfolioType: PortfolioType.ActivityLog,
                count: logs.length
            }));
            Platform.dispatch(SetPartnerAccountLogs({logs}));
            Platform.dispatch(SetPartnerServerError({
                tabs: [PortfolioType.ActivityLog],
                error: null
            }));
            Platform.dispatch(SetLastRefreshDate({
                tabs: [PortfolioType.ActivityLog],
                date: new Date()
            }));
        }
    }

    private sendToWebProfitService = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticated(request, "WebProfitServer/WebProfitClientService.svc/json/" + path, null, true);
    }
}
