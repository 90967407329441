import React from "react";
import {makeTourPopoverStyles} from "component/tour/popover/styles";
import {Tutorial,PopoverContentProps} from "component/tour/types";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "enum/TranslationKey";
import {TutorialIDs} from "core/tutorial/TutorialIDs";
import WebUtil from "platform/util/WebUtil";
import {TutorialType} from "enum/TutorialType";
import {onTutorialFinish} from "component/lessons/tutorials/TutorialHandler";

const isMobile: boolean = WebUtil.isMobile();

export const howToUseStopLossAndTakeProfit: Tutorial = {
    id: TutorialType.HowToUseSlTp,
    title: TranslationKey.tourLesson5Title,
    onFinish:onTutorialFinish,
    steps: [
        {
            id: TutorialIDs.howToUseStopLossAndTakeProfitStep1,
            selector: '[id="_wt_mmnu_symbols"]',
            content: () => Translations.text(TranslationKey.tourLesson5Step1Title),
            position: isMobile ? "top" : "right",
            disableActions: true,
            cancelOnOutside: true,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean)=> ({
                    left: isMobile ? "-8vw" : rtl ? "20px": "-50px",
                })),
            }
        },
        {
            id: TutorialIDs.howToUseStopLossAndTakeProfitStep2,
            selector: isMobile ? '[id="_wt_symbols_symbolsMyWatchlist"]' : ".trading-page-title.symbols .page-title",
            position: "bottom",
            disableActions: true,
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson5Step2Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson5Step2Text)}</div>
            </>,
            styles: {
                popover: makeTourPopoverStyles((rtl)=> ({
                    width: "280px",
                    top: isMobile ? "0px" : "-10px",
                    left: isMobile ? (rtl ? "-180px" : "15px") : (rtl ? "-190px": "0px"),
                })),
                popupArrow: (rtl) => ({
                    left: isMobile ? (rtl ? "initial" : "12%") : (rtl ? "initial" : "12%"),
                    right: isMobile ? (rtl ? "18%" : "initial") : (rtl ? "15%" : "initial"),
                }),
            }
        },
        {
            id: TutorialIDs.howToUseStopLossAndTakeProfitStep3,
            selector: '[class="btn-group"]',
            position: "bottom",
            disableActions: false,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean)=> ({
                    top: isMobile ? "-8px" : "0px",
                    width: isMobile ? "318px" : "380px",
                    left: isMobile ? "calc(50% - 150px)" : "40px",
                })),
            },
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson5Step3Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson5Step3Text)}</div>
            </>,
        },
        {
            id: TutorialIDs.howToUseStopLossAndTakeProfitStep4,
            selector: ".take-loss-wrapper",
            position: "top",
            disableActions: true,
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson5Step4Title)}</div>
            </>,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean)=> ({
                    width: isMobile ? "332px" : "300px",
                    left: isMobile ? "calc(50% - 172px)" : "74px",
                    top: isMobile ? "0" : "24px",
                })),
            }
        },
        {
            id: TutorialIDs.howToUseStopLossAndTakeProfitStep5,
            selector: '[id="_wt_sidebar_trade_stopLoss_rate_range"]',
            position: "top",
            disableActions: true,
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson5Step5Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson5Step5Text)}</div>
            </>,
            styles: {
                popover: makeTourPopoverStyles((rtl)=> ({
                    width: isMobile ? "332px" : "332px",
                    top: isMobile ? "-32px" : "-24px",
                    left: isMobile ? "calc(50% - 172px)" : (rtl ? "72px" : "46px"),
                })),
            }
        },
        {
            id: TutorialIDs.howToUseStopLossAndTakeProfitStep6,
            selector: '[id="_wt_sidebar_trade_takeProfit_rate_range"]',
            position: "top",
            disableActions: true,
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson5Step5Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson5Step5Text)}</div>
            </>,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean)=> ({
                    width: isMobile ? "332px" : "332px",
                    top: isMobile ? "-32px" : "-24px",
                    left: isMobile ? "calc(50% - 172px)" : (rtl ? "72px" : "46px"),
                })),
            }
        },
        {
            id: TutorialIDs.howToUseStopLossAndTakeProfitStep7,
            selector: ".wt_buy_sell_btn",
            position: "top",
            disableActions: false,
            mutationObservables: [".wt_buy_sell_btn"],
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson5Step6Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson5Step6Text)}</div>
            </>,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean)=> ({
                    top: isMobile ? "-46px" : "0px",
                    left: rtl ? "28px" : "60px",
                })),
            },
        },
    ],
}
