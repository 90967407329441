import {TranslationKey} from "enum/TranslationKey";

enum EduTradingScannerType {

    BreakoutMomentum = "BMMS",
    DynamicEquilibrium = "DEMS",
    PivotPointReversal = "PPRMS",
    TrendCapture = "TCMS",
    VolatilitySurge = "VSMS"
}

namespace EduTradingScannerType {

    export function ScannerKey(type: EduTradingScannerType): string {
        switch (type) {
            case EduTradingScannerType.BreakoutMomentum:
                return "t9JD7A0zuod";
            case EduTradingScannerType.DynamicEquilibrium:
                return "JKKaAGJYVif";
            case EduTradingScannerType.PivotPointReversal:
                return "FJXpGdJ0XwQ";
            case EduTradingScannerType.TrendCapture:
                return "dzUpTGJStlS";
            case EduTradingScannerType.VolatilitySurge:
                return "HQYuKTpKlqw";
        }
    }

    export function TrKey(type: EduTradingScannerType): TranslationKey {
        switch (type) {
            case EduTradingScannerType.BreakoutMomentum:
                return TranslationKey.BMMS;
            case EduTradingScannerType.DynamicEquilibrium:
                return TranslationKey.DEMS;
            case EduTradingScannerType.PivotPointReversal:
                return TranslationKey.PPRMS;
            case EduTradingScannerType.TrendCapture:
                return TranslationKey.TCMS;
            case EduTradingScannerType.VolatilitySurge:
                return TranslationKey.VSMS;
        }
    }
}

export  {
    EduTradingScannerType
}
