import React from "react";

import {PopoverContentProps} from "component/tour/types";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "enum/TranslationKey";

export const TourPopover: React.FC<PopoverContentProps> = (props) => {
    const isLastStep = props.currentStep === props.steps.length - 1;
    const currentStep = props.steps[props.currentStep];
    const content = currentStep.content;
    const position = currentStep.position;
    const rtl = props.rtl;

    const arrowPosition =
        position === "right" ? (rtl ? "arrow-right" : "arrow-left") :
            position === "left" ? (rtl ? "arrow-left" : "arrow-right") :
                position === "top" ? "arrow-down" : "arrow-up";

    const popupArrowProps = props.steps[props.currentStep]?.styles?.["popupArrow"];
    const popupArrowStyle = popupArrowProps ? popupArrowProps(rtl) : {};

    return (
        <div className={`tour-popover ${arrowPosition}`} dir={rtl ? 'rtl' : 'ltr'}>
            <div className="tour-content">
                <>
                    {typeof content === 'function'
                        ? content({...props})
                        : content}
                </>
            </div>
            {!props.disabledActions ? (<div className="action-menu">
                <div className="action-btn" style={{height: "100%"}}
                     onClick={() => {
                         if (isLastStep) {
                             props.setIsOpen(false);
                             props.setTutorialStarted(false);
                             props.setCurrentStep(0);
                             if (props.tutorial.onFinish) {
                                 props.tutorial.onFinish(props.tutorial);
                             }
                         } else {
                             props.setCurrentStep((s) => s + 1);
                         }
                     }}
                >
                    {Translations.text(isLastStep ? TranslationKey.done : TranslationKey.next)}
                </div>
            </div>) : null}
            <div className={`popup-arrow ${arrowPosition}`} style={popupArrowStyle}/>
        </div>
    )
}
