import {Logger} from "platform/logger/Logger";
import {IDpkHandler} from "platform/dpk/Dpk";
import {TSMap} from "typescript-map";
import {DpkType} from "enum/DpkType";
import Utils from "platform/util/Utils";
import Platform from "platform/Platform";
import {GoingEditDeal, GoingOpenDeal, SetPositionTabDealId, SetTradeManagerTab} from "core/redux/deal/DealReduxActions";
import {DpkParam} from "enum/DpkParam";
import {PositionDirection} from "platform/protocol/enum/PositionDirection";
import {StoreState} from "core/redux/StoreState";
import {
    DoActivateBonusCode,
    DoRegisterUserActivity,
    OnProcessTopUpButton,
    SetShowReviewModal,
    TogglePromotionsDetails
} from "core/redux/account/AccountReduxActions";
import Parameter from "platform/util/Parameter";
import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol";
import {SetTradingModal} from "core/redux/app/AppReduxActions";
import TradingModalType from "enum/TradingModalType";
import {AccountOption} from "protocol/account/AccountOption";
import {TradingModalUserFeedback} from "core/redux/app/AppReduxState";
import {NavigateTo, ShowChat} from "platform/redux/core/CoreActions";
import {UserActivityType} from "enum/UserActivityType";
import {RouteType} from "core/router/Routes";
import {Deal} from "platform/protocol/trading/Deal";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIconType} from "platform/redux/popups/PopupsReduxState";
import {TranslationKey} from "enum/TranslationKey";
import {TranslationParam} from "enum/TranslationParam";
import {TradeType} from "protocol/trade/TradeType";
import {TradeManagerTab} from "enum/TradeManagerTab";
import {EnvType} from "platform/enum/EnvType";
import WebUtil from "platform/util/WebUtil";
import {SetSettingsTab} from "core/redux/settings/SettingsReduxActions";
import {SettingsTab} from "enum/SettingsTab";
import {SetPortfolioTab} from "core/redux/portfolio/PortfolioReduxActions";
import {PortfolioType} from "enum/PortfolioType";
import ToolsMenuType from "enum/ToolsMenuType";
import {LDAcademyVideo} from "core/props/LDPlatformProps";
import {LDUtil} from "core/util/LDUtil";
import {SetAcademyVideo, SetTour} from "core/redux/lessons/LessonsReduxActions";
import {BIEventReferrer} from "enum/BIEventReferrer";
import {FetchClosedTrade, FetchOrderById} from "core/redux/trades/ClosedTradesReduxActions";
import {FetchSignalById} from "core/redux/deal/SignalsReduxActions";
import {LoginAccountType} from "protocol/auth/LoginAccountType";
import {GetExtendedPosition} from "core/redux/partner/PartnerReduxActions";
import {Confetti} from "core/Confetti";

export class DpkHandler implements IDpkHandler<DpkType> {

    private static _DpkActions: TSMap<DpkType, (routeType: RouteType, parameters?: Parameter[]) => void> = new TSMap<DpkType, (routeType: RouteType, parameters?: Parameter[]) => void>();
    protected _logger: Logger = Logger.Of("DpkHandler");

    public static RegisterAction(dpkType: DpkType, action: (routeType: RouteType, parameters?: Parameter[]) => void): void {
        DpkHandler._DpkActions.set(dpkType, action);
    }

    public static GetAction(dpkType: DpkType): (routeType: RouteType, parameters?: Parameter[]) => void {
        return DpkHandler._DpkActions.get(dpkType);
    }

    public dpkTypes(): DpkType[] {
        return [
            DpkType.OpenPosition,
            DpkType.openposition,
            DpkType.OpenDeposit,
            DpkType.Deposit,
            DpkType.OpenUploadDocument,
            DpkType.UserFeedback,
            DpkType.StartChat,
            DpkType.RegisterActivity,
            DpkType.Signal,
            DpkType.Position,
            DpkType.ClosedPosition,
            DpkType.Order,
            DpkType.Promotions,
            DpkType.ChangePassword,
            DpkType.Portfolio,
            DpkType.Academy,
            DpkType.NativeReview,
            DpkType.GivePromo,
            DpkType.SignTransactions,
            DpkType.PushPermission,
            DpkType.RegisterDemo,
            DpkType.Confetti,
            DpkType.Tour,
            DpkType.TutorialLearnMore,
        ];
    }

    public handle(dpkType: DpkType, parameters: TSMap<string, any>, params: Parameter[], action?: (routeType?: string, params?: Parameter[]) => void): void {
        if (Utils.isNotNull(dpkType)) {
            const FollowedAccountId: number = parseInt(parameters.get(DpkParam.FollowedAccountId) || Parameter.value(DpkParam.FollowedAccountId, params), 10);
            switch (dpkType) {
                case DpkType.OpenPosition:
                    const SymbolId: number = parseInt(parameters.get(DpkParam.SymbolId), 10);
                    const Direction: PositionDirection = parseInt(parameters.get(DpkParam.Direction), 10);
                    const stopLoss: number = parseFloat(parameters.get(DpkParam.StopLoss));
                    const takeProfit: number = parseFloat(parameters.get(DpkParam.TakeProfit));
                    this._logger.debug("Open position. SymbolId: " + SymbolId + " Direction: " + Direction);
                    if (SymbolId && Direction) {
                        const {symbols} = Platform.reduxState<StoreState>().symbols;
                        if (symbols.has(SymbolId)) {
                            Platform.dispatch(GoingOpenDeal({
                                SymbolId,
                                Direction,
                                stopLoss,
                                takeProfit,
                                comments: "Created from DPK",
                                action
                            }));
                        }
                    }
                    break;
                case DpkType.openposition:
                    const ric: string = parameters.get(DpkParam.Ric) || Parameter.value(DpkParam.Ric, params);
                    const direction: string = parameters.get(DpkParam.direction) || Parameter.value(DpkParam.direction, params);
                    const sl: string = parameters.get(DpkParam.StopLoss) || Parameter.value(DpkParam.StopLoss, params);
                    const tp: string = parameters.get(DpkParam.TakeProfit) || Parameter.value(DpkParam.TakeProfit, params);
                    this._logger.debug("RIC: " + ric + " Dir: " + direction + " SL: " + sl + " TP: " + tp)
                    if (ric) {
                        const {symbols} = Platform.reduxState<StoreState>().symbols;
                        for (let i = 0; i < symbols.size(); i++) {
                            const symbol: TradeSymbol = symbols.values()[i];
                            if (symbol?.tradingCentralRICCode === ric) {
                                const positionDirection: PositionDirection = direction === "buy" ? PositionDirection.Buy : PositionDirection.Sell;
                                Platform.dispatch(GoingOpenDeal({
                                    SymbolId: symbol.SymbolId,
                                    Direction: positionDirection,
                                    stopLoss: parseFloat(sl),
                                    takeProfit: parseFloat(tp),
                                    comments: "Created from DPK",
                                    action
                                }));
                                break;
                            }
                        }
                    }
                    break;
                case DpkType.OpenDeposit:
                case DpkType.Deposit:
                    Platform.dispatch(OnProcessTopUpButton({
                        parameters: params,
                        action
                    }));
                    break;
                case DpkType.OpenUploadDocument:
                    const {ShownMenuItems} = Platform.reduxState<StoreState>().account;
                    if ((ShownMenuItems & AccountOption.DocumentUploader) > 0) {
                        if (action) {
                            action();
                        } else {
                            Platform.dispatch(SetTradingModal({
                                tradingModalType: TradingModalType.Documents,
                                info: {
                                    visible: true
                                }
                            }));
                        }
                    }
                    break;
                case DpkType.UserFeedback:
                    if (action) {
                        action(null, params);
                    } else {
                        const fParams: TradingModalUserFeedback = {fromDpk: true};
                        Platform.dispatch(SetTradingModal({
                            tradingModalType: TradingModalType.Feedback,
                            info: {
                                visible: true,
                                params: fParams
                            }
                        }));
                    }
                    break;
                case DpkType.StartChat:
                    if (action) {
                        action();
                    } else {
                        Platform.dispatch(ShowChat({
                            opposite: true,
                            comment: "Executed by deep link"
                        }));
                    }
                    break;
                case DpkType.RegisterActivity:
                    const activity: UserActivityType = parseInt(parameters.get(DpkParam.Activity), 10);
                    this._logger.debug("Asked register activity: " + activity);
                    if (activity === UserActivityType.HelpRequested) {
                        const {CallMeEnabled} = Platform.reduxState<StoreState>().settings;
                        if (CallMeEnabled) {
                            Platform.dispatch(DoRegisterUserActivity({
                                userActivity: UserActivityType.HelpRequested,
                                comment: "Executed by deep link"
                            }));
                        }
                    } else {
                        Platform.dispatch(DoRegisterUserActivity({
                            userActivity: activity,
                            comment: "Executed by deep link"
                        }));
                    }
                    break;
                case DpkType.Signal:
                    const SignalId: number = parseInt(parameters.get(DpkParam.Id) || Parameter.value(DpkParam.Id, params), 10);
                    if (SignalId) {
                        Platform.dispatch(FetchSignalById({id: SignalId, action}));
                    }
                    break;
                case DpkType.Position:
                    const {trades, partner} = Platform.reduxState<StoreState>();
                    const DealId: number = parseInt(parameters.get(DpkParam.Id) || Parameter.value(DpkParam.Id, params), 10);
                    const deal: Deal = (Utils.greaterThen0(FollowedAccountId) ? partner.OpenPositionsMap : trades.OpenPositionsMap)[DealId];
                    if (deal) {
                        if (Utils.greaterThen0(FollowedAccountId)) {
                            Platform.dispatch(GetExtendedPosition({
                                DealId,
                                tradeType: TradeType.MarketOrder
                            }));
                        } else {
                            Platform.dispatch(
                                GoingEditDeal({
                                    DealId,
                                    tradeType: TradeType.MarketOrder,
                                    action
                                })
                            );
                            const {auth} = Platform.reduxState<StoreState>();
                            if (auth.loginAccountType !== LoginAccountType.Invest) {
                                Platform.dispatch(SetPositionTabDealId({DealId}));
                            }
                        }
                    } else {
                        Platform.dispatch(ShowPopup({
                            popup: {
                                icon: {type: PopupIconType.ERROR},
                                showClose: true,
                                message: {
                                    trKey: TranslationKey.positionNotFound,
                                    params: [Parameter.Of(TranslationParam.value, DealId)]
                                },
                                actions: [{type: PopupActionType.OK}]
                            }
                        }));
                    }
                    break;
                case DpkType.ClosedPosition:
                    const PositionId: number = parseInt(parameters.get(DpkParam.Id) || Parameter.value(DpkParam.Id, params), 10);
                    this._logger.debug("Try to open closed position details. PositionId: " + PositionId);
                    if (PositionId) {
                        Platform.dispatch(FetchClosedTrade({id: PositionId, FollowedAccountId,  action}));
                    }
                    break;
                case DpkType.Order:
                    const OrderId: number = parseInt(parameters.get(DpkParam.Id) || Parameter.value(DpkParam.Id, params), 10);
                    const order: Deal = Platform.reduxState<StoreState>().trades.EntryOrdersMap[OrderId];
                    if (order) {
                        Platform.dispatch(
                            GoingEditDeal({
                                DealId: OrderId,
                                tradeType: TradeType.EntryOrder,
                                action
                            })
                        );
                    } else if (OrderId) {
                        this._logger.debug("Try to open order details. OrderId: " + OrderId);
                        Platform.dispatch(FetchOrderById({id: OrderId, action}));
                    }
                    break;
                case DpkType.Promotions:
                    const SymbolRIC: string = (parameters.get(DpkParam.Ric) || Parameter.value(DpkParam.Ric, params))?.toUpperCase();
                    if (SymbolRIC) {
                        let symbol: TradeSymbol;
                        const symbols: TradeSymbol[] = Platform.reduxState<StoreState>().symbols.symbols.values();
                        for (let i = 0; i < symbols.length; i++) {
                            if (Utils.nullToEmpty(symbols[i].tradingCentralRICCode).toUpperCase() === SymbolRIC) {
                                symbol = symbols[i];
                                break;
                            }
                        }
                        if (symbol) {
                            Platform.dispatch(GoingOpenDeal({
                                SymbolId: symbol.SymbolId,
                                Direction: PositionDirection.Sell,
                                comments: "Created from DPK",
                                action
                            }));
                            Platform.dispatch(SetTradeManagerTab({
                                tab: TradeManagerTab.Promotions
                            }));
                        }
                    } else {
                        if (Platform.environment().type() === EnvType.Web) {
                            if (WebUtil.isMobile()) {
                                Platform.dispatch(SetTradingModal({
                                    tradingModalType: TradingModalType.PromotionsDetails,
                                    info: {
                                        visible: true,
                                    },
                                }));
                            } else {
                                Platform.dispatch(TogglePromotionsDetails({}));
                            }
                        } else if (action) {
                            action(RouteType.PromotionsDetails);
                        }
                    }
                    break;
                case DpkType.ChangePassword:
                    Platform.dispatch(SetSettingsTab({tab: SettingsTab.Profile}));
                    if (action) {
                        action();
                    } else {
                        Platform.dispatch(NavigateTo({
                            route: RouteType.AccountSettings
                        }));
                        Platform.dispatch(SetTradingModal({
                            tradingModalType: TradingModalType.ChangePassword,
                            info: {
                                visible: true,
                            },
                        }));
                    }
                    break;
                case DpkType.Portfolio:
                    const tab: string = Utils.nullToEmpty(parameters.get(DpkParam.Tab) || Parameter.value(DpkParam.Tab, params));
                    let portfolioType: PortfolioType = PortfolioType.OpenTrades;
                    if (tab === "orders") {
                        portfolioType = PortfolioType.EntryOrders;
                    } else if (tab === "closedpositions") {
                        portfolioType = PortfolioType.ClosedTrades;
                    } else if (tab === "log") {
                        portfolioType = PortfolioType.ActivityLog;
                    }
                    Platform.dispatch(SetPortfolioTab({portfolioType}));
                    if (action) {
                        action();
                    } else {
                        Platform.dispatch(NavigateTo({
                            route: RouteType.MyTrades
                        }));
                    }
                    break;
                case DpkType.Academy:
                    const {blockedTools} = Platform.reduxState<StoreState>().app;
                    if (blockedTools.indexOf(ToolsMenuType.Lessons) < 0) {
                        const {academyVideos} = Platform.reduxState<StoreState>().lessons;
                        const videoId: string = parameters.get(DpkParam.VideoId) || Parameter.value(DpkParam.VideoId, params);
                        const videoInfo: {groupId: number, video: LDAcademyVideo} = LDUtil.getVideo(videoId, academyVideos);
                        const OpenVideo = () => {
                            if (videoInfo) {
                                Platform.dispatch(SetAcademyVideo({video: videoInfo.video, referrer: BIEventReferrer.External}));
                                Platform.dispatch(
                                    SetTradingModal({
                                        tradingModalType: TradingModalType.AcademyVideo,
                                        info: {
                                            visible: true,
                                            params: {videoGroup: videoInfo.groupId}
                                        },
                                    })
                                );
                            } else if (videoId) {
                                Platform.dispatch(ShowPopup({
                                    popup: {
                                        icon: {type: PopupIconType.ERROR},
                                        showClose: true,
                                        message: {
                                            trKey: TranslationKey.videoNotFound
                                        },
                                        actions: [{type: PopupActionType.OK}]
                                    }
                                }));
                            }
                        };
                        if (Platform.environment().type() === EnvType.Web) {
                            if (WebUtil.isMobile()) {
                                Platform.dispatch(NavigateTo({
                                    route: RouteType.Tools
                                }));
                            } else {
                                Platform.dispatch(NavigateTo({
                                    route: RouteType.Lessons
                                }));
                            }
                            OpenVideo();
                        } else if (action) {
                            action();
                            OpenVideo();
                        }
                    }
                    break;
                case DpkType.NativeReview:
                    const {authenticated, IsRateApplicationEnabled} = Platform.reduxState<StoreState>().auth;
                    this._logger.debug(`Try open rate app dialog. Authenticated: ${authenticated}. Rate app allowed: ${IsRateApplicationEnabled}`);
                    if (authenticated && IsRateApplicationEnabled) {
                        Platform.dispatch(SetShowReviewModal({showModal: true}));
                    }
                    break;
                case DpkType.GivePromo:
                    const jwt: string = parameters.get(DpkParam.JWT) || Parameter.value(DpkParam.JWT, params);
                    if (jwt) {
                        Platform.dispatch(DoActivateBonusCode({
                            value: jwt
                        }));
                    }
                    break;
                case DpkType.SignTransactions:
                    if (action) {
                        action(null, params);
                    } else {
                        Platform.dispatch(SetTradingModal({
                            tradingModalType: TradingModalType.SignDeclaration,
                            info: {
                                visible: true
                            }
                        }));
                    }
                    break;
                case DpkType.PushPermission:
                    if (action) {
                        action(null, params);
                    }
                    break;
                case DpkType.RegisterDemo:
                    const {canAddDemoAccount} = Platform.reduxState<StoreState>().auth;
                    if (canAddDemoAccount) {
                        if (action) {
                            action(null, params);
                        } else {
                            Platform.dispatch(SetTradingModal({
                                tradingModalType: TradingModalType.AccessDemoAccount,
                                info: {
                                    visible: true
                                }
                            }));
                        }
                    }
                    break;
                case DpkType.Confetti:
                    if (Platform.environment().type() === EnvType.Web) {
                        const type: string = parameters.get(DpkParam.Type) || Parameter.value(DpkParam.Type, params);
                        Confetti.Run(type).catch(() => {});
                    }
                    break;
                case DpkType.Tour:
                    const tourId: string = parameters.get(DpkParam.Id) || Parameter.value(DpkParam.Id, params);
                    tourId && Platform.dispatch(SetTour({tourId}));
                    break;
                case DpkType.TutorialLearnMore:
                    Platform.dispatch(SetTradingModal({
                        tradingModalType: TradingModalType.TutorialLearnMore,
                        info: {
                            visible: true,
                        },
                    }));
                    break;
            }
        }
    }
}
