import {ActionCreatorDefinition, createAction} from "redux-ts";
import {TSMap} from "typescript-map";
import {
    LDAcademyVideo,
    LDAcademyVideoGroup,
    LDTutorial
} from "core/props/LDPlatformProps";
import {BIEventReferrer} from "enum/BIEventReferrer";

export const FetchUIConfigurationType: string = "FetchUIConfiguration";
export const SetLDAcademyConfigurationType: string = "SetLDAcademyConfiguration";
export const SetAcademyVideoType: string = "SetAcademyVideoType";
export const SetTourType: string = "SetTour";

export interface SetLDAcademyConfigurationPayload {

    tutorials: LDTutorial[];
    academyVideoGroups: LDAcademyVideoGroup[];
    academyVideos: TSMap<number, LDAcademyVideo[]>;
    supportZendesk: {[key: string]: string};
}

export interface SetAcademyVideoPayload {

    video: LDAcademyVideo;
    referrer: BIEventReferrer;
}

export interface SetTourPayload {
    tourId: string;
}

export const FetchUIConfiguration: ActionCreatorDefinition<any, any> = createAction<any>(FetchUIConfigurationType);
export const SetLDAcademyConfiguration: ActionCreatorDefinition<SetLDAcademyConfigurationPayload, any> = createAction<SetLDAcademyConfigurationPayload>(SetLDAcademyConfigurationType);
export const SetAcademyVideo: ActionCreatorDefinition<SetAcademyVideoPayload, any> = createAction<SetAcademyVideoPayload>(SetAcademyVideoType);
export const SetTour: ActionCreatorDefinition<SetTourPayload, any> = createAction<SetTourPayload>(SetTourType);
