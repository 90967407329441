import {ActionCreatorDefinition, createAction} from "redux-ts";
import {EduTradingAcademyVideos} from "protocol/edutrading/EduTradingAcademyVideosResponse";
import {EduTradingTradeContentTab} from "enum/EduTradingTradeContentTab";
import {EduTradingEBook} from "protocol/edutrading/EduTradingEBooksResponse";
import {EduTradingArticle} from "protocol/edutrading/EduTradingArticlesResponse";
import {EduTradingEconomicCalendarPeriod} from "protocol/edutrading/EduTradingEconomicCalendarPeriod";
import {EduTradingEconomicCalendarEvent} from "protocol/edutrading/EduTradingEconomicCalendarEventsResponse";
import {EduTradingSignal} from "protocol/edutrading/EduTradingSignalsResponse";
import {EduTradingChartTab} from "enum/EduTradingChartTab";
import {EduFilterDate, EduFilterValue, PlaylistInfo} from "core/redux/eduTrading/EduTradingReduxState";
import {TSMap} from "typescript-map";
import {EduTradingSignalsPeriod} from "protocol/edutrading/EduTradingSignalsPeriod";
import {EduTradingCryptoCalendarPeriod} from "protocol/edutrading/EduTradingCryptoCalendarPeriod";
import {EduTradingCryptoCalendarEvent} from "protocol/edutrading/EduTradingCryptoCalendarEventsResponse";
import {EduTradingMarketVideoType} from "protocol/edutrading/EduTradingMarketVideoType";
import {EduTradingMarketVideo} from "protocol/edutrading/EduTradingMarketVideosResponse";
import {CurrencyStrengthMeter} from "protocol/edutrading/EduTradingCurrencyStrengthMeterResponse";
import {Glossary} from "protocol/edutrading/EduTradingGlossaryResponse";
import {EduTradingNewsCategoryType} from "protocol/edutrading/EduTradingNewsCategoryType";
import {TrendAnalysisItem} from "protocol/edutrading/EduTradingTrendAnalysisResponse";
import {BooleanPayload} from "core/redux/StoreActions";

export const EduTradingSetActiveChartTabType: string = "EduTradingSetActiveChartTab";
export const EduTradingSetActiveTradeContentTabType: string = "EduTradingSetActiveTradeContentTab";
export const EduTradingSetActiveTradeMobileTabType: string = "EduTradingSetActiveTradeMobileTab";
export const EduTradingSetAvailableMobileChartType: string = "EduTradingSetAvailableMobileChart";
export const EduTradingFetchAcademyVideosType: string = "EduTradingFetchAcademyVideos";
export const EduTradingSetAcademyVideosType: string = "EduTradingSetAcademyVideos";
export const EduTradingFetchEBooksType: string = "EduTradingFetchEBooks";
export const EduTradingSetEBooksType: string = "EduTradingSetEBooks";
export const EduTradingFetchArticlesType: string = "EduTradingFetchArticles";
export const EduTradingSetArticlesType: string = "EduTradingSetArticles";
export const EduTradingFetchEconomicCalendarEventsType: string = "EduTradingFetchEconomicCalendarEvents";
export const EduTradingSetEconomicCalendarEventsType: string = "EduTradingSetEconomicCalendarEvents";
export const EduTradingFetchSignalsType: string = "EduTradingFetchSignals";
export const EduTradingSetSignalsType: string = "EduTradingSetSignals";
export const EduTradingSetEconomicCalendarPeriodType: string = "EduTradingSetEconomicCalendarPeriod";
export const EduTradingSetEconomicCalendarFiltersType: string = "EduTradingSetEconomicCalendarFilters";
export const EduTradingSetEconomicCalendarDateFilterType: string = "EduTradingSetEconomicCalendarDateFilter";
export const EduTradingSetSignalsPeriodType: string = "EduTradingSetSignalsPeriod";
export const EduTradingSetSignalsFiltersType: string = "EduTradingSetSignalsFilters";
export const EduTradingSetAcademyPlaylistProgressType: string = "EduTradingSetAcademyPlaylistProgress";
export const EduTradingSetLoadingType: string = "EduTradingSetLoading";
export const EduTradingFetchCryptoCalendarEventsType: string = "EduTradingFetchCryptoCalendarEvents";
export const EduTradingSetCryptoCalendarEventsType: string = "EduTradingSetCryptoCalendarEvents";
export const EduTradingSetCryptoCalendarPeriodType: string = "EduTradingSetCryptoCalendarPeriod";
export const EduTradingSetCryptoCalendarFiltersType: string = "EduTradingSetCryptoCalendarFilters";
export const EduTradingSetCryptoCalendarDateFilterType: string = "EduTradingSetCryptoCalendarDateFilter";
export const EduTradingFetchMarketVideosType: string = "EduTradingFetchMarketSearchVideos";
export const EduTradingSetMarketSearchVideosType: string = "EduTradingSetMarketSearchVideos";
export const EduTradingFetchCurrencyStrengthMeterType: string = "EduTradingFetchCurrencyStrengthMeter";
export const EduTradingSetCurrencyStrengthMeterType: string = "EduTradingSetCurrencyStrengthMeter";
export const EduTradingFetchGlossaryType: string = "EduTradingFetchGlossary";
export const EduTradingSetGlossaryType: string = "EduTradingSetGlossary";
export const EduTradingFetchTrendAnalysisType: string = "EduTradingFetchTrendAnalysis";
export const EduTradingSetTrendAnalisysType: string = "EduTradingSetTrendAnalisys";
export const EduTradingSetActiveNewsTabType: string = "EduTradingSetActiveNewsTab";
export const EduTradingSetTrendAnalysisMarketType: string = "EduTradingSetTrendAnalysisMarket";
export const EduTradingSetActiveMarketVideoTabType: string = "EduTradingSetActiveMarketVideoTab";

export interface EduTradingSetActiveChartTabPayload {

    Tab: EduTradingChartTab;
}

export interface EduTradingSetActiveTradeContentTabPayload {

    Tab: EduTradingTradeContentTab;
}

export interface EduTradingSetActiveTradeMobileTabPayload {

    Tab: EduTradingTradeContentTab;
}

export interface EduTradingSetAcademyVideosPayload {

    AcademyVideos: EduTradingAcademyVideos[];
}

export interface EduTradingSetEBooksPayload {

    Books: EduTradingEBook[];
}

export interface EduTradingSetArticlesPayload {

    Articles: EduTradingArticle[];
    articleType: EduTradingNewsCategoryType;
}

export interface EduTradingFetchEconomicCalendarEventsPayload {

    Period?: EduTradingEconomicCalendarPeriod;
    dateFilter?: EduFilterDate;
}

export interface EduTradingSetEconomicCalendarEventsPayload {

    Events: EduTradingEconomicCalendarEvent[];
}

export interface EduTradingSetSignalsPayload {

    Signals: EduTradingSignal[];
}

export interface EduTradingSetEconomicCalendarPeriodPayload {

    period: EduTradingEconomicCalendarPeriod;
}

export interface EduTradingSetEconomicCalendarFiltersPayload {

    filters: TSMap<string, EduFilterValue[]>;
}


export interface EduTradingSetEconomicCalendarDateFilterPayload {

    dateFilter: EduFilterDate;
}

export interface EduTradingSetSignalsPeriodPayload {

    period: EduTradingSignalsPeriod;
}

export interface EduTradingFetchSignalsPayload {

    period?: EduTradingSignalsPeriod;
}

export interface EduTradingFetchAcademyVideosPayload {

    playlistId: string;
}

export interface EduTradingSetAcademyPlaylistProgressPayload {

    data: TSMap<string, PlaylistInfo>;
}

export interface EduTradingSetLoadingPayload {

    loading: boolean;
}

export interface EduTradingFetchCryptoCalendarEventsPayload {

    Period?: EduTradingCryptoCalendarPeriod;
    dateFilter?: EduFilterDate;
}

export interface EduTradingSetCryptoCalendarEventsPayload {

    cryptoEvents: EduTradingCryptoCalendarEvent[];
}

export interface EduTradingSetCryptoCalendarPeriodPayload {

    period?: EduTradingCryptoCalendarPeriod;
}

export interface EduTradingSetCryptoCalendarDateFilterPayload {

    dateFilter: EduFilterDate;
}

export interface EduTradingFiltersPayload {

    filters: TSMap<string, EduFilterValue[]>;
}

export interface EduTradingFetchMarketVideosPayload {

    videoType: EduTradingMarketVideoType;
}

export interface EduTradingSetMarketSearchVideosPayload {

    videoType: EduTradingMarketVideoType;
    videos: EduTradingMarketVideo[];
}

export interface EduTradingSetCurrencyStrengthMeterPayload {

    currencies: CurrencyStrengthMeter[];
}

export interface EduTradingSetGlossaryPayload {

    glossary: Glossary[];
}

export interface EduTradingFetchArticlesPayload {

    articleType: EduTradingNewsCategoryType;
}

export interface EduTradingSetTrendAnalisysPayload {

    trendAnalysis: TrendAnalysisItem[];
}

export interface EduTradingSetActiveNewsTabPayload {

    categoryType: EduTradingNewsCategoryType;
    tab: string;
}

export interface EduTradingSetTrendAnalysisMarketPayload {

    market: string;
}

export interface EduTradingSetActiveMarketVideoTabPayload {

    category: EduTradingMarketVideoType;
    tab: string;
}

export const EduTradingSetActiveChartTab: ActionCreatorDefinition<EduTradingSetActiveChartTabPayload, any> = createAction<EduTradingSetActiveChartTabPayload>(EduTradingSetActiveChartTabType);
export const EduTradingSetActiveTradeContentTab: ActionCreatorDefinition<EduTradingSetActiveTradeContentTabPayload, any> = createAction<EduTradingSetActiveTradeContentTabPayload>(EduTradingSetActiveTradeContentTabType);
export const EduTradingSetActiveTradeMobileTab: ActionCreatorDefinition<EduTradingSetActiveTradeMobileTabPayload, any> = createAction<EduTradingSetActiveTradeMobileTabPayload>(EduTradingSetActiveTradeMobileTabType);
export const EduTradingSetAvailableMobileChart: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(EduTradingSetAvailableMobileChartType);
export const EduTradingFetchAcademyVideos: ActionCreatorDefinition<EduTradingFetchAcademyVideosPayload, any> = createAction<EduTradingFetchAcademyVideosPayload>(EduTradingFetchAcademyVideosType);
export const EduTradingSetAcademyVideos: ActionCreatorDefinition<EduTradingSetAcademyVideosPayload, any> = createAction<EduTradingSetAcademyVideosPayload>(EduTradingSetAcademyVideosType);
export const EduTradingFetchEBooks: ActionCreatorDefinition<any, any> = createAction<any>(EduTradingFetchEBooksType);
export const EduTradingSetEBooks: ActionCreatorDefinition<EduTradingSetEBooksPayload, any> = createAction<EduTradingSetEBooksPayload>(EduTradingSetEBooksType);
export const EduTradingFetchArticles: ActionCreatorDefinition<EduTradingFetchArticlesPayload, any> = createAction<EduTradingFetchArticlesPayload>(EduTradingFetchArticlesType);
export const EduTradingSetArticles: ActionCreatorDefinition<EduTradingSetArticlesPayload, any> = createAction<EduTradingSetArticlesPayload>(EduTradingSetArticlesType);
export const EduTradingFetchEconomicCalendarEvents: ActionCreatorDefinition<EduTradingFetchEconomicCalendarEventsPayload, any> = createAction<EduTradingFetchEconomicCalendarEventsPayload>(EduTradingFetchEconomicCalendarEventsType);
export const EduTradingSetEconomicCalendarEvents: ActionCreatorDefinition<EduTradingSetEconomicCalendarEventsPayload, any> = createAction<EduTradingSetEconomicCalendarEventsPayload>(EduTradingSetEconomicCalendarEventsType);
export const EduTradingFetchSignals: ActionCreatorDefinition<EduTradingFetchSignalsPayload, any> = createAction<EduTradingFetchSignalsPayload>(EduTradingFetchSignalsType);
export const EduTradingSetSignals: ActionCreatorDefinition<EduTradingSetSignalsPayload, any> = createAction<EduTradingSetSignalsPayload>(EduTradingSetSignalsType);
export const EduTradingSetEconomicCalendarPeriod: ActionCreatorDefinition<EduTradingSetEconomicCalendarPeriodPayload, any> = createAction<EduTradingSetEconomicCalendarPeriodPayload>(EduTradingSetEconomicCalendarPeriodType);
export const EduTradingSetEconomicCalendarFilters: ActionCreatorDefinition<EduTradingSetEconomicCalendarFiltersPayload, any> = createAction<EduTradingSetEconomicCalendarFiltersPayload>(EduTradingSetEconomicCalendarFiltersType);
export const EduTradingSetEconomicCalendarDateFilter: ActionCreatorDefinition<EduTradingSetEconomicCalendarDateFilterPayload, any> = createAction<EduTradingSetEconomicCalendarDateFilterPayload>(EduTradingSetEconomicCalendarDateFilterType);
export const EduTradingSetSignalsPeriod: ActionCreatorDefinition<EduTradingSetSignalsPeriodPayload, any> = createAction<EduTradingSetSignalsPeriodPayload>(EduTradingSetSignalsPeriodType);
export const EduTradingSetAcademyPlaylistProgress: ActionCreatorDefinition<EduTradingSetAcademyPlaylistProgressPayload, any> = createAction<EduTradingSetAcademyPlaylistProgressPayload>(EduTradingSetAcademyPlaylistProgressType);
export const EduTradingSetLoading: ActionCreatorDefinition<EduTradingSetLoadingPayload, any> = createAction<EduTradingSetLoadingPayload>(EduTradingSetLoadingType);
export const EduTradingFetchCryptoCalendarEvents: ActionCreatorDefinition<EduTradingFetchCryptoCalendarEventsPayload, any> = createAction<EduTradingFetchCryptoCalendarEventsPayload>(EduTradingFetchCryptoCalendarEventsType);
export const EduTradingSetCryptoCalendarEvents: ActionCreatorDefinition<EduTradingSetCryptoCalendarEventsPayload, any> = createAction<EduTradingSetCryptoCalendarEventsPayload>(EduTradingSetCryptoCalendarEventsType);
export const EduTradingSetCryptoCalendarPeriod: ActionCreatorDefinition<EduTradingSetCryptoCalendarPeriodPayload, any> = createAction<EduTradingSetCryptoCalendarPeriodPayload>(EduTradingSetCryptoCalendarPeriodType);
export const EduTradingSetCryptoCalendarDateFilter: ActionCreatorDefinition<EduTradingSetCryptoCalendarDateFilterPayload, any> = createAction<EduTradingSetCryptoCalendarDateFilterPayload>(EduTradingSetCryptoCalendarDateFilterType);
export const EduTradingSetCryptoCalendarFilters: ActionCreatorDefinition<EduTradingFiltersPayload, any> = createAction<EduTradingFiltersPayload>(EduTradingSetCryptoCalendarFiltersType);
export const EduTradingSetSignalsFilters: ActionCreatorDefinition<EduTradingFiltersPayload, any> = createAction<EduTradingFiltersPayload>(EduTradingSetSignalsFiltersType);
export const EduTradingFetchMarketVideos: ActionCreatorDefinition<EduTradingFetchMarketVideosPayload, any> = createAction<EduTradingFetchMarketVideosPayload>(EduTradingFetchMarketVideosType);
export const EduTradingSetMarketSearchVideos: ActionCreatorDefinition<EduTradingSetMarketSearchVideosPayload, any> = createAction<EduTradingSetMarketSearchVideosPayload>(EduTradingSetMarketSearchVideosType);
export const EduTradingFetchCurrencyStrengthMeter: ActionCreatorDefinition<any, any> = createAction<any>(EduTradingFetchCurrencyStrengthMeterType);
export const EduTradingSetCurrencyStrengthMeter: ActionCreatorDefinition<EduTradingSetCurrencyStrengthMeterPayload, any> = createAction<EduTradingSetCurrencyStrengthMeterPayload>(EduTradingSetCurrencyStrengthMeterType);
export const EduTradingFetchGlossary: ActionCreatorDefinition<any, any> = createAction<any>(EduTradingFetchGlossaryType);
export const EduTradingSetGlossary: ActionCreatorDefinition<EduTradingSetGlossaryPayload, any> = createAction<EduTradingSetGlossaryPayload>(EduTradingSetGlossaryType);
export const EduTradingFetchTrendAnalysis: ActionCreatorDefinition<any, any> = createAction<any>(EduTradingFetchTrendAnalysisType);
export const EduTradingSetTrendAnalisys: ActionCreatorDefinition<EduTradingSetTrendAnalisysPayload, any> = createAction<EduTradingSetTrendAnalisysPayload>(EduTradingSetTrendAnalisysType);
export const EduTradingSetActiveNewsTab: ActionCreatorDefinition<EduTradingSetActiveNewsTabPayload, any> = createAction<EduTradingSetActiveNewsTabPayload>(EduTradingSetActiveNewsTabType);
export const EduTradingSetTrendAnalysisMarket: ActionCreatorDefinition<EduTradingSetTrendAnalysisMarketPayload, any> = createAction<EduTradingSetTrendAnalysisMarketPayload>(EduTradingSetTrendAnalysisMarketType);
export const EduTradingSetActiveMarketVideoTab: ActionCreatorDefinition<EduTradingSetActiveMarketVideoTabPayload, any> = createAction<EduTradingSetActiveMarketVideoTabPayload>(EduTradingSetActiveMarketVideoTabType);
