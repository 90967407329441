import {TSMap} from "typescript-map";
import {Tutorial} from "component/tour/types";
import {howToAutomateTrade} from "component/lessons/tutorials/howToAutomateTrade";
import {howToOpenTrade} from "component/lessons/tutorials/howToOpenTrade";
import {howToCloseTrade} from "component/lessons/tutorials/howToCloseTrade";
import {howToUseTradeButton} from "component/lessons/tutorials/howToUseTradeButton";
import {howToUseStopLossAndTakeProfit} from "component/lessons/tutorials/howToUseStopLossAndTakeProfit";
import {TutorialType} from "enum/TutorialType";

const Tutorials: TSMap<string, Tutorial> = new TSMap<string, Tutorial>();
Tutorials.set(TutorialType.HowToAutomateTrade, howToAutomateTrade);
Tutorials.set(TutorialType.HowToOpenTrade, howToOpenTrade);
Tutorials.set(TutorialType.HowToCloseTrade, howToCloseTrade);
Tutorials.set(TutorialType.HowToUseTradeBtn, howToUseTradeButton);
Tutorials.set(TutorialType.HowToUseSlTp, howToUseStopLossAndTakeProfit);

export const GetTutorialById = (id: string): Tutorial => {
    return Tutorials.get(id);
}
