import moment from "moment-mini";
import {Engine} from "platform/engine/Engine";
import {SetPositionsOrdersPayload, SetTrades} from "core/redux/trades/TradesReduxActions";
import Platform from "platform/Platform";
import {StoreState} from "core/redux/StoreState";
import {TradeColumn} from "enum/TradeColumn";
import {SortDirection} from "platform/enum/SortDirection";
import Utils from "platform/util/Utils";
import {Trade} from "core/redux/trades/TradesReduxState";
import {Deal} from "platform/protocol/trading/Deal";
import {TradeUtil} from "core/util/TradeUtil";
import {Exposure} from "protocol/trade/Exposure";
import {TSMap} from "typescript-map";
import {PositionDirection} from "platform/protocol/enum/PositionDirection";
import {PortfolioType} from "enum/PortfolioType";
import {PortfolioTableState} from "core/redux/portfolio/PortfolioReduxState";
import {
    SetPortfolioCounter,
    SetPortfolioFilterCounter,
    SetPortfolioTabPayload
} from "core/redux/portfolio/PortfolioReduxActions";
import {SortTrades as SortTradesAction} from "core/redux/trades/TradesReduxActions";
import {ServiceType} from "enum/ServiceType";
import {AccountState} from "core/state/AccountState";
import {XhrUtil} from "core/util/XhrUtil";
import {
    SetClosedTradeDetail,
    SetClosedTrades,
    SetOrderDetail,
    SetOrdersHistory
} from "core/redux/trades/ClosedTradesReduxActions";
import DateTimeFormat from "core/format/DateTimeFormat";
import {FilterDateState, FilterDateType} from "core/redux/filter/FilterReduxState";
import {FilterUtil} from "core/util/FilterUtil";
import {
    PortfolioDateFilterPayload,
    SetClosedTradesDefaultFilter,
    SetHistoryOrderStatusPayload,
    UpdatePortfolioDateFilter
} from "core/redux/filter/FilterReduxActions";
import {SymbolsState} from "core/state/SymbolsState";
import {HttpReject} from "platform/network/http/Http";
import {RouteType} from "core/router/Routes";
import {DpkParam} from "enum/DpkParam";
import Parameter from "platform/util/Parameter";
import {SetTradingModal, ToggleMenu} from "core/redux/app/AppReduxActions";
import TradingModalType from "enum/TradingModalType";
import {GetClosedTradesRequest} from "protocol/trade/GetClosedTradesRequest";
import {GetEntryOrdersHistoryRequest} from "protocol/trade/GetEntryOrdersHistoryRequest";
import {EntryOrderStatus} from "platform/protocol/trading/EntryOrderStatus";
import {GetEntryOrdersHistoryResponse} from "protocol/trade/GetEntryOrdersHistoryResponse";
import {IdPayload} from "core/redux/StoreActions";
import {PaginationDealsTable} from "core/redux/trades/ClosedTradesReduxState";
import WebUtil from "platform/util/WebUtil";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIconType} from "platform/redux/popups/PopupsReduxState";
import {TranslationKey} from "enum/TranslationKey";
import {TranslationParam} from "enum/TranslationParam";
import {GetClosedTradesResponse} from "protocol/trade/GetClosedTradesResponse";
import {OrderType} from "platform/protocol/trading/OrderType";
import {HideLoader, SetLoader} from "platform/redux/core/CoreActions";
import {LoaderType} from "platform/enum/LoaderType";
import {ClosedPositionsBySymbolThreshold, ClosedPositionsThreshold, OrdersHistoryThreshold} from "core/Constants";
import {GetClosedPositionByIdRequest} from "protocol/trade/GetClosedPositionByIdRequest";

export const SortTrades = (column: TradeColumn, direction: SortDirection) => {
    return (t1: Trade, t2: Trade): number => {
        let result: number = 0;
        switch (column) {
            case TradeColumn.Symbol:
                result = Utils.compareString(t1.SymbolDisplayName, t2.SymbolDisplayName);
                break;
            case TradeColumn.OpenRate:
                result = Utils.compareNumber(t1.Rate, t2.Rate);
                break;
            case TradeColumn.Direction:
                result = Utils.compareNumber(t1.PositionDirections.length, t2.PositionDirections.length);
                if (result === 0) {
                    result = Utils.compareNumber(t1.PositionDirections[0], t2.PositionDirections[0]);
                }
                break;
            case TradeColumn.Order:
                result = Utils.compareNumber(t1.Amount, t2.Amount);
                break;
            case TradeColumn.MarketRate:
                result = Utils.compareNumber(t1.MarketRate, t2.MarketRate);
                break;
            case TradeColumn.MarketValue:
                result = Utils.compareNumber(t1.MarketValue, t2.MarketValue);
                break;
            case TradeColumn.TotalProfit:
                result = Utils.compareNumber(t1.PL, t2.PL);
                break;
            case TradeColumn.TakeProfit:
                result = Utils.compareNumber(t1.TakeProfitAmount || t1.TakeProfitRate, t2.TakeProfitAmount || t2.TakeProfitRate);
                break;
            case TradeColumn.StopLoss:
                result = Utils.compareNumber(t1.StopLossAmount || t1.StopLossRate, t2.StopLossAmount || t2.StopLossRate);
                break;
            case TradeColumn.CreatedDate:
                result = Utils.compareNumber(TradeUtil.createDate(t1), TradeUtil.createDate(t2));
                break;
        }
        return direction === SortDirection.Ask ? result : -result;
    };
};

export const SortDeals = (column: TradeColumn, direction: SortDirection) => {
    return (d1: Deal, d2: Deal): number => {
        let result: number = 0;
        switch (column) {
            case TradeColumn.Symbol:
                result = Utils.compareString(d1.SymbolDisplayName, d2.SymbolDisplayName);
                break;
            case TradeColumn.OpenRate:
                result = Utils.compareNumber(d1.Rate, d2.Rate);
                break;
            case TradeColumn.CloseRate:
                result = Utils.compareNumber(d1.CloseRate, d2.CloseRate);
                break;
            case TradeColumn.MarketRate:
                result = Utils.compareNumber(d1.MarketRate, d2.MarketRate);
                break;
            case TradeColumn.MarketValue:
                result = Utils.compareNumber(d1.MarketValue, d2.MarketValue);
                break;
            case TradeColumn.Direction:
                result = Utils.compareNumber(d1.PositionDirection, d2.PositionDirection);
                break;
            case TradeColumn.Order:
                result = Utils.compareNumber(d1.Amount, d2.Amount);
                break;
            case TradeColumn.TotalProfit:
                result = Utils.compareNumber(d1.PL, d2.PL);
                break;
            case TradeColumn.TakeProfit:
                result = Utils.compareNumber(d1.TakeProfitAmount || d1.TakeProfitRate, d2.TakeProfitAmount || d2.TakeProfitRate);
                break;
            case TradeColumn.StopLoss:
                result = Utils.compareNumber(d1.StopLossAmount || d1.StopLossRate, d2.StopLossAmount || d2.StopLossRate);
                break;
            case TradeColumn.CreatedDate:
                result = Utils.compareNumber(d1.Date, d2.Date);
                break;
            case TradeColumn.CloseDate:
                result = Utils.compareNumber(d1.CloseDate, d2.CloseDate);
                break;
            case TradeColumn.UnitsOpenrate:
                result = Utils.compareNumber(d1.Rate, d2.Rate);
                break;
        }
        return direction === SortDirection.Ask ? result : -result;
    };
};

export default class TradesEngine extends Engine {

    private static _instance: TradesEngine;

    public static instance(): TradesEngine {
        return this._instance || (this._instance = new this());
    }

    public onSetPositionsOrders = (payload: SetPositionsOrdersPayload): void => {
        const trades: Trade[] = [];
        const exposurePerSymbol: TSMap<number, Trade> = new TSMap();
        payload.Exposures.forEach((exposure: Exposure) => {
            if (exposure.OpenPositionsAmount > 1) {
                const trade: Trade = TradeUtil.mapExposure(exposure);
                trades.push(trade);
                exposurePerSymbol.set(exposure.SymbolId, trade);
            }
        });
        const storeState: StoreState = Platform.reduxState<StoreState>();
        const selectedDeals: TSMap<PortfolioType, Deal> = storeState.trades.selectedDeals.clone();
        const selectedPosition: Deal = selectedDeals.get(PortfolioType.OpenTrades);
        const selectedOrder: Deal = selectedDeals.get(PortfolioType.EntryOrders);
        const filterTrades: FilterDateState = storeState.filter.portfolioDateFilter.get(PortfolioType.OpenTrades);
        const filterOrders: FilterDateState = storeState.filter.portfolioDateFilter.get(PortfolioType.EntryOrders);
        let foundSelectedPosition: boolean;
        let foundSelectedOrder: boolean;
        let filterCountTrades: number = 0;
        let filterCountOrders: number = 0;
        const symbolIds: TSMap<number, any> = new TSMap();
        const OpenPositionsMap: {[key: number]: Deal} = {};
        const EntryOrdersMap: {[key: number]: Deal} = {};
        const EntryOrders: Deal[] = [];
        payload.EntryOrders.forEach((deal: Deal) => {
            EntryOrdersMap[deal.Id] = deal;
            if (FilterUtil.filterDeal(filterOrders, deal)) {
                filterCountOrders++;
                EntryOrders.push(deal);
            }
            symbolIds.set(deal.SymbolId, null);
            if (selectedOrder && selectedOrder.Id === deal.Id) {
                foundSelectedOrder = true;
            }
        });
        Platform.dispatch(SetPortfolioFilterCounter({
            portfolioType: PortfolioType.EntryOrders,
            value: filterCountOrders
        }));
        payload.OpenPositions.forEach((deal: Deal) => {
            OpenPositionsMap[deal.Id] = deal;
            if (FilterUtil.filterDeal(filterTrades, deal)) {
                filterCountTrades++;
            }
            symbolIds.set(deal.SymbolId, null);
            const trade: Trade = TradeUtil.mapDeal(deal);
            const exposure: Trade = exposurePerSymbol.get(deal.SymbolId);
            if (exposure) {
                exposure.SymbolRoundingDigits = trade.SymbolRoundingDigits;
                exposure.trades.push(trade);
                if (exposure.PositionDirections.indexOf(deal.PositionDirection) < 0) {
                    exposure.PositionDirections.push(deal.PositionDirection);
                    if (exposure.PositionDirections.length > 1) {
                        exposure.PositionDirections = [PositionDirection.Buy, PositionDirection.Sell];
                    }
                }
            } else {
                trades.push(trade);
            }
            if (selectedPosition && selectedPosition.Id === deal.Id) {
                foundSelectedPosition = true;
            }
        });
        Platform.dispatch(SetPortfolioFilterCounter({
            portfolioType: PortfolioType.OpenTrades,
            value: filterCountTrades
        }));
        const symbolsState: SymbolsState = Platform.state(ServiceType.Symbols);
        symbolsState.symbolsDealIds = symbolIds.keys() || [];
        const {portfolio, settings} = Platform.reduxState<StoreState>();
        const {tables} = portfolio;
        const otState: PortfolioTableState = tables.get(PortfolioType.OpenTrades);
        const eoState: PortfolioTableState = tables.get(PortfolioType.EntryOrders);
        const otColumn: TradeColumn = otState.activeColumn;
        const otSort: SortDirection = otState.sort.get(otColumn);
        const filteredTrades: Trade[] = [];
        trades.forEach((t: Trade) => {
            const trade: Trade = FilterUtil.filterTrade(filterTrades, t);
            if (trade) {
                filteredTrades.push(trade);
            }
        });
        filteredTrades.sort(SortTrades(otColumn, otSort));
        exposurePerSymbol.values().forEach((trade: Trade) => {
            trade.trades.sort(SortTrades(otColumn, otSort));
        });
        payload.OpenPositions.sort(SortDeals(otColumn, otSort));
        EntryOrders.sort(SortDeals(eoState.activeColumn, eoState.sort.get(eoState.activeColumn)));
        if (settings.preferencesFetched) {
            if (!foundSelectedPosition) {
                selectedDeals.set(PortfolioType.OpenTrades, payload.OpenPositions[0]);
            }
            if (!foundSelectedOrder) {
                selectedDeals.set(PortfolioType.EntryOrders, EntryOrders[0]);
            }
        }
        Platform.dispatch(SetTrades({
            OpenPositionsMap,
            EntryOrdersMap,
            EntryOrders,
            trades: filteredTrades,
            selectedDeals,
            OverviewPositions: [...payload.OpenPositions].sort((d1: Deal, d2: Deal) => Utils.compareNumber(d2.Date, d1.Date))
        }));
    }

    public onSetPortfolioTab = async (payload: SetPortfolioTabPayload) => {
        const {portfolioDateFilter} = Platform.reduxState<StoreState>().filter;
        const filter: FilterDateState = portfolioDateFilter.get(payload.portfolioType);
        if (payload.portfolioType === PortfolioType.ClosedTrades) {
            this.fetchClosedTrades(filter.dateType, filter.from, filter.to);
        } else if (payload.portfolioType === PortfolioType.OrdersHistory) {
            await this.fetchOrdersHistory(1, filter.orderStatus, filter.dateType, filter.from, filter.to);
        }
    }

    public onSetPortfolioDateFilter = async (payload: PortfolioDateFilterPayload) => {
        if (payload.routeName === RouteType.MyTrades) {
            if (payload.portfolioType === PortfolioType.ClosedTrades) {
                this._logger.debug("Set closed trades date filter to: " + payload.dateType);
                this.fetchClosedTradesInternal(1, payload.dateType, payload.from, payload.to).catch(() => {
                    this._logger.warn("Failed fetch closed trades on date filter change");
                });
            } else if (payload.portfolioType === PortfolioType.OrdersHistory && !payload.isMobile) { // NOTE: next dispatch SetHistoryOrderStatus will fetch OrdersHistory
                this._logger.debug("Set orders history date filter to: " + payload.dateType);
                const {portfolioDateFilter} = Platform.reduxState<StoreState>().filter;
                const filter: FilterDateState = portfolioDateFilter.get(PortfolioType.OrdersHistory);
                await this.fetchOrdersHistory(1, filter.orderStatus, payload.dateType, payload.from, payload.to);
            }
        }
    }

    public onSetHistoryOrderStatus = async (payload: SetHistoryOrderStatusPayload) => {
        if (payload.routeName !== RouteType.FollowedAccounts && payload.routeName !== RouteType.FollowedAccount) {
            const {portfolioDateFilter} = Platform.reduxState<StoreState>().filter;
            const filter: FilterDateState = portfolioDateFilter.get(PortfolioType.OrdersHistory);
            await this.fetchOrdersHistory(1, payload.orderStatus, filter.dateType, filter.from, filter.to);
        }
    }

    public onLoadMoreHistoryOrders = async () => {
        const {portfolioDateFilter} = Platform.reduxState<StoreState>().filter;
        const filter: FilterDateState = portfolioDateFilter.get(PortfolioType.OrdersHistory);
        const ordersHistory: PaginationDealsTable = Platform.reduxState<StoreState>().closedTrades.OrdersHistoryTable;
        await this.fetchOrdersHistory(ordersHistory.page + 1, filter.orderStatus, filter.dateType, filter.from, filter.to);
    }

    public onLoadMoreClosedTrades = async () => {
        const {portfolioDateFilter} = Platform.reduxState<StoreState>().filter;
        const filter: FilterDateState = portfolioDateFilter.get(PortfolioType.ClosedTrades);
        const closedTrades: PaginationDealsTable = Platform.reduxState<StoreState>().closedTrades.ClosedTradesTable;
        await this.fetchClosedTradesInternal(closedTrades.page + 1, filter.dateType, filter.from, filter.to);
    }

    private fetchClosedTrades = (dateType: FilterDateType, from?: moment.Moment, to?: moment.Moment): void => {
        // NOTE: We will recalculate default filter each time user navigate to the closed trades tab
        // (because user can close trade during the session)
        Platform.dispatch(SetClosedTradesDefaultFilter({dateType}));
        this.fetchClosedTradesInternal(1, dateType, from, to).then((closedTrades: Deal[]) => {
            if (Utils.isArrayEmpty(closedTrades)) {
                const nextDateType: FilterDateType = FilterUtil.nextClosedTradesDateType(dateType);
                if (Utils.isNotNull(nextDateType)) {
                    this.fetchClosedTrades(nextDateType);
                }
            } else {
                Platform.dispatch(UpdatePortfolioDateFilter({
                    routeName: RouteType.MyTrades,
                    portfolioType: PortfolioType.ClosedTrades,
                    dateType,
                    from,
                    to
                }));
            }
        }).catch(() => {
            this._logger.warn("Failed fetch closed trades on switch to ClosedTrades portfolio tab. Date type: " + dateType);
        });
    }

    private fetchClosedTradesInternal = async (page: number, dateType: FilterDateType, from: moment.Moment, to: moment.Moment): Promise<Deal[]> => {
        this._logger.debug("Fetch closed trades");
        Platform.dispatch(SetClosedTrades({table: {
                pending: true,
                page,
                deals: page === 1 ? [] : null
            }}));
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const range: {from: Date, to: Date} = FilterUtil.getDateRange(dateType, from, to);
        const request: GetClosedTradesRequest = {
            AccountIds: [accountState.accountId],
            ClosedDateFrom: dateType === FilterDateType.All ? null : DateTimeFormat.formatServerDate(range.from),
            ClosedDateTo: DateTimeFormat.formatServerDate(range.to),
            PageIndex: page,
            PageSize: ClosedPositionsThreshold
        };
        const answer: [HttpReject, GetClosedTradesResponse] = await Utils.to(XhrUtil.sendToAccountService(request, "GetClosedPositions"));
        if (answer[0]) {
            this._logger.debug("Failed fetch closed trades");
            Platform.dispatch(SetClosedTrades({table: {
                    pending: false
                }}));
            return [];
        } else {
            const {Positions} = answer[1];
            this._logger.debug("Fetched closed trades size: " + Positions?.length);
            Platform.dispatch(SetPortfolioCounter({
                portfolioType: PortfolioType.ClosedTrades,
                value: Positions.length + (page === 1 ? 0 : Platform.reduxState<StoreState>().closedTrades.ClosedTradesTable.deals.length)
            }));
            Platform.dispatch(SetClosedTrades({table: {
                    pending: false,
                    hasMore: ClosedPositionsThreshold === Positions.length,
                    deals: Positions
                }}));
            if (Utils.isArrayNotEmpty(Positions)) {
                const {tables} = Platform.reduxState<StoreState>().portfolio;
                const ctState: PortfolioTableState = tables.get(PortfolioType.ClosedTrades);
                if (ctState) {
                    Platform.dispatch(SortTradesAction({
                        portfolioType: PortfolioType.ClosedTrades,
                        column: ctState.activeColumn,
                        sortDirection: ctState.sort.get(ctState.activeColumn)
                    }));
                }
            }
            return Positions;
        }
    }

    public doFetchClosedTrade = async ({id, FollowedAccountId, action}: IdPayload) => {
        this._logger.debug(`Fetch closed position for: ${id}`);
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const request: GetClosedPositionByIdRequest = {
            PositionId: id,
            FollowedAccountId
        };
        const answer: [HttpReject, Deal] = await Utils.to(XhrUtil.sendToAccountService(request, "GetClosedPositionByIdv2"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            this._logger.debug(`Failed fetch closed position by id: ${id}`);
            Platform.dispatch(ShowPopup({
                popup: {
                    icon: {type: PopupIconType.ERROR},
                    showClose: true,
                    message: {
                        trKey: TranslationKey.positionNotFound,
                        params: [Parameter.Of(TranslationParam.value, id)]
                    },
                    actions: [{type: PopupActionType.OK}]
                }
            }));
        } else {
            Platform.dispatch(SetClosedTradeDetail({trade: answer[1]}));
            if (action) {
                action(RouteType.ClosedTrade, [Parameter.Of(DpkParam.Id, id)]);
            } else {
                if (WebUtil.isMobile()) {
                    Platform.dispatch(SetTradingModal({
                        tradingModalType: TradingModalType.ClosedTrade,
                        info: {
                            visible: true
                        }
                    }));
                } else {
                    Platform.dispatch(SetOrderDetail({order: null}));
                    Platform.dispatch(ToggleMenu({collapse: true}));
                }
            }
        }
    }

    public doFetchSymbolClosedTrades = async (symbolId: number, page: number): Promise<Deal[]> => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const request: GetClosedTradesRequest = {
            AccountIds: [accountState.accountId],
            SymbolIds: [symbolId],
            PageIndex: page + 1,
            PageSize: ClosedPositionsBySymbolThreshold
        };
        this._logger.debug(`Fetch closed trades by symbolId: ${symbolId}. Page: ${page}`);
        const answer: [HttpReject, GetClosedTradesResponse] = await Utils.to(XhrUtil.sendToAccountService(request, "GetClosedPositions"));
        if (answer[0]) {
            this._logger.debug(`Failed fetch closed trades by symbolId: ${symbolId}`);
            return [];
        } else {
            return (answer[1].Positions || []).sort((d1: Deal, d2: Deal) => Utils.compareNumber(d2.CloseDate, d1.CloseDate));
        }
    }

    private fetchOrdersHistory = async (page: number, statuses: EntryOrderStatus[], dateType: FilterDateType, from?: moment.Moment, to?: moment.Moment) => {
        this._logger.debug("Fetch orders history");
        Platform.dispatch(SetOrdersHistory({table: {
                pending: true,
                page,
                deals: page === 1 ? [] : null
            }}));
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const range: {from: Date, to: Date} = FilterUtil.getDateRange(dateType, from, to);
        const request: GetEntryOrdersHistoryRequest = {
            AccountIds: [accountState.accountId],
            CreatedDateFrom: dateType === FilterDateType.All ? null : DateTimeFormat.formatServerDate(range.from),
            CreatedDateTo: DateTimeFormat.formatServerDate(range.to),
            EntryOrderStatuses: statuses,
            EntryOrderTypes: [OrderType.EntryLimit, OrderType.EntryStop, OrderType.Market],
            ShouldCalculateTotalCount: false,
            PageIndex: page,
            PageSize: OrdersHistoryThreshold
        };
        const answer: [HttpReject, GetEntryOrdersHistoryResponse] = await Utils.to(XhrUtil.sendToAccountService(request, "GetEntryOrders"));
        if (answer[0]) {
            this._logger.debug("Failed fetch history orders");
            Platform.dispatch(SetOrdersHistory({table: {
                    pending: false
                }}));
        } else {
            const {EntryOrders} = answer[1];
            this._logger.debug("Fetched history orders size: " + EntryOrders?.length);
            Platform.dispatch(SetPortfolioCounter({
                portfolioType: PortfolioType.OrdersHistory,
                value: EntryOrders.length + (page === 1 ? 0 : Platform.reduxState<StoreState>().closedTrades.OrdersHistoryTable.deals.length)
            }));
            Platform.dispatch(SetOrdersHistory({table: {
                    pending: false,
                    hasMore: OrdersHistoryThreshold === EntryOrders.length,
                    deals: EntryOrders
                }}));
            if (Utils.isArrayNotEmpty(EntryOrders)) {
                const {tables} = Platform.reduxState<StoreState>().portfolio;
                const ctState: PortfolioTableState = tables.get(PortfolioType.OrdersHistory);
                if (ctState) {
                    Platform.dispatch(SortTradesAction({
                        portfolioType: PortfolioType.OrdersHistory,
                        column: ctState.activeColumn,
                        sortDirection: ctState.sort.get(ctState.activeColumn)
                    }));
                }
            }
        }
    }

    public doFetchOrderById = async ({id, action}: IdPayload) => {
        this._logger.debug(`Fetch order by ID: ${id}`);
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, Deal] = await Utils.to(XhrUtil.sendToAccountService(id, "GetExtendedEntryOrder"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            this._logger.debug(`Failed fetch order by id: ${id}`);
            Platform.dispatch(ShowPopup({
                popup: {
                    icon: {type: PopupIconType.ERROR},
                    showClose: true,
                    message: {
                        trKey: TranslationKey.entryOrderNotFound,
                        params: [Parameter.Of(TranslationParam.value, id)]
                    },
                    actions: [{type: PopupActionType.OK}]
                }
            }));
        } else {
            Platform.dispatch(SetOrderDetail({order: answer[1]}));
            if (action) {
                action(RouteType.ClosedOrderHistory, [Parameter.Of("IsNotActive", true)]);
            } else {
                if (WebUtil.isMobile()) {
                    Platform.dispatch(SetTradingModal({
                        tradingModalType: TradingModalType.OrderDetails,
                        info: {
                            visible: true
                        }
                    }));
                } else {
                    Platform.dispatch(SetClosedTradeDetail({trade: null}));
                    Platform.dispatch(ToggleMenu({collapse: true}));
                }
            }
        }
    }
}
