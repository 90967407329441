import React, {Dispatch} from 'react'
import { PopoverContentProps } from '../types'

export type ContentProps = {
  content: any;
  setCurrentStep: Dispatch<React.SetStateAction<number>>;
  setIsOpen?: Dispatch<React.SetStateAction<boolean>>;
  currentStep: number;
  transition?: boolean;
  isHighlightingObserved?: boolean;
  rtl: boolean;
}

const Content: React.FC<ContentProps> = ({
                                           content,
                                           setCurrentStep,
                                           transition,
                                           isHighlightingObserved,
                                           currentStep,
                                           setIsOpen,
                                           rtl,
                                         }) => {
  return typeof content === 'function'
      ? content({
        setCurrentStep,
        transition,
        isHighlightingObserved,
        currentStep,
        setIsOpen,
        rtl,
      })
      : content
}


const PopoverContent: React.FC<PopoverContentProps> = ({
  styles,
  components = {},
  accessibilityOptions,
  disabledActions,
  onClickClose,
  steps,
  setCurrentStep,
  currentStep,
  transition,
  isHighlightingObserved,
  setIsOpen,
  nextButton,
  prevButton,
  disableDotsNavigation,
  rtl,
  showPrevNextButtons = true,
  showCloseButton = true,
  showNavigation = true,
  showBadge = true,
  showDots = true,
  meta,
  setMeta,
  setSteps,
}) => {
  const step = steps[currentStep]

  return (
    <React.Fragment>
      <Content
        content={step?.content}
        setCurrentStep={setCurrentStep}
        currentStep={currentStep}
        transition={transition}
        isHighlightingObserved={isHighlightingObserved}
        setIsOpen={setIsOpen}
        rtl={rtl}
      />
    </React.Fragment>
  )
}

export {PopoverContent}
