import React, {useEffect} from "react";
import {connect} from "react-redux";
import {StoreState} from "core/redux/StoreState";
import {useTour} from "component/tour/TourContext";
import {Tutorial} from "component/tour/types";
import {GetTutorialById} from "component/lessons/tutorials/Tutorials";
import Utils from "platform/util/Utils";

interface IProps {

    tourId?: string;
    isRTL?: boolean;
}

const Bounce: React.FunctionComponent<IProps> = ({tourId, isRTL}: IProps) => {
    const {setTutorial, setTutorialStarted, setSteps, setIsOpen, cancelTutorial, setRTL} = useTour();

    useEffect(() => {
        if (Utils.isNotEmpty(tourId)) {
            const tutorial: Tutorial = GetTutorialById(tourId);
            if (tutorial) {
                setTutorial(tutorial);
                setTutorialStarted(true);
                setIsOpen(true);
            }
        } else {
            cancelTutorial();
        }

    }, [tourId]);

    useEffect(() => {
        setRTL(isRTL || false);
    }, [isRTL]);

    return null;
};

const TourConnect: React.FC<IProps> = connect(
    (state: StoreState): Partial<IProps> => {
        return {
            tourId: state.lessons.tourId,
            isRTL: state.core.rtl,
        };
    })(Bounce) as React.FC<IProps>;

export {
    TourConnect
};
