import moment from "moment-mini";
import isToday from "date-fns/isToday";
import isThisWeek from "date-fns/isThisWeek";
import isThisMonth from "date-fns/isThisMonth";
import isThisYear from "date-fns/isThisYear";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfDay from "date-fns/startOfDay";
import startOfWeek from "date-fns/startOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import startOfYear from "date-fns/startOfYear";
import addWeeks from "date-fns/addWeeks";
import addDays from "date-fns/addDays";
import {Deal} from "platform/protocol/trading/Deal";
import {FilterDateState, FilterDateType, FilterSymbolState} from "core/redux/filter/FilterReduxState";
import {Trade} from "core/redux/trades/TradesReduxState";
import Utils from "platform/util/Utils";
import {TradeUtil} from "core/util/TradeUtil";
import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol";
import {TSMap} from "typescript-map";
import {StoreState} from "core/redux/StoreState";
import {Quote} from "platform/protocol/trading/Quote";
import Platform from "platform/Platform";
import {Continent} from "enum/Continent";
import {Configuration, CountryConfig} from "core/configuration/Configuration";
import {EntryOrderStatus} from "platform/protocol/trading/EntryOrderStatus";
import {PartnerAccount} from "protocol/partner/GetFollowedAccountsResponse";
import {PortfolioType} from "enum/PortfolioType";

const FilterDateTypes: FilterDateType[] = [
    FilterDateType.Today,
    FilterDateType.ThisWeek,
    FilterDateType.ThisMonth,
    FilterDateType.ThisYear,
    FilterDateType.All,
];
const PortfolioFilterTypes: TSMap<PortfolioType, FilterDateType[]> = new TSMap<PortfolioType, FilterDateType[]>();
PortfolioFilterTypes.set(PortfolioType.OpenTrades, FilterDateTypes);
PortfolioFilterTypes.set(PortfolioType.EntryOrders, FilterDateTypes);
PortfolioFilterTypes.set(PortfolioType.ClosedTrades, FilterDateTypes);
PortfolioFilterTypes.set(PortfolioType.ActivityLog, [
    FilterDateType.Today,
    FilterDateType.ThisWeek,
    FilterDateType.ThisMonth,
]);
PortfolioFilterTypes.set(PortfolioType.OrdersHistory, FilterDateTypes);

export class FilterUtil {

    public static portfolioFilterTypes = (): TSMap<PortfolioType, FilterDateType[]> => {
        return PortfolioFilterTypes.clone();
    }

    public static filterSearch = (value: string, search: string): boolean => {
        if (search) {
            if (value) {
                const regExp: RegExp = new RegExp(search, "i");
                if (value.search(regExp) < 0) {
                    return false;
                }
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    public static filterByState = (filter: FilterSymbolState, symbol: TradeSymbol): boolean => {
        let isPass: boolean = true;
        if (Utils.isNotNull(filter) && Utils.isNotNull(symbol)) {
            if (Utils.isNotNull(filter.marketStatus)) {
                const quotes: TSMap<number, Quote> = Platform.reduxState<StoreState>().quotes.quotes;
                const currentQuote: Quote = quotes.get(symbol.SymbolId);
                if (Utils.isNotNull(currentQuote)) {
                    isPass = currentQuote.Status === filter.marketStatus;
                }
            }
            if (isPass && Utils.isArrayNotEmpty(filter.continents) && Utils.isArrayNotEmpty(symbol.countryCodes)) {
                isPass = false;
                const config: Configuration = Platform.config();
                for (let i = 0; i < symbol.countryCodes.length; i++) {
                    const countryConfig: CountryConfig = config.countries[symbol.countryCodes[i]];
                    if (filter.continents.indexOf(countryConfig.c as Continent) > -1) {
                        isPass = true;
                        break;
                    }
                }
            }
        }
        return isPass;
    }

    public static filterTrade = (filter: FilterDateState, trade: Trade): Trade => {
        if (Utils.isArrayNotEmpty(trade.trades)) {
            const filteredTrades: Trade[] = trade.trades.filter((t: Trade) => FilterUtil.filterCreateDate(filter, t.Date));
            if (Utils.isArrayNotEmpty(filteredTrades)) {
                if (filteredTrades.length > 1) {
                    if (filteredTrades.length === trade.trades.length) {
                        return trade;
                    } else {
                        return TradeUtil.recalculateExposure(trade, filteredTrades);
                    }
                } else {
                    return filteredTrades[0];
                }
            }
        } else {
            return FilterUtil.filterCreateDate(filter, trade.Date) ? trade : null;
        }
        return null;
    }

    public static filterDeal = (filter: FilterDateState, deal: Deal): Deal => {
        return FilterUtil.filterCreateDate(filter, deal.Date) ? deal : null;
    }

    private static filterCreateDate = (filter: FilterDateState, createDate: number): boolean => {
        if (filter && createDate) {
            switch (filter.dateType) {
                case FilterDateType.Today:
                    return isToday(new Date(createDate));
                case FilterDateType.ThisWeek:
                    return isThisWeek(new Date(createDate));
                case FilterDateType.ThisMonth:
                    return isThisMonth(new Date(createDate));
                case FilterDateType.ThisYear:
                    return isThisYear(new Date(createDate));
                case FilterDateType.Range:
                    if (filter.from && filter.to && (filter.from < filter.to)) {
                        return isWithinInterval(
                            new Date(createDate),
                            { start: filter.from.toDate(), end: filter.to.toDate() }
                        );
                    }
            }
            return true;
        }
        return false;
    }

    public static getDateRange(dateType: FilterDateType, from: moment.Moment, to: moment.Moment): {from: Date, to: Date} {
        let To: Date = new Date();
        let From: Date = To;
        if (dateType) {
            switch (dateType) {
                case FilterDateType.Today:
                    From = startOfDay(To);
                    break;
                case FilterDateType.ThisWeek:
                    From = startOfWeek(To);
                    break;
                case FilterDateType.ThisMonth:
                    From = startOfMonth(To);
                    break;
                case FilterDateType.ThisYear:
                    From = startOfYear(To);
                    break;
                case FilterDateType.Tomorrow:
                    From = new Date();
                    To = addDays(new Date(), 1);
                    break;
                case FilterDateType.NextWeek:
                    From = new Date();
                    To = addWeeks(To, 1);
                    break;
                case FilterDateType.Range:
                    if (from && to) {
                        From = from.startOf("day").toDate();
                        To = to.endOf("day").toDate();
                    }
                    break;
            }
        }
        return {
            from: From,
            to: To
        };
    }

    public static nextClosedTradesDateType(dateType: FilterDateType): FilterDateType {
        if (dateType === FilterDateType.Today) {
            return FilterDateType.ThisWeek
        } else if (dateType === FilterDateType.ThisWeek) {
            return FilterDateType.ThisMonth
        } else if (dateType === FilterDateType.ThisMonth) {
            return FilterDateType.ThisYear
        }
        return null;
    }

    public static filterSymbol = (filter: FilterSymbolState, symbol: TradeSymbol, search?: string): TradeSymbol => {
        const isPass: boolean = FilterUtil.filterByState(filter, symbol) &&
            (FilterUtil.filterSearch(symbol.symbol, search) || FilterUtil.filterSearch(symbol.text, search) || FilterUtil.filterSearch(symbol.tradingCentralRICCode, search));
        return isPass ? symbol : null;
    }

    public static filterFollowedAccount = (search: string, account: PartnerAccount) => {
        const isPass: boolean = (account.FollowedAccountId && FilterUtil.filterSearch(account.FollowedAccountId.toString(), search))
            || FilterUtil.filterSearch(account.ShareInformationTokenId, search)
        return isPass ? account : null;
    }

    public static mergeContinents = (continent: Continent, continents: Continent[] = []): Continent[] => {
        const newContinents: Continent[] = [...continents];
        const index: number = newContinents.indexOf(continent);
        if (index >= 0) {
            newContinents.splice(index, 1);
        } else {
            newContinents.push(continent);
        }
        return newContinents;
    }

    public static getOrderRateStatus = (orderStatus: EntryOrderStatus): EntryOrderStatus[] => {
        if (orderStatus) {
            switch (orderStatus) {
                case EntryOrderStatus.Executed:
                    return [EntryOrderStatus.Executed];
                case EntryOrderStatus.Cancelled:
                    return [EntryOrderStatus.Cancelled, EntryOrderStatus.Rejected];
            }
        }

        return null;
    }
}
