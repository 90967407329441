export enum TutorialIDs {
    howToOpenTradeStep1 = "oT1",
    howToOpenTradeStep2 = "oT2",
    howToOpenTradeStep3 = "oT3",
    howToOpenTradeStep4 = "oT4",
    howToOpenTradeStep5 = "oT5",
    howToOpenTradeStep6 = "oT6",

    howToCloseTradeStep1 = "cT1",
    howToCloseTradeStep2 = "cT2",
    howToCloseTradeStep3 = "cT3",
    howToCloseTradeStep4 = "cT4",
    howToCloseTradeStep5 = "cT5",
    howToCloseTradeStep6 = "cT6",

    howToAutomateTradeStep0 = "aT0",
    howToAutomateTradeStep1 = "aT1",
    howToAutomateTradeStep2 = "aT2",
    howToAutomateTradeStep3 = "aT3",
    howToAutomateTradeStep4 = "aT4",
    howToAutomateTradeStep5 = "aT5",

    howToUseTradeButtonStep0 = "uTBtn0",
    howToUseTradeButtonStep1 = "uTBtn1",
    howToUseTradeButtonStep2 = "uTBtn2",
    howToUseTradeButtonStep3 = "uTBtn3",
    howToUseTradeButtonStep4 = "uTBtn4",
    howToUseTradeButtonStep5 = "uTBtn5",

    howToUseStopLossAndTakeProfitStep1 = "slTp1",
    howToUseStopLossAndTakeProfitStep2 = "slTp2",
    howToUseStopLossAndTakeProfitStep3 = "slTp3",
    howToUseStopLossAndTakeProfitStep4 = "slTp4",
    howToUseStopLossAndTakeProfitStep5 = "slTp5",
    howToUseStopLossAndTakeProfitStep6 = "slTp6",
    howToUseStopLossAndTakeProfitStep7 = "slTp7",
}
