import {ActionCreatorDefinition, createAction} from "redux-ts";
import {SettingsTab} from "enum/SettingsTab";
import {AccountStatement, Transaction} from "core/redux/settings/SettingsReduxState";
import {StorageKey} from "enum/StorageKey";
import {Preference} from "core/util/Preference";
import {Leverage} from "protocol/config/GetChangeLeverageResponse";
import {CloseOutMethod} from "protocol/config/GetChangeCloseOutMethodsResponse";
import {UserProfileInfo} from "protocol/account/GetUserProfileResponse";
import {VaultDetails} from "protocol/promo/GetVaultDetailsResponse";
import {PendingTransactionToSign} from "protocol/account/GetPendingTransactionsToSignResponse";
import {LSKey} from "platform/storage/Storage";
import {LDDocument} from "core/props/LDPlatformProps";
import {TwoFactorAuthenticationMethodType} from "enum/TwoFactorAuthenticationMethodType";
import {BooleanPayload, NumberPayload} from "core/redux/StoreActions";
import {LangCode} from "platform/enum/LangCode";
import {AccountTierSettingInfo} from "protocol/config/GetUIConfigurationResponse";

export const UpdatePlatformLanguageType: string = "UpdatePlatformLanguage";
export const SetLocalizationDataType: string = "SetLocalizationData";
export const FetchSettingsConfigType: string = "FetchSettingsConfig";
export const SetPreferencesFetchedType: string =  "SetPreferencesFetched";
export const InsertClientPreferenceType: string =  "InsertClientPreference";
export const InsertClientPreferenceLSType: string =  "InsertClientPreferenceLS";
export const SetClientPreferenceType: string =  "SetClientPreference";
export const SetSettingsTabType: string = "SetSettingsTab";
export const SetTransactionsType: string = "SetTransactions";
export const SetAccountStatementsType: string = "SetAccountStatements";
export const SetUIConfigurationType: string = "SetUIConfiguration";
export const SubmitAccountAnnualReportType: string = "SubmitAccountAnnualReport";
export const SetAvailableLeveragesType: string = "SetAvailableLeverages";
export const SubmitLeverageDisclaimerType: string = "SubmitLeverageDisclaimer";
export const DoChangeLeverageType: string = "DoChangeLeverage";
export const SetCurrentLeverageType: string = "SetCurrentLeverage";
export const SetAvailableCloseOutMethodsType: string = "SetAvailableCloseOutMethods";
export const DoChangeCloseOutMethodType: string = "DoChangeCloseOutMethod";
export const SetCurrentCloseOutMethodType: string = "SetCurrentCloseOutMethod";
export const FetchDepositLimitsType: string = "FetchDepositLimits";
export const FetchUserProfileType: string = "FetchUserProfile";
export const SetUserProfileInfoType: string = "SetUserProfileInfo";
export const SetDepositLimitsInfoType: string = "SetDepositLimitsInfo";
export const FetchVaultDetailsType: string = "FetchVaultDetails";
export const SetVaultDetailsType: string = "SetVaultDetails";
export const FetchIsRollingContractsEnabledType: string = "FetchIsRollingContractsEnabled";
export const SetIsRollingContractsEnabledType: string = "SetIsRollingContractsEnabled";
export const SetLegalDataDocumentsType: string = "SetLegalDataDocuments";
export const SetCallMeSubmittedType: string = "SetCallMeSubmitted";
export const AcceptDisclaimerForExternalProvidersType: string = "AcceptDisclaimerForExternalProviders";
export const SetDisclaimerStatusReceivedForExternalProvidersType: string = "SetDisclaimerStatusReceivedForExternalProviders";
export const SetDisclaimerAcceptedForExternalProvidersType: string = "SetDisclaimerAcceptedForExternalProviders";
export const SetEnabledSkipEducationTradingGroupType: string = "SetEnabledSkipEducationTradingGroup";
export const FetchCanSkipEducationTradingGroupType: string = "FetchCanSkipEducationTradingGroup";
export const SetCanSkipEducationTradingGroupType: string = "SetCanSkipEducationTradingGroup";
export const DoSkipEducationTradingGroupType: string = "DoSkipEducationTradingGroup";
export const SetUserAvailableForPushNotificationsType: string = "SetUserAvailableForPushNotifications";
export const SetTwoFactorAuthenticationEnabledType: string = "SetTwoFactorAuthenticationEnabled";
export const SetTwoFactorAuthenticationMethodType: string = "SetTwoFactorAuthenticationMethod";
export const NotifyNotAvailableForPushNotificationsType: string = "NotifyNotAvailableForPushNotifications";
export const RegisterPushTokenType: string = "RegisterPushToken";
export const SetEMTEnabledType: string = "SetEMTEnabled";
export const FetchEMTPercentagesType: string = "FetchEMTPercentages";
export const SetEMTPercentagesType: string = "SetEMTPercentages";
export const SubmitEMTPercentageType: string = "SubmitEMTPercentage";
export const SetDeclarationRecordedType: string = "SetDeclarationRecorded";
export const FetchTransactionsToSignType: string = "FetchTransactionsToSign";
export const SetTransactionsToSignType: string = "SetTransactionsToSign";
export const DoSignTransactionsType: string = "DoSignTransactions";
export const SetTelegramUrlType: string = "SetTelegramUrl";

export interface UpdatePlatformLanguagePayload {

    langCode: LangCode;
}

export interface SetLocalizationDataPayload {

    localization: {[key: string]: string};
}

export interface InsertClientPreferencePayload<T> {

    key: StorageKey | LSKey;
    preference: Preference<T>;
}

export interface InsertClientPreferenceLSPayload<T> {

    key: StorageKey | LSKey;
    preference: Preference<T>;
}

export interface SetClientPreferencePayload<T> {

    key: StorageKey | LSKey;
    preference: Preference<T>;
}

export interface SetSettingsTabPayload {

    tab: SettingsTab;
}

export interface SetTransactionsPayload {

    transactions: Transaction[];
}

export interface SetAccountStatementsPayload {

    statements: AccountStatement[];
}

export interface SetUIConfigurationPayload {

    AnnualStatementYears: number[];
    ShowShareInformation: boolean;
    ShowSwitchToInvest: boolean;
    CallMeEnabled: boolean;
    UploadedDocumentsSendToEmail: string;
    AccountTierSetting: AccountTierSettingInfo
}

export interface SubmitAccountAnnualReportPayload {

    year: number;
}

export interface SetAvailableLeveragesPayload {

    isAllowed: boolean;
    leverages: Leverage[];
    leverage: Leverage;
    shouldAcceptLeverageDisclaimer: boolean;
}

export interface SetAvailableCloseOutMethodsPayload {

    isAllowed: boolean;
    methods: CloseOutMethod[];
    currentMethod: CloseOutMethod;
}

export interface DoChangeLeveragePayload {

    leverageId: number;
    action?: () => void;
}

export interface SetCurrentLeveragePayload {

    leverage: Leverage;
}

export interface DoChangeCloseOutMethodPayload {

    methodId: number;
    action?: () => void;
}

export interface SetCurrentCloseOutMethodPayload {

    currentMethod: CloseOutMethod;
}

export interface SetUserProfileInfoPayload {

    userProfile: UserProfileInfo;
}

export interface SetDepositLimitsInfoPayload {

    accountLimit: number;
    leftTopUp: number;
}

export interface SetVaultDetailsPayload {

    vault: VaultDetails;
}

export interface SetIsRollingContractsEnabledPayload {

    enabled: boolean;
    expiration: string;
}

export interface SetLegalDataDocumentsPayload {

    documents: LDDocument[];
}

export interface AcceptDisclaimerForExternalProvidersPayload {

    action?: () => void;
}

export interface SetTwoFactorAuthenticationMethodPayload {

    method: TwoFactorAuthenticationMethodType;
}

export interface RegisterPushTokenPayload {

    packageName: string;
    platformType: string;
    pushNotificationToken: string;
    apnsToken?: string;
}

export interface SubmitLeverageDisclaimerPayload {

    action?: () => void
}

export interface SetEMTPercentagesPayload {

    values: number[];
}

export interface TransactionsToSignPayload {

    transactions: PendingTransactionToSign[];
    signature?: string;
    action?: () => void;
}


export interface SetTelegramUrlPayload {

    telegramInitUrl: string;
}

export const UpdatePlatformLanguage: ActionCreatorDefinition<UpdatePlatformLanguagePayload, any> = createAction<UpdatePlatformLanguagePayload>(UpdatePlatformLanguageType);
export const SetLocalizationData: ActionCreatorDefinition<SetLocalizationDataPayload, any> = createAction<SetLocalizationDataPayload>(SetLocalizationDataType);
export const FetchSettingsConfig: ActionCreatorDefinition<any, any> = createAction<any>(FetchSettingsConfigType);
export const SetPreferencesFetched: ActionCreatorDefinition<any, any> = createAction<any>(SetPreferencesFetchedType);
export const InsertClientPreference: ActionCreatorDefinition<InsertClientPreferencePayload<any>, any> = createAction<InsertClientPreferencePayload<any>>(InsertClientPreferenceType);
export const InsertClientPreferenceLS: ActionCreatorDefinition<InsertClientPreferenceLSPayload<any>, any> = createAction<InsertClientPreferenceLSPayload<any>>(InsertClientPreferenceLSType);
export const SetClientPreference: ActionCreatorDefinition<SetClientPreferencePayload<any>, any> = createAction<SetClientPreferencePayload<any>>(SetClientPreferenceType);
export const SetSettingsTab: ActionCreatorDefinition<SetSettingsTabPayload, any> = createAction<SetSettingsTabPayload>(SetSettingsTabType);
export const SetTransactions: ActionCreatorDefinition<SetTransactionsPayload, any> = createAction<SetTransactionsPayload>(SetTransactionsType);
export const SetAccountStatements: ActionCreatorDefinition<SetAccountStatementsPayload, any> = createAction<SetAccountStatementsPayload>(SetAccountStatementsType);
export const SetUIConfiguration: ActionCreatorDefinition<SetUIConfigurationPayload, any> = createAction<SetUIConfigurationPayload>(SetUIConfigurationType);
export const SubmitAccountAnnualReport: ActionCreatorDefinition<SubmitAccountAnnualReportPayload, any> = createAction<SubmitAccountAnnualReportPayload>(SubmitAccountAnnualReportType);
export const SetAvailableLeverages: ActionCreatorDefinition<SetAvailableLeveragesPayload, any> = createAction<SetAvailableLeveragesPayload>(SetAvailableLeveragesType);
export const SetAvailableCloseOutMethods: ActionCreatorDefinition<SetAvailableCloseOutMethodsPayload, any> = createAction<SetAvailableCloseOutMethodsPayload>(SetAvailableCloseOutMethodsType);
export const SubmitLeverageDisclaimer: ActionCreatorDefinition<SubmitLeverageDisclaimerPayload, any> = createAction<SubmitLeverageDisclaimerPayload>(SubmitLeverageDisclaimerType);
export const DoChangeLeverage: ActionCreatorDefinition<DoChangeLeveragePayload, any> = createAction<DoChangeLeveragePayload>(DoChangeLeverageType);
export const SetCurrentLeverage: ActionCreatorDefinition<SetCurrentLeveragePayload, any> = createAction<SetCurrentLeveragePayload>(SetCurrentLeverageType);
export const DoChangeCloseOutMethod: ActionCreatorDefinition<DoChangeCloseOutMethodPayload, any> = createAction<DoChangeCloseOutMethodPayload>(DoChangeCloseOutMethodType);
export const SetCurrentCloseOutMethod: ActionCreatorDefinition<SetCurrentCloseOutMethodPayload, any> = createAction<SetCurrentCloseOutMethodPayload>(SetCurrentCloseOutMethodType);
export const FetchDepositLimits: ActionCreatorDefinition<any, any> = createAction<any>(FetchDepositLimitsType);
export const FetchUserProfile: ActionCreatorDefinition<any, any> = createAction<any>(FetchUserProfileType);
export const SetUserProfileInfo: ActionCreatorDefinition<SetUserProfileInfoPayload, any> = createAction<SetUserProfileInfoPayload>(SetUserProfileInfoType);
export const SetDepositLimitsInfo: ActionCreatorDefinition<SetDepositLimitsInfoPayload, any> = createAction<SetDepositLimitsInfoPayload>(SetDepositLimitsInfoType);
export const FetchVaultDetails: ActionCreatorDefinition<any, any> = createAction<any>(FetchVaultDetailsType);
export const SetVaultDetails: ActionCreatorDefinition<SetVaultDetailsPayload, any> = createAction<SetVaultDetailsPayload>(SetVaultDetailsType);
export const FetchIsRollingContractsEnabled: ActionCreatorDefinition<any, any> = createAction<any>(FetchIsRollingContractsEnabledType);
export const SetIsRollingContractsEnabled: ActionCreatorDefinition<SetIsRollingContractsEnabledPayload, any> = createAction<SetIsRollingContractsEnabledPayload>(SetIsRollingContractsEnabledType);
export const SetLegalDataDocuments: ActionCreatorDefinition<SetLegalDataDocumentsPayload, any> = createAction<SetLegalDataDocumentsPayload>(SetLegalDataDocumentsType);
export const SetCallMeSubmitted: ActionCreatorDefinition<any, any> = createAction<any>(SetCallMeSubmittedType);
export const AcceptDisclaimerForExternalProviders: ActionCreatorDefinition<AcceptDisclaimerForExternalProvidersPayload, any> = createAction<AcceptDisclaimerForExternalProvidersPayload>(AcceptDisclaimerForExternalProvidersType);
export const SetDisclaimerStatusReceivedForExternalProviders: ActionCreatorDefinition<any, any> = createAction<any>(SetDisclaimerStatusReceivedForExternalProvidersType);
export const SetDisclaimerAcceptedForExternalProviders: ActionCreatorDefinition<any, any> = createAction<any>(SetDisclaimerAcceptedForExternalProvidersType);
export const SetEnabledSkipEducationTradingGroup: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetEnabledSkipEducationTradingGroupType);
export const FetchCanSkipEducationTradingGroup: ActionCreatorDefinition<any, any> = createAction<any>(FetchCanSkipEducationTradingGroupType);
export const SetCanSkipEducationTradingGroup: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetCanSkipEducationTradingGroupType);
export const DoSkipEducationTradingGroup: ActionCreatorDefinition<any, any> = createAction<any>(DoSkipEducationTradingGroupType);
export const SetUserAvailableForPushNotifications: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetUserAvailableForPushNotificationsType);
export const SetTwoFactorAuthenticationEnabled: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetTwoFactorAuthenticationEnabledType);
export const SetTwoFactorAuthenticationMethod: ActionCreatorDefinition<SetTwoFactorAuthenticationMethodPayload, any> = createAction<SetTwoFactorAuthenticationMethodPayload>(SetTwoFactorAuthenticationMethodType);
export const NotifyNotAvailableForPushNotifications: ActionCreatorDefinition<any, any> = createAction<any>(NotifyNotAvailableForPushNotificationsType);
export const RegisterPushToken: ActionCreatorDefinition<RegisterPushTokenPayload, any> = createAction<RegisterPushTokenPayload>(RegisterPushTokenType);
export const SetEMTEnabled: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetEMTEnabledType);
export const FetchEMTPercentages: ActionCreatorDefinition<any, any> = createAction<any>(FetchEMTPercentagesType);
export const SetEMTPercentages: ActionCreatorDefinition<SetEMTPercentagesPayload, any> = createAction<SetEMTPercentagesPayload>(SetEMTPercentagesType);
export const SubmitEMTPercentage: ActionCreatorDefinition<NumberPayload, any> = createAction<NumberPayload>(SubmitEMTPercentageType);
export const SetDeclarationRecorded: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetDeclarationRecordedType);
export const FetchTransactionsToSign: ActionCreatorDefinition<any, any> = createAction<any>(FetchTransactionsToSignType);
export const SetTransactionsToSign: ActionCreatorDefinition<TransactionsToSignPayload, any> = createAction<TransactionsToSignPayload>(SetTransactionsToSignType);
export const DoSignTransactions: ActionCreatorDefinition<TransactionsToSignPayload, any> = createAction<TransactionsToSignPayload>(DoSignTransactionsType);
export const SetTelegramUrl: ActionCreatorDefinition<SetTelegramUrlPayload, any> = createAction<SetTelegramUrlPayload>(SetTelegramUrlType);
