export class EduTradingState {

    private static _instance: EduTradingState;
    private AccountSwitchedPostActions: {[key: number]: () => void} = {};

    private constructor() {}

    public static instance(): EduTradingState {
        return this._instance || (this._instance = new this());
    }

    public setAccountSwitchedPostAction(accountId: number, action: () => void): void {
        this.AccountSwitchedPostActions[accountId] = action;
    }

    public runAccountSwitchedPostAction(accountId: number): void {
        const action: () => void = this.AccountSwitchedPostActions[accountId];
        if (action) {
            delete this.AccountSwitchedPostActions[accountId];
            action();
        }
    }
}
