import React, {useState, useContext, useEffect, useCallback} from 'react'
import {Tour} from './Tour'
import {ProviderProps, StepType, TourProps, Tutorial} from "./types";

const defaultState = {
  tutorial: {} as Tutorial,
  isOpen: false,
  setIsOpen: () => false,
  currentStep: 0,
  setCurrentStep: () => 0,
  steps: [],
  setSteps: () => [],
  setMeta: () => '',
  disabledActions: false,
  setDisabledActions: () => false,
  components: {},
  rtl: false,
  setCurrentStepById: ()=>{},
  tutorialStarted: false,
  setTutorial: (tutorial: Tutorial) => ({}),
  setTutorialStarted: () => false,
  cancelTutorial: () => {},
  setRTL: (rtl: boolean) => {},
}

const TourContext = React.createContext<TourProps>(defaultState);

const TourProvider: React.FC<ProviderProps> = ({
  tutorial: defaultTutorial,
  children,
  defaulttutorialStarted = false,
  defaultOpen = false,
  startAt = 0,
  steps: defaultSteps,
  setCurrentStep: customSetCurrentStep,
  currentStep: customCurrentStep,
  rtl: defaultRTL = false,
  ...props
}) => {
  const [tutorial, setCurrentTutorial] = useState<Tutorial>(defaultTutorial);
  const [tutorialStarted, setTutorialStarted] = useState<boolean>(defaulttutorialStarted);
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [currentStep, setCurrentStep] = useState(startAt);
  const [steps, setSteps] = useState(defaultSteps);
  const [meta, setMeta] = useState('');
  const [disabledActions, setDisabledActions] = useState(false);
  const [currentStepId, setCurrentStepId] =useState<string>();
  const [rtl, setRTL] = useState<boolean>(defaultRTL);

  useEffect(()=>{
    const step = steps[currentStep];
    setCurrentStepId(step?.id);
  },[steps, currentStep]);

  const setCurrentStepById = useCallback((id: string) => {
    steps.forEach((step: StepType, i) => {
      if (step.id === id) {
        setCurrentStep(i);
      }
    });
  },[steps]);

  const goNextStep = useCallback(()=>{
    setCurrentStep(currentStep + 1);
  },[steps, currentStep]);

  const cancelTutorial = () => {
    if (currentStep === steps.length - 1) {
      tutorial.onFinish && tutorial.onFinish(tutorial);
    }
    setTutorialStarted(false);
    setIsOpen(false);
    setCurrentStep(0);
  };

  const setTutorial = (tutorial: Tutorial) => {
    setCurrentTutorial(tutorial);
    setSteps(tutorial.steps);
    setCurrentStep(0);
  };

  const value = {
    tutorial,
    setTutorial,
    isOpen,
    setIsOpen,
    currentStep: customCurrentStep || currentStep,
    setCurrentStep:
      customSetCurrentStep && typeof customSetCurrentStep === 'function'
        ? customSetCurrentStep
        : setCurrentStep,
    steps,
    setSteps,
    disabledActions,
    setDisabledActions,
    meta,
    setMeta,
    currentStepId,
    setCurrentStepById,
    goNextStep,
    tutorialStarted,
    setTutorialStarted,
    cancelTutorial,
    rtl,
    setRTL,
    ...props,
  }

  return (
    <TourContext.Provider value={value}>
      {children}
      {isOpen ? <Tour {...value} /> : null}
    </TourContext.Provider>
  )
}

export { TourContext, TourProvider }

export function useTour() {
  return useContext(TourContext)
}
