import {ActionCreatorDefinition, createAction} from "redux-ts";
import TradingModalType from "enum/TradingModalType";
import {Banner, BarMetrics, Tooltip, TradingModalInfo} from "core/redux/app/AppReduxState";
import {DynamicConfiguration} from "platform/protocol/common/DynamicConfiguration";
import ToolsMenuType from "enum/ToolsMenuType";
import {TSMap} from "typescript-map";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import {UrlType} from "platform/enum/UrlType";
import Parameter from "platform/util/Parameter";
import {RouteType} from "core/router/Routes";
import {
    LDAcademyVideo,
    LDAcademyVideoGroup,
    LDTutorial,
    LDPlatformProps
} from "core/props/LDPlatformProps";
import {BooleanPayload, StringPayload} from "core/redux/StoreActions";
import {EnvironmentConfiguration} from "protocol/config/LoginConfigurationResponse";

export const SetDynamicConfigurationType: string =  "SetDynamicConfiguration";
export const BrandConfigurationChangeType: string =  "BrandConfigurationChange";
export const SetIsMultiBrandAppType: string =  "SetIsMultiBrandApp";
export const SetIsDepositOutsideAppType: string =  "SetIsDepositOutsideApp";
export const SetLDPlatformPropsType: string =  "SetLDPlatformProps";
export const SetMenuRouteAllowedType: string =  "SetMenuRouteAllowed";
export const SetMenuToolAllowedType: string =  "SetMenuToolAllowed";
export const DoGetURLType: string =  "DoGetURL";
export const DoFetchPlatformPropsType: string =  "DoFetchPlatformProps";
export const SetConfiguredCountriesType: string =  "SetConfiguredCountries";
export const SetTradingModalType: string =  "SetTradingModal";
export const ToggleMenuType: string = "ToggleMenu";
export const SetBannerType: string =  "SetBanner";
export const RemoveBannerType: string =  "RemoveBanner";
export const SetBannerMetricsType: string =  "SetBannerMetrics";
export const SetBarTopMetricsType: string =  "SetBarTopMetrics";
export const SetBarBottomMetricsType: string =  "SetBarBottomMetrics";
export const SetModalIconCloseMetricsType: string =  "SetModalIconCloseMetricsMetrics";
export const SetModalHeaderMetricsType: string =  "SetModalHeaderMetrics";
export const SetModalTabsMetricsType: string =  "SetModalTabsMetrics";
export const SetOpenSupportWidgetType: string =  "SetOpenSupportWidget";
export const SetShowAccountDetailsModalType: string = "SetShowAccountDetailsModal";
export const SetTooltipType: string =  "SetTooltip";
export const ToggleAccountDetailsSwiperType: string = "ToggleAccountDetailsSwiper";
export const SetPartnerAccountMetricsType: string = "SetPartnerAccountMetrics";
export const SetNotificationPermissionSuggestionType: string = "SetNotificationPermissionSuggestion";
export const SetNativeUseDomainType: string = "SetNativeUseDomain";

export interface SetDynamicConfigurationPayload {

    configuration: DynamicConfiguration;
    environmentConfigurations: EnvironmentConfiguration[];
}

export interface FetchLDPlatformPropsPayload {

    Url?: string;
}

export interface SetLDPlatformPropsPayload {

    props: LDPlatformProps;
    tutorials: LDTutorial[];
    academyVideoGroups: LDAcademyVideoGroup[];
    academyVideos: TSMap<number, LDAcademyVideo[]>;
}

export interface SetMenuRouteAllowedPayload {

    routeType: RouteType;
    allowed: boolean;
}

export interface SetMenuToolAllowedPayload {

    toolType: ToolsMenuType;
    allowed: boolean;
}

export interface SetShowAccountDetailsModalPayload {

    show: boolean;
}

export interface DoGetURLPayload {

    urlType: UrlType;
    parameters?: Parameter[];
}

export interface SetConfiguredCountriesPayload {

    countries: TSMap<number, CountryInfo>;
    namePerCountry: TSMap<string, CountryInfo>;
}

export interface SetTradingModalPayload<T> {

    tradingModalType: TradingModalType;
    info: TradingModalInfo<T>;
}

export interface ToggleMenuPayload {

    collapse: boolean;
}

export interface BannerPayload {

    banner: Banner<any>;
}

export interface RemoveBannerPayload {

    banner: Banner<any>;
    block: boolean;
}

export interface SetBarMetricsPayload {

    metrics: BarMetrics;
}

export interface SetModalIconCloseMetricsPayload {

    metrics: BarMetrics;
}

export interface SetModalHeaderMetricsPayload {

    metrics: BarMetrics;
}

export interface SetModalTabsMetricsPayload {

    metrics: BarMetrics;
}

export interface SetOpenSupportWidgetPayload {

    visible: boolean;
}

export interface SetTooltipPayload {

    tooltip: Tooltip;
}

export const SetDynamicConfiguration: ActionCreatorDefinition<SetDynamicConfigurationPayload, any> = createAction<SetDynamicConfigurationPayload>(SetDynamicConfigurationType);
export const BrandConfigurationChange: ActionCreatorDefinition<any, any> = createAction<any>(BrandConfigurationChangeType);
export const SetIsMultiBrandApp: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetIsMultiBrandAppType);
export const SetIsDepositOutsideApp: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetIsDepositOutsideAppType);
export const SetLDPlatformProps: ActionCreatorDefinition<SetLDPlatformPropsPayload, any> = createAction<SetLDPlatformPropsPayload>(SetLDPlatformPropsType);
export const SetMenuRouteAllowed: ActionCreatorDefinition<SetMenuRouteAllowedPayload, any> = createAction<SetMenuRouteAllowedPayload>(SetMenuRouteAllowedType);
export const SetMenuToolAllowed: ActionCreatorDefinition<SetMenuToolAllowedPayload, any> = createAction<SetMenuToolAllowedPayload>(SetMenuToolAllowedType);
export const DoGetURL: ActionCreatorDefinition<DoGetURLPayload, any> = createAction<DoGetURLPayload>(DoGetURLType);
export const DoFetchPlatformProps: ActionCreatorDefinition<FetchLDPlatformPropsPayload, any> = createAction<FetchLDPlatformPropsPayload>(DoFetchPlatformPropsType);
export const SetConfiguredCountries: ActionCreatorDefinition<SetConfiguredCountriesPayload, any> = createAction<SetConfiguredCountriesPayload>(SetConfiguredCountriesType);
export const SetTradingModal: ActionCreatorDefinition<SetTradingModalPayload<any>, any> = createAction<SetTradingModalPayload<any>>(SetTradingModalType);
export const ToggleMenu: ActionCreatorDefinition<ToggleMenuPayload, any> = createAction<ToggleMenuPayload>(ToggleMenuType);
export const SetBanner: ActionCreatorDefinition<BannerPayload, any> = createAction<BannerPayload>(SetBannerType);
export const RemoveBanner: ActionCreatorDefinition<RemoveBannerPayload, any> = createAction<RemoveBannerPayload>(RemoveBannerType);
export const SetBannerMetrics: ActionCreatorDefinition<SetBarMetricsPayload, any> = createAction<SetBarMetricsPayload>(SetBannerMetricsType);
export const SetBarTopMetrics: ActionCreatorDefinition<SetBarMetricsPayload, any> = createAction<SetBarMetricsPayload>(SetBarTopMetricsType);
export const SetBarBottomMetrics: ActionCreatorDefinition<SetBarMetricsPayload, any> = createAction<SetBarMetricsPayload>(SetBarBottomMetricsType);
export const SetModalIconCloseMetrics: ActionCreatorDefinition<SetModalIconCloseMetricsPayload, any> = createAction<SetModalIconCloseMetricsPayload>(SetModalIconCloseMetricsType);
export const SetModalHeaderMetrics: ActionCreatorDefinition<SetModalHeaderMetricsPayload, any> = createAction<SetModalHeaderMetricsPayload>(SetModalHeaderMetricsType);
export const SetModalTabsMetrics: ActionCreatorDefinition<SetModalTabsMetricsPayload, any> = createAction<SetModalTabsMetricsPayload>(SetModalTabsMetricsType);
export const SetOpenSupportWidget: ActionCreatorDefinition<SetOpenSupportWidgetPayload, any> = createAction<SetOpenSupportWidgetPayload>(SetOpenSupportWidgetType);
export const SetShowAccountDetailsModal: ActionCreatorDefinition<SetShowAccountDetailsModalPayload, any> = createAction<SetShowAccountDetailsModalPayload>(SetShowAccountDetailsModalType);
export const SetTooltip: ActionCreatorDefinition<SetTooltipPayload, any> = createAction<SetTooltipPayload>(SetTooltipType);
export const ToggleAccountDetailsSwiper: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(ToggleAccountDetailsSwiperType);
export const SetPartnerAccountMetrics: ActionCreatorDefinition<SetBarMetricsPayload, any> = createAction<SetBarMetricsPayload>(SetPartnerAccountMetricsType);
export const SetNotificationPermissionSuggestion: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetNotificationPermissionSuggestionType);
export const SetNativeUseDomain: ActionCreatorDefinition<StringPayload, any> = createAction<StringPayload>(SetNativeUseDomainType);
