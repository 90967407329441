import {Engine} from "platform/engine/Engine";
import {Http, HttpReject} from "platform/network/http/Http";
import Utils from "platform/util/Utils";
import {
    EduTradingAcademyVideo,
    EduTradingAcademyVideos,
    EduTradingAcademyVideosResponse
} from "protocol/edutrading/EduTradingAcademyVideosResponse";
import {LangCode} from "platform/enum/LangCode";
import {LanguageUtil} from "platform/util/LanguageUtil";
import Platform from "platform/Platform";
import {
    EduTradingFetchAcademyVideosPayload,
    EduTradingFetchArticlesPayload,
    EduTradingFetchCryptoCalendarEventsPayload,
    EduTradingFetchEconomicCalendarEventsPayload,
    EduTradingFetchMarketVideosPayload,
    EduTradingFetchSignalsPayload,
    EduTradingSetAcademyPlaylistProgress,
    EduTradingSetAcademyVideos,
    EduTradingSetArticles,
    EduTradingSetCryptoCalendarEvents,
    EduTradingSetCryptoCalendarFilters,
    EduTradingSetCurrencyStrengthMeter,
    EduTradingSetEBooks, EduTradingSetEconomicCalendarEvents, EduTradingSetEconomicCalendarFilters, EduTradingSetGlossary, EduTradingSetLoading, EduTradingSetMarketSearchVideos, EduTradingSetSignals, EduTradingSetSignalsFilters, EduTradingSetTrendAnalisys
} from "core/redux/eduTrading/EduTradingReduxActions";
import {EduTradingEBook, EduTradingEBooksResponse} from "protocol/edutrading/EduTradingEBooksResponse";
import {EduTradingArticle, EduTradingArticlesResponse} from "protocol/edutrading/EduTradingArticlesResponse";
import {EduTradingEconomicCalendarEventsResponse} from "protocol/edutrading/EduTradingEconomicCalendarEventsResponse";
import {EduTradingSignal, EduTradingSignalsResponse} from "protocol/edutrading/EduTradingSignalsResponse";
import {TSMap} from "typescript-map";
import {EduCalendarFilterType} from "enum/EduCalendarFilterType";
import {EduFilterValue, PlaylistInfo} from "core/redux/eduTrading/EduTradingReduxState";
import DateTimeFormat from "core/format/DateTimeFormat";
import {EduTradingSignalsPeriod} from "protocol/edutrading/EduTradingSignalsPeriod";
import {StorageKey} from "enum/StorageKey";
import {
    EduTradingCryptoCalendarEvent,
    EduTradingCryptoCalendarEventsResponse
} from "protocol/edutrading/EduTradingCryptoCalendarEventsResponse";
import {
    EduTradingMarketVideo,
    EduTradingMarketVideosResponse
} from "protocol/edutrading/EduTradingMarketVideosResponse";
import {EduTradingCurrencyStrengthMeterResponse} from "protocol/edutrading/EduTradingCurrencyStrengthMeterResponse";
import {EduTradingGlossaryResponse} from "protocol/edutrading/EduTradingGlossaryResponse";
import {EduTradingTrendAnalysisResponse} from "protocol/edutrading/EduTradingTrendAnalysisResponse";

const EduSupportedLanguages: LangCode[] = [LangCode.EN, LangCode.IT, LangCode.PL, LangCode.SV];
const FilesReplaceDomain = (url: string): string => {
    return url?.replace("https://files.traducationfx.com", "https://files-tfx.tradenetworks.com")
};

export default class EduTradingEngine extends Engine {

    private static _instance: EduTradingEngine;
    private API_URL: string = "https://api-tfx.tradenetworks.com/";
    private API_KEY: string = "thebrainKYZRDSzn2tN";

    public static instance(): EduTradingEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        const data: TSMap<string, PlaylistInfo> = await this.deserializeLS();
        Platform.dispatch(EduTradingSetAcademyPlaylistProgress({data}));
    }

    private deserializeLS = async (): Promise<TSMap<string, PlaylistInfo>> => {
        const playlistInfoMap: TSMap<string, PlaylistInfo> = new TSMap();
        const playlistJson: string = await Platform.storage().getItem(StorageKey.AcademyWatchedVideos);
        if (!Utils.isObjectEmpty(playlistJson)) {
            try {
                const playlistData: object = JSON.parse(playlistJson);
                Object.keys(playlistData).forEach((category: string) => {
                    const playlistInfo: any = playlistData[category];
                    const playlistProgressInfo: PlaylistInfo = {
                        watchedVideos: playlistInfo.watchedVideos,
                        progress: playlistInfo.progress
                    };
                    playlistInfoMap.set(category, playlistProgressInfo);
                });
                return playlistInfoMap;
            } catch (e) {
            }
        }
        return Promise.resolve(playlistInfoMap);
    }

    private query = async <R>(path: string, params?: {[key: string]: string | number}): Promise<R> => {
        let langCode: LangCode = LanguageUtil.languageCode();
        langCode = EduSupportedLanguages.indexOf(langCode) > -1 ? langCode : LangCode.EN;
        let url: string = `${this.API_URL}${path}?apiKey=${this.API_KEY}&langCode=${langCode}`;
        if (!Utils.isObjectEmpty(params)) {
            url += "&" + Object.keys(params).map((key: string) => `${key}=${params[key]}`).join("&");
        }
        const answer: [HttpReject, string] = await Utils.to(Http.get(url));
        if (answer[0]) {
            this._logger.warn(`Failed to call: ${url}`);
        } else {
            try {
                return JSON.parse(answer[1]);
            } catch (e) {
                this._logger.warn(`Failed parse response from: ${url}`);
            }
        }
        return null;
    }

    public DoGetAcademyVideos = async ({playlistId}: EduTradingFetchAcademyVideosPayload): Promise<void> => {
        Platform.dispatch(EduTradingSetLoading({loading: true}));
        const response: EduTradingAcademyVideosResponse = await this.query("video-academy/get-playlist-videos", {
            playlistKey: playlistId
        });
        if (Utils.isArrayNotEmpty(response)) {
            response.forEach((academyVideo: EduTradingAcademyVideos) => {
                academyVideo.videos?.forEach((video: EduTradingAcademyVideo) => {
                    video.videoUrl = FilesReplaceDomain(video.videoUrl);
                    video.thumbnailUrl = FilesReplaceDomain(video.thumbnailUrl);
                });
            });
            Platform.dispatch(EduTradingSetAcademyVideos({
                AcademyVideos: response
            }));
        }
        Platform.dispatch(EduTradingSetLoading({loading: false}));
    }

    public DoGetEBooks = async (): Promise<void> => {
        Platform.dispatch(EduTradingSetLoading({loading: true}));
        const response: EduTradingEBooksResponse = await this.query("books/get-ebooks");
        Platform.dispatch(EduTradingSetLoading({loading: false}));

        if (Utils.isArrayNotEmpty(response)) {
            response.forEach((book: EduTradingEBook) => {
                book.bookUrl = FilesReplaceDomain(book.bookUrl);
                book.placeholderUrl = FilesReplaceDomain(book.placeholderUrl);
            });
            Platform.dispatch(EduTradingSetEBooks({
                Books: response
            }));
        }
    }

    public DoGetArticles = async ({articleType}: EduTradingFetchArticlesPayload): Promise<void> => {
        Platform.dispatch(EduTradingSetLoading({loading: true}));
        const response: EduTradingArticlesResponse = await this.query("articles/get-recent-articles", {
            category: articleType,
            limit: 100,
            // market: "forex" | "crypto"
            // offset: 0
        });
        response?.forEach((article: EduTradingArticle) => {
            article.image = FilesReplaceDomain(article.image);
        });
        Platform.dispatch(EduTradingSetLoading({loading: false}));
        Platform.dispatch(EduTradingSetArticles({
            Articles: Utils.isArrayNotEmpty(response) ? response : [],
            articleType
        }));
    }

    public DoGetEconomicCalendarEvents = async ({Period, dateFilter}: EduTradingFetchEconomicCalendarEventsPayload): Promise<void> => {

        let request;
        if (Utils.isNotNull(dateFilter)) {
            const dateFrom = dateFilter?.from ? DateTimeFormat.dateFormatInput(dateFilter?.from) : null;
            const dateTo = dateFilter?.from ? DateTimeFormat.dateFormatInput(dateFilter?.to) : null;
            request = {
                dateFrom,
                dateTo
            }
        } else {
            request = {dateRange: Period}
        }
        Platform.dispatch(EduTradingSetLoading({loading: true}));
        const response: EduTradingEconomicCalendarEventsResponse = await this.query("economic-calendar/get-events", request);

        const Filters: TSMap<EduCalendarFilterType, EduFilterValue[]> = new TSMap();
        const currencies: EduFilterValue[] = [];
        const impact: EduFilterValue[] = [];
        response?.forEach(item => {
            if (!currencies?.find(currency => currency.name === item.currencyCode)) {
                currencies.push({name: item.currencyCode, value: true});
            }
            if (!impact?.find(impact => impact.name === item.impact)) {
                impact.push({name: item.impact, value: true});
            }
        })

        Filters.set(EduCalendarFilterType.Currency, currencies);
        Filters.set(EduCalendarFilterType.Impact, impact);

        Platform.dispatch(EduTradingSetLoading({loading: false}));

        Platform.dispatch(EduTradingSetEconomicCalendarEvents({
            Events: response
        }));

        Platform.dispatch(EduTradingSetEconomicCalendarFilters({
            filters: Filters
        }));
    }

    public DoGetCryptoCalendarEvents = async ({Period, dateFilter}: EduTradingFetchCryptoCalendarEventsPayload): Promise<void> => {

        let request;
        if (Utils.isNotNull(dateFilter)) {
            const dateFrom = dateFilter?.from ? DateTimeFormat.dateFormatInput(dateFilter?.from) : null;
            const dateTo = dateFilter?.from ? DateTimeFormat.dateFormatInput(dateFilter?.to) : null;
            request = {
                dateFrom,
                dateTo
            }
        } else {
            request = {dateRange: Period}
        }
        Platform.dispatch(EduTradingSetLoading({loading: true}));
        const response: EduTradingCryptoCalendarEventsResponse = await this.query("crypto-calendar/get-events", request);
        response.forEach((event: EduTradingCryptoCalendarEvent) => {
            event.currencyLogoUrl = FilesReplaceDomain(event.currencyLogoUrl);
        });
        const Filters: TSMap<EduCalendarFilterType, EduFilterValue[]> = new TSMap();
        const impact: EduFilterValue[] = [];
        response?.forEach(item => {
            if (!impact?.find(impact => impact.name === item.impact) || Utils.isArrayEmpty(impact)) {
                impact.push({name: item.impact, value: true});
            }
        });
        Filters.set(EduCalendarFilterType.Impact, impact);
        Platform.dispatch(EduTradingSetLoading({loading: false}));

        Platform.dispatch(EduTradingSetCryptoCalendarEvents({
            cryptoEvents: response
        }));

        Platform.dispatch(EduTradingSetCryptoCalendarFilters({
            filters: Filters
        }));
    }

    public DoGetSignals = async ({period}: EduTradingFetchSignalsPayload): Promise<void> => {
        let request;

        if (Utils.isNull(period) || period === EduTradingSignalsPeriod.All) {
            request = {};
        } else {
            request = {
                duration: period
            };
        }
        const response: EduTradingSignalsResponse = await this.query("signals/get-signals", request);
        const Filters: TSMap<EduCalendarFilterType, EduFilterValue[]> = new TSMap();
        const impact: EduFilterValue[] = [];
        const markets: EduFilterValue[] = [];

        Platform.dispatch(EduTradingSetLoading({loading: true}));
        
        response?.forEach((signal: EduTradingSignal) => {
            signal.imageUrl = FilesReplaceDomain(signal.imageUrl);
            if (!impact?.find(impact => impact.name === signal.strength) || Utils.isArrayEmpty(impact)) {
                impact.push({name: signal.strength, value: true});
            }
            if (!markets?.find(market => market.name === signal.assetCategory) || Utils.isArrayEmpty(markets)) {
                markets.push({name: signal.assetCategory, value: true});
            }
        });
        Filters.set(EduCalendarFilterType.Impact, impact);
        Filters.set(EduCalendarFilterType.Currency, markets);

        Platform.dispatch(EduTradingSetSignalsFilters({
            filters: Filters
        }));

        Platform.dispatch(EduTradingSetSignals({
            Signals: response
        }));
        setTimeout(() => {
            Platform.dispatch(EduTradingSetLoading({loading: false}))
        }, 0);
    }

    public DoGetMarketVideos = async ({videoType}: EduTradingFetchMarketVideosPayload): Promise<void> => {
        const request = {
            category: videoType,
            limit: 100
        }
        Platform.dispatch(EduTradingSetLoading({loading: true}));
        const response: EduTradingMarketVideosResponse = await this.query("market-research-videos/get-recent-videos", request);
        response?.forEach((video: EduTradingMarketVideo) => {
            video.file = FilesReplaceDomain(video.file);
            video.image = FilesReplaceDomain(video.image);
            video.teaser = FilesReplaceDomain(video.teaser);
        });
        Platform.dispatch(EduTradingSetMarketSearchVideos({videoType, videos: response}));
        Platform.dispatch(EduTradingSetLoading({loading: false}));
    }

    public DoGetCurrencyStrengthMeter = async (): Promise<void> => {
        Platform.dispatch(EduTradingSetLoading({loading: true}));

        const response: EduTradingCurrencyStrengthMeterResponse = await this.query("currency-strength-meter/get-strengths", {});

        Platform.dispatch(EduTradingSetLoading({loading: false}));
        Platform.dispatch(EduTradingSetCurrencyStrengthMeter({currencies: response}))
    }

    public DoGetGlossary = async (): Promise<void> => {
        Platform.dispatch(EduTradingSetLoading({loading: true}));

        const response: EduTradingGlossaryResponse = await this.query("glossary/get-terms", {});

        Platform.dispatch(EduTradingSetLoading({loading: false}));
        Platform.dispatch(EduTradingSetGlossary({glossary: response}))
    }

    public DoGetTrendAnalysis = async (): Promise<void> => {
        Platform.dispatch(EduTradingSetLoading({loading: true}));

        const response: EduTradingTrendAnalysisResponse = await this.query("trend-analyzer/get-trends", {});

        Platform.dispatch(EduTradingSetTrendAnalisys({trendAnalysis: response}));
        Platform.dispatch(EduTradingSetLoading({loading: false}));
    }
}
