import Platform from "platform/Platform";
import {ExecuteDpk} from "platform/redux/dpk/DpkActions";
import {Dpk} from "platform/dpk/Dpk";
import {DpkType} from "enum/DpkType";
import {Tutorial} from "component/tour/types";

export const onTutorialFinish = (tutorial: Tutorial)=> {
    Platform.dispatch(ExecuteDpk({
        dpk: Dpk.build(DpkType.TutorialLearnMore),
    }));
}
