import {LoginAccountInfo} from "protocol/auth/LoginAccountInfo";
import {TSMap} from "typescript-map";
import {NXEnvironmentType} from "platform/protocol/enum/NXEnvironmentType";
import Platform from "platform/Platform";
import {StorageKey} from "enum/StorageKey";
import Utils from "platform/util/Utils";
import {Logger} from "platform/logger/Logger";

export class AuthState {

    private static _instance: AuthState;
    private _logger: Logger = Logger.Of(this.constructor.toString().match(/\w+/g)[1]);
    private _accounts: TSMap<NXEnvironmentType, LoginAccountInfo> = new TSMap();
    private _token: string;
    private _bioPrompt: boolean;

    private constructor() {}

    public static instance(): AuthState {
        return this._instance || (this._instance = new this());
    }

    public get token(): string {
        return this._token;
    }

    public set token(value: string) {
        this._token = value;
    }

    public get bioPrompt(): boolean {
        return this._bioPrompt;
    }

    public set bioPrompt(value: boolean) {
        this._bioPrompt = value;
    }

    public setAccount(account: LoginAccountInfo): void {
        this._logger.debug("Add account for: " + account.EnvironmentType + ". Account type: " + account.AccountType);
        this._accounts.set(account.EnvironmentType, account);
    }

    public getAccount(environmentType: NXEnvironmentType): LoginAccountInfo {
        return this._accounts.get(environmentType);
    }

    public serializeAccounts(): void {
        Platform.storage().setItem(StorageKey.Accounts, JSON.stringify(this._accounts));
    }

    public async deserializeAccounts(): Promise<void> {
        const sAccounts: string = await Platform.storage().getItem(StorageKey.Accounts);
        if (Utils.isNotEmpty(sAccounts)) {
            try {
                const accounts: any = JSON.parse(sAccounts);
                if (!Utils.isObjectEmpty(accounts)) {
                    Object.keys(accounts).forEach((key: string) => {
                        this._accounts.set(parseInt(key, 10), accounts[key]);
                    });
                }
            } catch (e) {
                this._logger.debug("Failed deserialize accounts");
            }
        }
    }

    public clear(): void {
        this.token = null;
        this._accounts = new TSMap();
    }
}
