import {Engine} from "platform/engine/Engine";
import CoreEngine from "platform/engine/CoreEngine";
import {LangCode} from "platform/enum/LangCode";
import {GetAllCountriesRequest} from "platform/protocol/common/GetAllCountriesRequest";
import {Http, HttpReject} from "platform/network/http/Http";
import {GetAllCountriesResponse} from "platform/protocol/common/GetAllCountriesResponse";
import Utils from "platform/util/Utils";
import {XhrUtil} from "core/util/XhrUtil";
import {ServerType} from "platform/enum/ServerType";
import {TSMap} from "typescript-map";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import Platform from "platform/Platform";
import {
    DoGetURLPayload, RemoveBannerPayload,
    SetConfiguredCountries,
    SetLDPlatformProps,
} from "core/redux/app/AppReduxActions";
import {SetAuthStatusPayload} from "core/redux/auth/AuthReduxActions";
import {StoreState} from "core/redux/StoreState";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {GetURLByTypeRequest} from "platform/protocol/common/GetURLByTypeRequest";
import {GetUrlType} from "platform/protocol/enum/GetUrlType";
import {UrlType} from "platform/enum/UrlType";
import {GetURLByTypeResponse} from "platform/protocol/common/GetURLByTypeReponse";
import {SetTheme, SetUrls, ShowChatPayload} from "platform/redux/core/CoreActions";
import WebUtil from "platform/util/WebUtil";
import {EventType} from "platform/enum/EventType";
import {AutoChartistMessageType} from "core/autochartist/AutoChartistMessageType";
import {AutoChartistTradeNow} from "core/autochartist/AutoChartistTradeNow";
import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol";
import {GoingOpenDeal} from "core/redux/deal/DealReduxActions";
import {PositionDirection} from "platform/protocol/enum/PositionDirection";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIconType} from "platform/redux/popups/PopupsReduxState";
import {TranslationKey} from "enum/TranslationKey";
import Parameter from "platform/util/Parameter";
import {TranslationParam} from "enum/TranslationParam";
import {DpkType} from "enum/DpkType";
import {Dpk} from "platform/dpk/Dpk";
import {DpkParam} from "enum/DpkParam";
import {ExecuteDpk} from "platform/redux/dpk/DpkActions";
import {AccountState} from "core/state/AccountState";
import {ServiceType} from "enum/ServiceType";
import {Locale} from "core/format/Locale";
import {NXEnvironmentType} from "platform/protocol/enum/NXEnvironmentType";
import {Win} from "platform/integration/win/Win";
import {Router} from "router5";
import {RouteType} from "core/router/Routes";
import {DoRegisterUserActivity} from "core/redux/account/AccountReduxActions";
import {UserActivityType} from "enum/UserActivityType";
import {LoadLanguagePayload} from "platform/redux/translation/TranslationActions";
import {
    LDAcademyVideo,
    LDAcademyVideoGroup,
    LDTutorial,
    LDPlatformProp,
    LDPlatformProps
} from "core/props/LDPlatformProps";
import {EnvType} from "platform/enum/EnvType";
import {ThemeType} from "platform/enum/ThemeType";
import {StorageKey} from "enum/StorageKey";
import {Preference} from "core/util/Preference";
import {SetClientPreference} from "core/redux/settings/SettingsReduxActions";
import {LDUtil} from "core/util/LDUtil";
import {LSKey} from "platform/storage/Storage";
import {ReadyState} from "core/state/ReadyState";
import {BIEventType} from "enum/BIEventType";
import {BIUtil} from "core/util/BIUtil";
import {Configuration} from "core/configuration/Configuration";
import {AppState} from "core/state/AppState";
import {BannerType} from "enum/BannerType";
import {ConfigUtil} from "core/util/ConfigUtil";

let DpkUrl: string = WebUtil.urlParam("dpk");
const decodedDpkUrl: string = decodeURIComponent(DpkUrl);
const DpkId: string = WebUtil.urlParam(DpkParam.Id) || WebUtil.urlParam(DpkParam.Id, decodedDpkUrl);
const DpkTypeParam: string = WebUtil.urlParam(DpkParam.Type) || WebUtil.urlParam(DpkParam.Type, decodedDpkUrl);
const DpkJWT: string = WebUtil.urlParam(DpkParam.JWT) || WebUtil.urlParam(DpkParam.JWT, decodedDpkUrl);
const DpkMethod: string = WebUtil.urlParam(DpkParam.Method) || WebUtil.urlParam(DpkParam.Method, decodedDpkUrl);
const DpkCurrency: string = WebUtil.urlParam(DpkParam.Currency) || WebUtil.urlParam(DpkParam.Currency, decodedDpkUrl);
const DpkAmount: string = WebUtil.urlParam(DpkParam.Amount) || WebUtil.urlParam(DpkParam.Amount, decodedDpkUrl);
const DpkRic: string = WebUtil.urlParam(DpkParam.Ric) || WebUtil.urlParam(DpkParam.Ric, decodedDpkUrl);
const DpkRIC: string = WebUtil.urlParam(DpkParam.RIC) || WebUtil.urlParam(DpkParam.RIC, decodedDpkUrl);
const DpkDirection: string = WebUtil.urlParam(DpkParam.direction) || WebUtil.urlParam(DpkParam.direction, decodedDpkUrl);
const DpkSl: string = WebUtil.urlParam(DpkParam.StopLoss) || WebUtil.urlParam(DpkParam.StopLoss, decodedDpkUrl);
const DpkTp: string = WebUtil.urlParam(DpkParam.TakeProfit) || WebUtil.urlParam(DpkParam.TakeProfit, decodedDpkUrl);
const DpkTab: string = WebUtil.urlParam(DpkParam.Tab) || WebUtil.urlParam(DpkParam.Tab, decodedDpkUrl);
const DpkVideoId: string = WebUtil.urlParam(DpkParam.VideoId) || WebUtil.urlParam(DpkParam.VideoId, decodedDpkUrl);
const DpkTutorialId: string = WebUtil.urlParam(DpkParam.TutorialId) || WebUtil.urlParam(DpkParam.TutorialId, decodedDpkUrl);

export default class AppEngine extends Engine {

    private static _instance: AppEngine;
    private _dpkTasks: Array<() => void> = [];

    public static instance(): AppEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        await CoreEngine.instance().setup();
        await Locale.provider.load();
        ReadyState.clear();
        const {mixpanelApiKey, brand, blockedBanners} = Platform.config<Configuration>();
        AppState.instance().setBlockedBanners((blockedBanners || []) as BannerType[]);
        if (mixpanelApiKey) {
            Platform.bi().init(mixpanelApiKey, brand);
        }
        if (Platform.environment().type() === EnvType.Web) {
            const router: Router = Platform.router().router();
            router.canActivate(RouteType.AccountOverview, () => () => {
                return WebUtil.isMobile();
            });
            router.canActivate(RouteType.Promotions, () => () => {
                return false;
            });
            router.canActivate(RouteType.Lessons, () => () => {
                return Platform.reduxState<StoreState>().app.blockedMenuRoutes.indexOf(RouteType.Lessons) < 0
                    && (WebUtil.isMobile() || Platform.reduxState<StoreState>().settings.disclaimerAcceptedForExternalProviders);
            });
            router.canActivate(RouteType.Videos, () => () => {
                return Platform.reduxState<StoreState>().app.blockedMenuRoutes.indexOf(RouteType.Videos) < 0;
            });
            router.canActivate(RouteType.Alerts, () => () => {
                return Platform.reduxState<StoreState>().app.blockedMenuRoutes.indexOf(RouteType.Alerts) < 0;
            });
            router.canActivate(RouteType.Signals, () => () => {
                const {account, settings} = Platform.reduxState<StoreState>();
                return WebUtil.isMobile() || Platform.config<Configuration>().IsEduTrading || settings.disclaimerAcceptedForExternalProviders
                    || (Utils.isNotNull(account.AreSignalsAllowed) && !account.AreSignalsAllowed);
            });
            router.canActivate(RouteType.EconomicCalendar, () => () => {
                return WebUtil.isMobile() || Platform.config<Configuration>().IsEduTrading || Platform.reduxState<StoreState>().settings.disclaimerAcceptedForExternalProviders;
            });
            router.canActivate(RouteType.News, () => () => {
                return WebUtil.isMobile() || Platform.config<Configuration>().IsEduTrading || Platform.reduxState<StoreState>().settings.disclaimerAcceptedForExternalProviders;
            });
            router.canActivate(RouteType.FollowedAccounts, () => () => {
                return Platform.reduxState<StoreState>().app.blockedMenuRoutes.indexOf(RouteType.FollowedAccounts) < 0
                && Platform.reduxState<StoreState>().partner.IsEnabled;
            });
        }
        Platform.environment().addEventListener(EventType.Message, (e: any) => {
            try {
                const message = JSON.parse(e.data);
                if (message.type === AutoChartistMessageType.TradeNow) {
                    const tradeNow: AutoChartistTradeNow = message;
                    const symbolName: string = tradeNow?.value?.item?.symbol_name;
                    const direction: number = tradeNow?.value?.item?.direction;
                    let foundSymbol: boolean;
                    if (symbolName) {
                        const {symbols} = Platform.reduxState<StoreState>().symbols;
                        for (let i = 0; i < symbols.size(); i++) {
                            const symbol: TradeSymbol = symbols.values()[i];
                            if (symbol?.text === symbolName) {
                                foundSymbol = true;
                                Platform.dispatch(GoingOpenDeal({
                                    SymbolId: symbol.SymbolId,
                                    Direction: direction === 1 ? PositionDirection.Buy : PositionDirection.Sell,
                                    stopLoss: tradeNow?.value?.item?.signal_levels?.stop_loss,
                                    takeProfit: tradeNow?.value?.item?.signal_levels?.target_level,
                                    comments: "Opened by Trading Opportunities"
                                }));
                                Platform.dispatch(DoRegisterUserActivity({
                                    userActivity: UserActivityType.OpenPositionByTradingInsider
                                }));
                                break;
                            }
                        }
                    }
                    if (!foundSymbol) {
                        Platform.dispatch(ShowPopup({
                            popup: {
                                message: {
                                    trKey: TranslationKey.symbolNotFound,
                                    params: [Parameter.Of(TranslationParam.name, symbolName)]
                                },
                                showClose: true,
                                icon: {type: PopupIconType.ERROR},
                                actions: [{type: PopupActionType.OK}]
                            }
                        }));
                    }
                }
            } catch (e) {}
        });
        try {
            const theme: string = await Platform.storage().getItem(LSKey.Theme);
            if (theme) {
                const themeType: ThemeType = ThemeType.deserialize(theme);
                Platform.dispatch(SetTheme({
                    themeType
                }));
                Platform.dispatch(SetClientPreference({
                    key: LSKey.Theme, preference: Preference.Of(themeType)
                }));
            }
            const preferenceShowChartDesktop: string = await Platform.storage().getItem(StorageKey.ShowChartDesktop);
            if (preferenceShowChartDesktop) {
                Platform.dispatch(SetClientPreference({key: StorageKey.ShowChartDesktop, preference: Preference.Of(Utils.parseBoolean(preferenceShowChartDesktop))}));
            } else {
                Platform.dispatch(SetClientPreference({key: StorageKey.ShowChartDesktop, preference: Preference.Of(true)}));
            }
            const preferenceShowChartMobile: string = await Platform.storage().getItem(StorageKey.ShowChartMobile);
            if (preferenceShowChartMobile) {
                Platform.dispatch(SetClientPreference({key: StorageKey.ShowChartMobile, preference: Preference.Of(Utils.parseBoolean(preferenceShowChartMobile))}));
            } else {
                Platform.dispatch(SetClientPreference({key: StorageKey.ShowChartMobile, preference: Preference.Of(true)}));
            }
            const preferenceCompactListView: string = await Platform.storage().getItem(StorageKey.CompactListView);
            if (preferenceCompactListView) {
                Platform.dispatch(SetClientPreference({key: StorageKey.CompactListView, preference: Preference.Of(Utils.parseBoolean(preferenceCompactListView))}));
            } else {
                Platform.dispatch(SetClientPreference({key: StorageKey.CompactListView, preference: Preference.Of(true)}));
            }
            const preferenceExpandedView: string = await Platform.storage().getItem(StorageKey.ExpandedView);
            if (preferenceExpandedView) {
                Platform.dispatch(SetClientPreference({key: StorageKey.ExpandedView, preference: Preference.Of(Utils.parseBoolean(preferenceExpandedView))}));
            } else {
                const config: Configuration = Platform.config<Configuration>();
                Platform.dispatch(SetClientPreference({key: StorageKey.ExpandedView, preference: Preference.Of(config?.Settings.MyTrades?.ExpandedListView)}));
            }
            const preferenceChartMobileY: string = await Platform.storage().getItem(StorageKey.ChartMobileY);
            Platform.dispatch(SetClientPreference({key: StorageKey.ChartMobileY, preference: Preference.Of(Utils.parseNumber(preferenceChartMobileY))}));
            const preferenceChartMobileAcY: string = await Platform.storage().getItem(StorageKey.ChartMobileAcY);
            Platform.dispatch(SetClientPreference({key: StorageKey.ChartMobileAcY, preference: Preference.Of(Utils.parseNumber(preferenceChartMobileAcY))}));
            const preferenceChartMobileArY: string = await Platform.storage().getItem(StorageKey.ChartMobileArY);
            Platform.dispatch(SetClientPreference({key: StorageKey.ChartMobileArY, preference: Preference.Of(Utils.parseNumber(preferenceChartMobileArY))}));
        } catch (e) {}
    }

    public onLoggedOut = () => {
        ReadyState.logOut();
        const {blockedBanners} = Platform.config<Configuration>();
        AppState.instance().setBlockedBanners((blockedBanners || []) as BannerType[]);
    }

    public onChangeRoute = ({route}): void => {
        if (route?.name) {
            Platform.bi().track(BIEventType.ViewLoaded, {
                Name: BIUtil.PageName(route),
                TabName: BIUtil.PageTabName(route)
            });
        }
    }

    public onLoadLanguage = async (payload: LoadLanguagePayload) => {
        const curLangCode: LangCode = Platform.reduxState().translation.langCode;
        if (curLangCode !== payload.langCode) {
            await this.doFetchPlatformPropsInternal(payload.langCode);
        }
    }

    public onRemoveBanner = ({banner, block}: RemoveBannerPayload): void => {
        if (Utils.isNull(banner.remoteId) && block) {
            const blockedBanners: BannerType[] = AppState.instance().getBlockedBanners();
            AppState.instance().setBlockedBanners([...blockedBanners, banner.type]);
        }
    }

    public doFetchPlatformProps = async () => {
        const langCode: LangCode = LanguageUtil.languageCode();
        await this.doFetchPlatformPropsInternal(langCode);
    }

    private doFetchPlatformPropsInternal = async (langCode: LangCode) => {
        const {brandPropsUrl} = Platform.reduxState().core;
        const brandAssetUrl: string = brandPropsUrl || Platform.config<Configuration>().brandAssetUrl;
        if (brandAssetUrl) {
            this._logger.debug("Start fetch platform props for lang: " + langCode);
            const {brandAssetSharedUrl} = Platform.config<Configuration>();
            type LDUrl = {url: string, shared?: boolean};
            const urls: LDUrl[] = [
                {url: `${brandAssetSharedUrl}en/Platform_full.json`, shared: true},
                {url: `${brandAssetUrl}en/Platform_full.json`}
            ];
            if (langCode !== LangCode.EN) {
                urls.push(
                    {url: `${brandAssetSharedUrl}${langCode}/Platform_full.json`, shared: true},
                    {url: `${brandAssetUrl}${langCode}/Platform_full.json`}
                );
            }
            const sharedSkipKeys: string[] = [];
            const enProps: LDPlatformProps[] = [];
            const langProps: LDPlatformProps[] = [];
            const allProps: LDPlatformProps[] = [];
            for (let i: number = 0; i < urls.length; i++) {
                const ldUrl: LDUrl = urls[i];
                const answer: [HttpReject, LDPlatformProps] = await Utils.to(Http.getJson(ldUrl.url));
                if (answer[1]) {
                    answer[1].metadata.shared = ldUrl.shared;
                    allProps.push(answer[1]);
                    (i <= 1 ? enProps : langProps).push(answer[1]);
                    if (!ldUrl.shared) {
                        const skipProps: LDPlatformProp = answer[1].keys?.SharedSkipKeys;
                        if (Utils.isNotEmpty(skipProps?.value)) {
                            try {
                                sharedSkipKeys.push(JSON.parse(skipProps.value));
                            } catch (e) {}
                        }
                    }
                }
            }
            this._logger.debug("LD platform skip shared keys", sharedSkipKeys);
            const resultProps: LDPlatformProps = {keys: {}};
            allProps.forEach((props: LDPlatformProps) => {
                if (props.metadata.shared && Utils.isArrayNotEmpty(sharedSkipKeys)) {
                    sharedSkipKeys.forEach(k => delete props.keys[k]);
                }
                if (props?.keys) {
                    resultProps.keys = {...resultProps.keys, ...props.keys};
                }
            });
            const tutorials: TSMap<string, LDTutorial> = new TSMap<string, LDTutorial>();
            const academyVideoGroups: TSMap<number, LDAcademyVideoGroup> = new TSMap<number, LDAcademyVideoGroup>();
            const academyVideos: TSMap<number, LDAcademyVideo[]> = new TSMap<number, LDAcademyVideo[]>();
            (Utils.isArrayNotEmpty(langProps) ? langProps : enProps).forEach((props: LDPlatformProps) => {
                LDUtil.parseVideos(props, academyVideoGroups, academyVideos);
            });
            LDUtil.parseTutorials(resultProps, tutorials);
            const videosResult: {academyVideoGroups: LDAcademyVideoGroup[], academyVideos: TSMap<number, LDAcademyVideo[]>} = LDUtil.filterVideos(academyVideoGroups, academyVideos);
            this._logger.debug("Fire set platform props");
            Platform.dispatch(SetLDPlatformProps({
                props: resultProps,
                tutorials: tutorials.values(),
                ...videosResult
            }));
            ReadyState.hasLDPlatformProps = true;
        }
    }

    public onShowChat = (payload: ShowChatPayload): void => {
        Platform.dispatch(DoRegisterUserActivity({
            userActivity: UserActivityType.ChatRequest,
            comment: payload.comment
        }));
    }

    public doSetAuthStatus = async (payload: SetAuthStatusPayload) => {
        const {connected, authenticated, accountType} = Platform.reduxState<StoreState>().auth;
        if (connected) {
            const accountState: AccountState = Platform.state(ServiceType.Account);
            if (accountState.accountType === NXEnvironmentType.Live &&
                    ((!authenticated && payload.authStatus.authenticated) || (accountType && payload.authStatus.accountType && accountType !== payload.authStatus.accountType))) {
                const langCode: LangCode = LanguageUtil.languageCode();
                this._logger.debug("Fetch countries for lang code: " + langCode);
                const request: GetAllCountriesRequest = {LanguageCode: langCode};
                const answer: [HttpReject, GetAllCountriesResponse] = await Utils.to(this.sendToComplianceService(request, "GetAllCountries"));
                if (answer[0]) {
                    this._logger.debug("Failed fetch countries list. Status: " + answer[0].status);
                } else {
                    const response: GetAllCountriesResponse = answer[1];
                    if (Utils.isArrayNotEmpty(response?.Countries)) {
                        this._logger.debug("Received countries list. Size: " + response.Countries.length);
                        const countries: TSMap<number, CountryInfo> = new TSMap();
                        const namePerCountry: TSMap<string, CountryInfo> = new TSMap();
                        response.Countries.sort((ci1: CountryInfo, ci2: CountryInfo) => Utils.compareString(ci1.LocalizedName, ci2.LocalizedName)).forEach((countryInfo: CountryInfo) => {
                            countryInfo.LocalizedName = (countryInfo.LocalizedName || "").trim();
                            countries.set(countryInfo.Id, countryInfo);
                            namePerCountry.set(countryInfo.LocalizedName, countryInfo);
                        });
                        Platform.dispatch(SetConfiguredCountries({
                            countries, namePerCountry
                        }));
                    } else {
                        this._logger.debug("Countries list empty");
                    }
                }
            }
        }
    }

    public doGetUrl = async ({urlType, parameters}: DoGetURLPayload) => {
        const url: string = await this.doGetUrlInternal(urlType, parameters);
        this._logger.debug("Final url for type " + urlType + ". URL: " + url);
        const parameter: Parameter = Parameter.parameter("win", parameters);
        const win: Win = parameter?.value;
        Platform.bi().track(BIEventType.GetUrlResult, {
            URL: url,
            Target: Utils.isNotNull(parameter) ? "blank" : "iframe",
            HasTargetWindow: Utils.isNotNull(win)
        });
        if (url) {
            const action: (Url: string) => void = Parameter.value("action", parameters);
            if (win) {
                win.origin().location.href = url;
            } else if (action) {
                action(url);
            } else if (Utils.isNotNull(parameter) && Utils.isNull(win) && (urlType === UrlType.Kyc || urlType === UrlType.Deposit)) {
                Platform.environment().redirect(url);
            } else {
                Platform.dispatch(SetUrls({urls: [{type: urlType, url}]}));
            }
        }
    }

    public static DoGetUrlInternal = async (urlType: UrlType, parameters: Parameter[]): Promise<string> => {
        const appEngine: AppEngine = Platform.engine(ServiceType.App);
        return await appEngine.doGetUrlInternal(urlType, parameters);
    }

    protected doGetUrlInternal = async (urlType: UrlType, parameters: Parameter[]): Promise<string> => {
        const URLType: GetUrlType = UrlType.getUrlType(urlType);
        if (Utils.isNotNull(URLType)) {
            Platform.bi().track(BIEventType.GetUrlAttempt, {
                UrlType
            });
            const langCode: LangCode = LanguageUtil.languageCode();
            const request: GetURLByTypeRequest = {LanguageCode: langCode, URLType};
            const answer: [HttpReject, GetURLByTypeResponse] = await Utils.to(this.sendToLoginSecure(request, "GetURLByType"));
            if (answer[0]) {
                this._logger.debug("Failed fetch url by type " + urlType + ". Status: " + answer[0].status);
                Platform.bi().track(BIEventType.GetUrlResult, {
                    Success: false,
                    Response: answer[0].response
                });
            } else {
                const {theme} = Platform.reduxState<StoreState>().core;
                const response: GetURLByTypeResponse = answer[1];
                this._logger.debug("Received url for type " + urlType + ". URL: " + response.URL);
                let url: string = ConfigUtil.UseAddressBarDomain(response.URL);
                if (urlType === UrlType.Deposit) {
                    url = decodeURIComponent(url);
                    url = url.replace("{0}", langCode);
                    url = url.replace("&isCalledFromCompliance=True", "");
                    url = WebUtil.addGetParams(url, {
                        platform: "profit",
                        themeType: theme,
                        theme,
                    });
                    const dpk: string = Parameter.value("dpk", parameters);
                    if (dpk) {
                        url = WebUtil.addGetParam(url, "dpk", dpk);
                    }
                } else {
                    const GetParams: { [key: string]: string } = {
                        "token": response.Token,
                        "language": langCode,
                        theme,
                        "bi": "true"
                    };
                    url = WebUtil.addGetParams(url, GetParams);
                    const kycWebView = urlType === UrlType.Kyc && Platform.environment().type() === EnvType.Native;
                    if (urlType === UrlType.Professional || urlType === UrlType.Documents || urlType === UrlType.DepositLimits || urlType === UrlType.ShareInfo || kycWebView) {
                        const params: {[key: string]: string} = {
                            "platform": "new"
                        };
                        if (kycWebView && Utils.parseBoolean(Parameter.value("skipWelcome", parameters))) {
                            params["ts"] = new Date().getTime().toString();
                            params["skipWelcome"] = "true";
                        }
                        url = WebUtil.addGetParams(url, params);
                    }
                }
                return Promise.resolve(url);
            }
        }
        return Promise.resolve(null);
    }

    public addDpkTask = (task: () => void): void => {
        if (task) {
            this._dpkTasks.push(task);
        }
    }

    public tryExecuteDpk = (): void => {
        this._logger.debug("Try execute startup DPK");
        this._logger.debug("URL dpk: " + DpkUrl + " Type: " + DpkTypeParam + " Ric :" + DpkRic + " RIC " + DpkRIC + " direction: " + DpkDirection
            + " SL: " + DpkSl + " TP: " + DpkTp + " Id: " + DpkId + " JWT: " + DpkJWT);
        const dpkType: DpkType = DpkType.deserialize(DpkUrl);
        if (dpkType) {
            const params: Parameter[] = [];
            if (dpkType === DpkType.OpenDeposit || dpkType === DpkType.Deposit) {
                params.push(Parameter.Of("dpk", Dpk.build(dpkType,
                    Parameter.Of(DpkParam.Method, DpkMethod, false),
                    Parameter.Of(DpkParam.Currency, DpkCurrency, false),
                    Parameter.Of(DpkParam.Amount, DpkAmount, false)
                )));
            } else if (dpkType === DpkType.openposition) {
                if (DpkRic) {
                    params.push(Parameter.Of(DpkParam.Ric, DpkRic));
                } else if (DpkRIC) {
                    params.push(Parameter.Of(DpkParam.Ric, DpkRIC));
                }
                params.push(Parameter.Of(DpkParam.direction, DpkDirection));
                params.push(Parameter.Of(DpkParam.StopLoss, DpkSl));
                params.push(Parameter.Of(DpkParam.TakeProfit, DpkTp));
            } else {
                if (DpkId) {
                    params.push(Parameter.Of(DpkParam.Id, DpkId));
                }
                if (DpkTypeParam) {
                    params.push(Parameter.Of(DpkParam.Type, DpkTypeParam));
                }
                if (DpkJWT) {
                    params.push(Parameter.Of(DpkParam.JWT, DpkJWT));
                }
                if (DpkRic) {
                    params.push(Parameter.Of(DpkParam.Ric, DpkRic));
                } else if (DpkRIC) {
                    params.push(Parameter.Of(DpkParam.Ric, DpkRIC));
                }
                if (DpkTab) {
                    params.push(Parameter.Of(DpkParam.Tab, DpkTab));
                }
                if (DpkVideoId) {
                    params.push(Parameter.Of(DpkParam.VideoId, DpkVideoId));
                }
                if (DpkTutorialId) {
                    params.push(Parameter.Of(DpkParam.TutorialId, DpkTutorialId));
                }
            }
            Platform.dispatch(ExecuteDpk({
                dpk: DpkUrl,
                params
            }));
        }
        DpkUrl = null;
        if (Utils.isArrayNotEmpty(this._dpkTasks)) {
            this._dpkTasks.forEach((task: () => void) => {
                task();
            });
            this._dpkTasks = [];
        }
    }

    private sendToComplianceService(request: any, path: string): Promise<any> {
        return XhrUtil.sendAuthenticatedTo(ServerType.Compliance, request, "ComplianceWebSite/ComplianceConfigurationsService.svc/json/" + path);
    }

    private sendToLoginSecure = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendToLogin(request, "loginsecureService.svc/json/" + path);
    }
}
