import {
    LDAcademyVideo,
    LDAcademyVideoConfig,
    LDAcademyVideoGroup, LDPlatformProp,
    LDPlatformProps, LDTutorial
} from "core/props/LDPlatformProps";
import Utils from "platform/util/Utils";
import {Logger} from "platform/logger/Logger";
import {TSMap} from "typescript-map";
import Platform from "platform/Platform";
import {EnvType} from "platform/enum/EnvType";

export class LDUtil {

    private static readonly _logger: Logger = Logger.Of("LDUtil");

    private constructor() {
    }

    public static parseTutorials(props: LDPlatformProps, tutorials: TSMap<string, LDTutorial>): void {
        const prop: LDPlatformProp = Platform.environment().type() === EnvType.Web ? props?.keys?.TutorialsWeb : props?.keys?.Tutorials;
        if (prop) {
            try {
                const ldTutorials: LDTutorial[] = JSON.parse(prop.value);
                ldTutorials?.forEach((tutorial: LDTutorial) => tutorials.set(tutorial.id, tutorial));
            } catch (e) {
                LDUtil._logger.info("Failed parse LDTutorialConfig");
            }
        }
    }

    public static parseVideos(props: LDPlatformProps, academyVideoGroups: TSMap<number, LDAcademyVideoGroup>, academyVideos: TSMap<number, LDAcademyVideo[]>): void {
        if (props?.keys?.AcademyVideos) {
            try {
                const academyVideosConfig: LDAcademyVideoConfig = JSON.parse(props?.keys?.AcademyVideos.value);
                if (Utils.isArrayNotEmpty(academyVideosConfig.academyVideoGroups)) {
                    academyVideosConfig.academyVideoGroups.forEach((group: LDAcademyVideoGroup) => {
                        academyVideoGroups.set(group.id, group);
                        if (!academyVideos.has(group.id)) {
                            academyVideos.set(group.id, []);
                        }
                    });
                    if (Utils.isArrayNotEmpty(academyVideosConfig.academyVideos)) {
                        academyVideosConfig.academyVideos.forEach((av: LDAcademyVideo) => {
                            if (av.groups) {
                                av.groups.forEach((groupId: number) => {
                                    const videos: LDAcademyVideo[] = academyVideos.get(groupId);
                                    if (videos) {
                                        videos.push(av);
                                    }
                                });
                            }
                        });
                    }
                }
            } catch (e) {
                this._logger.warn("Failed parse LDAcademyVideoConfig");
            }
        }
    }

    public static filterVideos(groups: TSMap<number, LDAcademyVideoGroup>, videosPerGroup: TSMap<number, LDAcademyVideo[]>): {
        academyVideoGroups: LDAcademyVideoGroup[],
        academyVideos: TSMap<number, LDAcademyVideo[]>
    } {
        const academyVideoGroups: LDAcademyVideoGroup[] = [];
        const academyVideos: TSMap<number, LDAcademyVideo[]> = videosPerGroup.filter((videos: LDAcademyVideo[]) => Utils.isArrayNotEmpty(videos));
        groups.forEach((group: LDAcademyVideoGroup) => {
            if (academyVideos.has(group.id)) {
                academyVideoGroups.push(group);
            }
        });
        return {
            academyVideoGroups,
            academyVideos
        };
    }

    public static getVideo(videoId: string, academyVideos: TSMap<number, LDAcademyVideo[]>): {groupId: number, video: LDAcademyVideo} {
        if (videoId && academyVideos) {
            for (let i = 0; i < academyVideos.values().length; i++) {
                const videos: LDAcademyVideo[] = academyVideos.values()[i];
                if (videos) {
                    for (let j = 0; j < videos.length; j++) {
                        if (videoId === videos[j].videoId) {
                            return {
                                groupId: academyVideos.keys()[i],
                                video: videos[j]
                            };
                        }
                    }
                }
            }
        }
        return null;
    }
}
