import moment from "moment-mini";
import {TSMap} from "typescript-map";
import {PortfolioType} from "enum/PortfolioType";
import {Continent} from "enum/Continent";
import {QuoteStatus} from "platform/protocol/enum/QuoteStatus";
import {EntryOrderStatus} from "platform/protocol/trading/EntryOrderStatus";

export enum FilterDateType {

    Today = "today",
    ThisWeek = "thisWeek",
    ThisMonth = "thisMonth",
    ThisYear = "thisYear",
    All = "all",
    Range = "range",
    Tomorrow = "tomorrow",
    NextWeek = "NextWeek",
}

export interface FilterReduxState {

    filterVisible?: boolean;
    closedTradesDefaultFilter?: FilterDateType;
    portfolioDateFilter: TSMap<PortfolioType, FilterDateState>;
    partnerDateFilter: TSMap<PortfolioType, FilterDateState>;
    symbolFilter: FilterSymbolState;
}

export interface FilterDateState {

    dateType?: FilterDateType;
    from?: moment.Moment;
    to?: moment.Moment;
    orderStatus?: EntryOrderStatus[];
}

export interface FilterSymbolState {

    continents: Continent[];
    marketStatus?: QuoteStatus;
}
