import {LangCode} from "platform/enum/LangCode";
import {GetUILocalization} from "protocol/config/GetUILocalization";
import {Http, HttpReject} from "platform/network/http/Http";
import Utils from "platform/util/Utils";
import {GetUILocalizationResponse} from "protocol/config/GetUILocalizationResponse";
import {Logger} from "platform/logger/Logger";
import {XhrHeaders} from "platform/network/xhr/XhrHeaders";

export class LocalizationManager {

    private static logger: Logger = Logger.Of("LocalizationManager");

    public static FetchLocalizations = async (languageCode: LangCode, origin: string, ctoken: string, brandId?: number): Promise<{[key: string]: string}> => {
        LocalizationManager.logger.debug("Fetching localization");
        const request: GetUILocalization = {
            languageCode,
            brandId
        };
        const answer: [HttpReject, string] = await Utils.to(Http.post(
            origin + "WebProfitServer/WebProfitClientService.svc/json/GetUILocalization",
            JSON.stringify(request),
            XhrHeaders.Value(ctoken),
            true
        ));
        try {
            const response: GetUILocalizationResponse = JSON.parse(answer[1].trim());
            LocalizationManager.logger.debug("Localization parsed");
            return Promise.resolve(response?.Localization || {});
        } catch (e) {
            LocalizationManager.logger.debug("Failed parse localization");
        }
        return Promise.resolve({});
    }
}
