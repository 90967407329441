
enum TradingModalType {

    // IFrame based
    Deposit = "deposit",
    KYC = "kyc",
    Professional = "professional",
    Documents = "documents",
    DepositLimits = "depositLimits",
    ShareInfo = "shareInfo",
    // Trade related
    AddSymbol = "addSymbol",
    CreateTrade = "createTrade",
    CreateEditTrade = "createEditTrade",
    CloseTrades = "closeTrades",
    ClosedTrade = "closedTrade",
    SetAlert = "setAlert",
    OrderDetails = "orderDetails",
    OpenTrade = "openTrade",
    PendingOrder = "pendingOrder",
    // Account related
    Withdrawal = "withdrawal",
    WithdrawalSuccess = "withdrawalSuccess",
    AccountStatements = "accountStatements",
    TransactionsHistory = "transactionsHistory",
    ChangePassword = "changePassword",
    CloseOutMethod = "closeOutMethod",
    MyLeverageDisclaimer = "myLeverageDisclaimer",
    MyLeverage = "myLeverage",
    ChangeName = "changeName",
    ChangeEmail = "changeEmail",
    ChangePhoneNumber = "changePhoneNumber",
    ChangeAddress = "changeAddress",
    SymbolSearch = "symbolSearch",
    AcademyVideo = "AcademyVideo",
    SignalsDisclaimer = "SignalsDisclaimer",
    Support = "Support",
    NotificationDetails = "NotificationDetails",
    AccountDetails = "AccountDetails",
    TradingOpenAccount = "TradingOpenAccount",
    WelcomeInvestAccount = "WelcomeInvestAccount",
    TransferToCDF = "transferToCDF",
    TradingAccountSuccess = "TradingAccountSuccess",
    ChangeLanguage = "ChangeLanguage",
    EMT = "EMT",
    SignDeclaration = "SignDeclaration",
    RecuringDetails = "recuringDetails",
    Verification = "verification",
    VerificationMethods = "verificationMethods",
    PersonalInfo = "personalInfo",
    Logs = "logs",
    AccountTiersDetails = "AccountTiersDetails",
    SuccessDemoAccount = "successDemoAccount",
    AccessDemoAccount = "accessDemoAccount",

    // Promotions related
    EducationalTrades = "educationalTrades",
    RebatePlan = "rebatePlan",
    Vault = "vault",
    ProtectedPosition = "protectedPosition",
    SpreadCashback = "spreadCashback",
    InsurancePosition = "insurancePosition",
    RollingContract = "rollingContract",
    RollOverDiscount = "rollOverDiscount",
    CreditPromo = "creditPromo",
    Feedback = "feedback",
    PromotionsDetails = "promotionsDetails",
    VaultPromotionWarning = "vaultPromotionWarning",

    // Partners Accounts
    AddFollowedAccount = "addFollowedAccount",
    RemoveFollowedAccount = "removeFollowedAccount",
    AccountSearch = "accountSearch",

    // Education
    BrokerInfo = "BrokerInfo",
    BrokerTokenConnect = "BrokerTokenConnect",
    BrokerTokenDisconnect = "BrokerTokenDisconnect",
    FinancialNews = "FinancialNews",
    MarketVideo = "MarketVideo",
    CurrencyStrengthInfo = "CurrencyStrengthInfo",
    TrendAnalysisInfo = "TrendAnalysisInfo",
    CalendarEventDetails = "CalendarEventDetails",
    DateFilter = "DateFilter",
    Filters = "Filters",
    TradingSignalDetails = "TradingSignalDetails",

    // Tutorial
    TutorialLearnMore = "tutorialLearnMore",
}
export default TradingModalType;
