import React from "react";
import {makeTourPopoverStyles} from "component/tour/popover/styles";
import {PopoverContentProps, Tutorial} from "component/tour/types";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "enum/TranslationKey";
import {TutorialIDs} from "core/tutorial/TutorialIDs";
import WebUtil from "platform/util/WebUtil";
import {TutorialType} from "enum/TutorialType";
import {onTutorialFinish} from "component/lessons/tutorials/TutorialHandler";

const isMobile: boolean = WebUtil.isMobile();

export const howToOpenTrade: Tutorial = {
    id: TutorialType.HowToOpenTrade,
    title: TranslationKey.tourLesson1Title,
    onFinish:onTutorialFinish,
    steps: [
        {
            id: TutorialIDs.howToOpenTradeStep1,
            selector: '[id="_wt_mmnu_symbols"]',
            // outsideSelector: '.trading-menu',
            content: () => Translations.text(TranslationKey.tourLesson1Step1Title),
            position: isMobile ? "top" : "right",
            disableActions: true,
            cancelOnOutside: true,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean) => ({
                    left: isMobile ? "-8vw" : rtl ? "20px": "-50px",
                })),
            }
        },
        {
            id: TutorialIDs.howToOpenTradeStep2,
            selector: isMobile ? '[id="_wt_symbols_symbolsMyWatchlist"]' : ".trading-page-title.symbols .page-title",
            position: "bottom",
            disableActions: true,
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson1Step2Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson1Step2Text)}</div>
            </>,
            styles: {
                popover: makeTourPopoverStyles((rtl)=> ({
                    width: "280px",
                    top: isMobile ? "0px" : "-10px",
                    left: isMobile ? (rtl ? "-180px" : "15px") : (rtl ? "-190px": "0px"),
                })),
                popupArrow: (rtl) => ({
                    left: isMobile ? (rtl ? "initial" : "12%") : (rtl ? "initial" : "12%"),
                    right: isMobile ? (rtl ? "18%" : "initial") : (rtl ? "15%" : "initial"),
                }),
            }
        },
        {
            id: TutorialIDs.howToOpenTradeStep3,
            selector: '[class="btn-group"]',
            position: "bottom",
            disableActions: false,
            mutationObservables: ['[id="_wt_trading_manager"]', '[class="btn-group"]'],
            resizeObservables: ['[id="_wt_trading_manager"]', '[class="btn-group"]'],
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean)=> ({
                    top: isMobile ? "-8px" : "0px",
                    width: isMobile ? "318px" : "initial",
                    left: isMobile ? "calc(50% - 150px)" : rtl ? "88px" : "50px",
                })),
            },
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson1Step3Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson1Step3Text)}</div>
            </>,
        },
        {
            id: TutorialIDs.howToOpenTradeStep4,
            selector: '[id="_wt_sidebar_trade_units_amount"]',
            position: "bottom",
            disableActions: false,
            mutationObservables: ['[id="_wt_trading_manager"]', '[id="_wt_sidebar_trade_units_amount"]'],
            resizeObservables: ['[id="_wt_trading_manager"]', '[id="_wt_sidebar_trade_units_amount"]'],
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson1Step4Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson1Step4Text)}</div>
            </>,
            styles: {
                popover: makeTourPopoverStyles((rtl)=> ({
                    width: isMobile ? "332px" : "initial",
                    left: isMobile ? "calc(50% - 172px)" : "0",
                })),
                popupArrow: (rtl) => ({
                    left: rtl ? "initial" : "28%",
                    right: rtl ? "18%" : "initial",
                }),
            }
        },
        {
            id: TutorialIDs.howToOpenTradeStep5,
            selector: '.trade-title.order-type',
            position: "top",
            disableActions: false,
            mutationObservables: ['[id="_wt_trading_manager"]', '.trade-title.order-type'],
            resizeObservables: ['[id="_wt_trading_manager"]', '.trade-title.order-type'],
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson1Step5Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson1Step5Text)}</div>
            </>,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean)=> ({
                    width: isMobile ? "332px" : "initial",
                    left: isMobile ? "calc(50% - 172px)" : "0",
                })),
            }
        },
        {
            id: TutorialIDs.howToOpenTradeStep6,
            selector: ".wt_buy_sell_btn",
            position: "top",
            disableActions: false,
            mutationObservables: [".wt_buy_sell_btn"],
            content: () => <>
                <div className="tour-title">{Translations.text(TranslationKey.tourLesson1Step6Title)}</div>
                <div className="tour-text">{Translations.text(TranslationKey.tourLesson1Step6Text)}</div>
            </>,
            styles: {
                popover: makeTourPopoverStyles((rtl: boolean)=> ({
                    left: rtl ? "86px" : "60px",
                })),
            },
        },
    ],
}
