import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {AppReduxState, Banner, BarMetrics} from "core/redux/app/AppReduxState";
import {
    BannerPayload,
    DoFetchPlatformPropsType,
    DoGetURLType,
    RemoveBanner,
    RemoveBannerPayload,
    SetBanner,
    SetBannerMetrics,
    SetBarBottomMetrics,
    SetBarMetricsPayload,
    SetBarTopMetrics,
    SetConfiguredCountries,
    SetConfiguredCountriesPayload,
    SetMenuRouteAllowed,
    SetMenuRouteAllowedPayload,
    SetMenuToolAllowed,
    SetMenuToolAllowedPayload,
    SetModalHeaderMetrics,
    SetModalHeaderMetricsPayload,
    SetModalIconCloseMetrics,
    SetModalIconCloseMetricsPayload,
    SetModalTabsMetrics,
    SetModalTabsMetricsPayload,
    SetOpenSupportWidget,
    SetOpenSupportWidgetPayload,
    SetTradingModal,
    SetTradingModalPayload,
    ToggleMenu,
    ToggleMenuPayload,
    SetShowAccountDetailsModal,
    SetShowAccountDetailsModalPayload,
    SetTooltip,
    SetTooltipPayload,
    SetDynamicConfiguration,
    SetDynamicConfigurationPayload,
    BrandConfigurationChange, SetIsMultiBrandApp, SetIsDepositOutsideApp, RemoveBannerType,
    ToggleAccountDetailsSwiper,
    SetPartnerAccountMetrics, SetNotificationPermissionSuggestion, SetNativeUseDomain,
} from "core/redux/app/AppReduxActions";
import {TSMap} from "typescript-map";
import Utils from "platform/util/Utils";
import {RouteType} from "core/router/Routes";
import {OnAccountChanged, OnLoggedOut, OnLoggedOutType, SetAuthStatusType} from "core/redux/auth/AuthReduxActions";
import ToolsMenuType from "enum/ToolsMenuType";
import AppEngine from "core/engine/AppEngine";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import {InitChat, InitChatPayload, ShowChatType} from "platform/redux/core/CoreActions";
import {LoadLanguageType} from "platform/redux/translation/TranslationActions";
import Platform from "platform/Platform";
import {Configuration} from "core/configuration/Configuration";
import {BooleanPayload, StringPayload} from "core/redux/StoreActions";

const equalBarMetrics = (bm1: BarMetrics, bm2: BarMetrics): boolean => {
    if (bm1 && bm2) {
        return bm1.height === bm2.height;
    }
    return false;
};

const equalBanner = (b1: Banner<any>, b2: Banner<any>): boolean => {
    if (b1 && b2) {
        return b1.type === b2.type && b1.remoteId === b2.remoteId && Utils.isNull(b1.payload) && Utils.isNull(b2.payload);
    }
    return false;
};

const initialState = (): AppReduxState => {
    const config: Configuration = Platform.config<Configuration>();
    return {
        dynamicConfiguration: {},
        environmentConfigurations: [],
        countries: new TSMap<number, CountryInfo>(),
        namePerCountry: new TSMap<string, CountryInfo>(),
        availableMenuRoutes: [
            RouteType.Symbols,
            RouteType.MyTrades,
            RouteType.News,
            RouteType.EconomicCalendar,
            RouteType.Alerts,
            RouteType.Signals,
            RouteType.Lessons,
            RouteType.AccountSettings,
            RouteType.FollowedAccounts,
        ],
        blockedMenuRoutes: [
            ...((config.blockedMenuRoutes || []) as RouteType[]),
            RouteType.Lessons,
            RouteType.FollowedAccounts,
        ],
        modals: new TSMap(),
        activeTools: [
            ToolsMenuType.News,
            ToolsMenuType.EconomicCalendar,
            ToolsMenuType.Signals,
            ToolsMenuType.Alerts,
            ToolsMenuType.Lessons,
            ToolsMenuType.AccountSettings,
            ToolsMenuType.LiveChat,
            ToolsMenuType.TelegramChat,
            ToolsMenuType.Support,
            ToolsMenuType.Logs,
            ToolsMenuType.FollowedAccounts,
        ],
        blockedTools: [
            ...((config.blockedTools || []) as ToolsMenuType[]),
            ToolsMenuType.Lessons,
            ToolsMenuType.FollowedAccounts,
            ToolsMenuType.Support,
            ToolsMenuType.LiveChat,
        ],
        openMenu: true,
        openSupportWidget: false,
        bannerMetrics: {height: 0},
        topBar: {height: 0},
        bottomBar: {height: 0},
        partnerAccountMetrics: {height: 0},
        modalIconCloseMetrics: {height: 0},
        modalHeaderMetrics: {height: 0},
        modalTabsMetrics: {height: 0},
        showAccountDetailsModal: false,
        showAccountDetailsSwiper: false,
    };
};

export default class AppReducer extends Reducer<AppReduxState> {

    private static _instance: AppReducer;

    public static instance(): AppReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const appEngine: AppEngine = AppEngine.instance();
        this._middlewareActions.set("@@router5/TRANSITION_START", appEngine.onChangeRoute);
        this._middlewareActions.set(OnLoggedOutType, appEngine.onLoggedOut);
        this._middlewareActions.set(LoadLanguageType, appEngine.onLoadLanguage);
        this._middlewareActions.set(ShowChatType, appEngine.onShowChat);
        this._middlewareActions.set(SetAuthStatusType, appEngine.doSetAuthStatus);
        this._middlewareActions.set(RemoveBannerType, appEngine.onRemoveBanner);
        this._middlewareActions.set(DoGetURLType, appEngine.doGetUrl);
        this._middlewareActions.set(DoFetchPlatformPropsType, appEngine.doFetchPlatformProps);
    }

    public get name(): string {
        return "app";
    }

    protected setup(builder: ReducerBuilder<AppReduxState>): void {
        builder
            .init(initialState())
            .handle(BrandConfigurationChange, (state: AppReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    dynamicConfiguration: state.dynamicConfiguration,
                    environmentConfigurations: state.environmentConfigurations
                };
            })
            .handle(OnLoggedOut, (state: AppReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    dynamicConfiguration: state.dynamicConfiguration,
                    environmentConfigurations: state.environmentConfigurations,
                    blockedTools: state.blockedTools,
                    DepositOutsideApp: state.DepositOutsideApp
                };
            })
            .handle(OnAccountChanged, (state: AppReduxState, action: Action<any>) => {
                return Object.assign({}, state, {
                    dynamicConfiguration: state.dynamicConfiguration,
                    environmentConfigurations: state.environmentConfigurations,
                    banner: null,
                    bannerMetrics: {height: 0},
                    DepositOutsideApp: state.DepositOutsideApp
                });
            })
            .handle(SetDynamicConfiguration, (state: AppReduxState, {payload}: Action<SetDynamicConfigurationPayload>) => {
                return Object.assign({}, state, {
                    dynamicConfiguration: payload.configuration,
                    environmentConfigurations: payload.environmentConfigurations
                });
            })
            .handle(SetIsMultiBrandApp, (state: AppReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    IsMultiBrandApp: payload.value,
                });
            })
            .handle(SetIsDepositOutsideApp, (state: AppReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    DepositOutsideApp: payload.value,
                });
            })
            .handle(InitChat, (state: AppReduxState, action: Action<InitChatPayload>) => {
                const newState: AppReduxState = Utils.merge({}, state);
                newState.zeChatAvailable = true;
                newState.blockedTools = newState.blockedTools.filter((toolType: ToolsMenuType) => toolType !== ToolsMenuType.LiveChat);
                return newState;
            })
            .handle(SetMenuRouteAllowed, (state: AppReduxState, {payload}: Action<SetMenuRouteAllowedPayload>) => {
                const newState: AppReduxState = Utils.merge({}, state);
                if (payload.allowed) {
                    newState.blockedMenuRoutes = newState.blockedMenuRoutes.filter((routeType: RouteType) => routeType !== payload.routeType);
                } else {
                    newState.blockedMenuRoutes = [...newState.blockedMenuRoutes, payload.routeType];
                }
                return newState;
            })
            .handle(SetMenuToolAllowed, (state: AppReduxState, {payload}: Action<SetMenuToolAllowedPayload>) => {
                const newState: AppReduxState = Utils.merge({}, state);
                if (payload.allowed) {
                    newState.blockedTools = newState.blockedTools.filter((toolType: ToolsMenuType) => toolType !== payload.toolType);
                } else {
                    newState.blockedTools = [...newState.blockedTools, payload.toolType];
                }
                return newState;
            })
            .handle(SetConfiguredCountries, (state: AppReduxState, {payload}: Action<SetConfiguredCountriesPayload>) => {
                const newState: AppReduxState = Utils.merge({}, state);
                newState.countries = payload.countries;
                newState.namePerCountry = payload.namePerCountry;
                return newState;
            })
            .handle(SetTradingModal, (state: AppReduxState, {payload}: Action<SetTradingModalPayload<any>>) => {
                const newState: AppReduxState = Utils.merge({}, state);
                if (payload.info?.visible) {
                    newState.modals.set(payload.tradingModalType, payload.info);
                } else {
                    newState.modals.delete(payload.tradingModalType);
                }
                return newState;
            })
            .handle(ToggleMenu, (state: AppReduxState, {payload}: Action<ToggleMenuPayload>) => {
                this._logger.debug(`Set menu collapsed: ${payload.collapse}`);
                return Object.assign({}, state, {
                    openMenu: !payload.collapse
                })
            })
            .handle(SetShowAccountDetailsModal, (state: AppReduxState, {payload}: Action<SetShowAccountDetailsModalPayload>) => {
                return Object.assign({}, state, {
                    showAccountDetailsModal: payload.show
                });
            })
            .handle(SetBanner, (state: AppReduxState, {payload}: Action<BannerPayload>) => {
                if (!equalBanner(state.banner, payload.banner)) {
                    return Object.assign({}, state, {
                        banner: payload.banner
                    });
                }
                return state;
            })
            .handle(RemoveBanner, (state: AppReduxState, {payload}: Action<RemoveBannerPayload>) => {
                const newState: AppReduxState = Utils.merge({}, state);
                newState.banner = null;
                newState.bannerMetrics = {height: 0};
                return newState;
            })
            .handle(SetBannerMetrics, (state: AppReduxState, {payload}: Action<SetBarMetricsPayload>) => {
                if (!equalBarMetrics(state.bannerMetrics, payload.metrics)) {
                    const newState: AppReduxState = Utils.merge({}, state);
                    newState.bannerMetrics = {...state.bannerMetrics, ...payload.metrics};
                    return newState;
                }
                return state;
            })
            .handle(SetBarTopMetrics, (state: AppReduxState, {payload}: Action<SetBarMetricsPayload>) => {
                if (!equalBarMetrics(state.topBar, payload.metrics)) {
                    const newState: AppReduxState = Utils.merge({}, state);
                    newState.topBar = {...state.topBar, ...payload.metrics};
                    return newState;
                }
                return state;
            })
            .handle(SetBarBottomMetrics, (state: AppReduxState, {payload}: Action<SetBarMetricsPayload>) => {
                if (!equalBarMetrics(state.bottomBar, payload.metrics)) {
                    const newState: AppReduxState = Utils.merge({}, state);
                    newState.bottomBar = {...state.bottomBar, ...payload.metrics};
                    return newState;
                }
                return state;
            })
            .handle(SetModalIconCloseMetrics, (state: AppReduxState, {payload}: Action<SetModalIconCloseMetricsPayload>) => {
                if (!equalBarMetrics(state.modalIconCloseMetrics, payload.metrics)) {
                    const newState: AppReduxState = Utils.merge({}, state);
                    newState.modalIconCloseMetrics = {...state.modalIconCloseMetrics, ...payload.metrics};
                    return newState;
                }
                return state;
            })
            .handle(SetModalHeaderMetrics, (state: AppReduxState, {payload}: Action<SetModalHeaderMetricsPayload>) => {
                if (!equalBarMetrics(state.modalHeaderMetrics, payload.metrics)) {
                    const newState: AppReduxState = Utils.merge({}, state);
                    newState.modalHeaderMetrics = {...state.modalHeaderMetrics, ...payload.metrics};
                    return newState;
                }
                return state;
            })
            .handle(SetModalTabsMetrics, (state: AppReduxState, {payload}: Action<SetModalTabsMetricsPayload>) => {
                if (!equalBarMetrics(state.modalTabsMetrics, payload.metrics)) {
                    const newState: AppReduxState = Utils.merge({}, state);
                    newState.modalTabsMetrics = {...state.modalTabsMetrics, ...payload.metrics};
                    return newState;
                }
                return state;
            })
            .handle(SetOpenSupportWidget, (state: AppReduxState, {payload}: Action<SetOpenSupportWidgetPayload>) => {
                return Object.assign({}, state, {
                    openSupportWidget: payload.visible
                });
            })
            .handle(SetTooltip, (state: AppReduxState, {payload}: Action<SetTooltipPayload>) => {
                return Object.assign({}, state, {
                    tooltip: payload.tooltip
                });
            })
            .handle(ToggleAccountDetailsSwiper, (state: AppReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    showAccountDetailsSwiper: payload.value
                });
            })
            .handle(SetPartnerAccountMetrics, (state: AppReduxState, {payload}: Action<SetBarMetricsPayload>) => {
                if (!equalBarMetrics(state.bottomBar, payload.metrics)) {
                    const newState: AppReduxState = Utils.merge({}, state);
                    newState.partnerAccountMetrics = {...state.partnerAccountMetrics, ...payload.metrics};
                    return newState;
                }
                return state;
            })
            .handle(SetNotificationPermissionSuggestion, (state: AppReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    notificationPermissionSuggestion: payload.value
                });
            })
            .handle(SetNativeUseDomain, (state: AppReduxState, {payload}: Action<StringPayload>) => {
                return Object.assign({}, state, {
                    nativeUseDomain: payload.value
                });
            });
    }
}
