import React, {useEffect, MouseEventHandler, useCallback} from 'react'
import { Observables } from './utils/Observables'
import { Popover } from './popover/Popover'
import { useSizes } from './hooks'
import { TourProps, Padding } from './types'
import {PopoverContent} from './popover/PopoverContent'


const Tour: React.FC<TourProps> = ({
  tutorial,
  currentStep,
  setCurrentStep,
  setIsOpen,
  steps = [],
  setSteps,
  styles: globalStyles = {},
  scrollSmooth,
  afterOpen,
  beforeClose,
  padding = 10,
  position,
  onClickMask,
  onClickHighlighted,
  keyboardHandler,
  className = 'reactour__popover',
  clipId,
  maskId,
  disableInteraction,
  // disableFocusLock,
  disableKeyboardNavigation,
  inViewThreshold,
  disabledActions,
  setDisabledActions,
  disableWhenSelectorFalsy,
  rtl,
  accessibilityOptions = {
    closeButtonAriaLabel: 'Close Tour',
    showNavigationScreenReaders: true,
  },
  ContentComponent,
  Wrapper,
  meta,
  setMeta,
  autoupdatePositionTime,
  onTransition = () => {
    // const arr: [number, number] = [prev.x, prev.y]
    return 'center'
  },
    cancelTutorial,
    tutorialStarted,
    setTutorialStarted,
  ...popoverProps
}) => {
  const step = steps[currentStep]
  const styles = { ...globalStyles, ...step?.styles }

  const {
    sizes,
    transition,
    observableRefresher,
    isHighlightingObserved,
    target,
  } = useSizes(step, {
    block: 'center',
    behavior: scrollSmooth ? 'smooth' : 'auto',
    inViewThreshold,
  })

  useEffect(() => {
    if (afterOpen && typeof afterOpen === 'function') {
      afterOpen(target)
    }
    return () => {
      if (beforeClose && typeof beforeClose === 'function') {
        beforeClose(target)
      }
    }
  }, [])

  const { maskPadding , popoverPadding, wrapperPadding } = getPadding(
    step?.padding ?? padding
  );

  useEffect(() => {
    if (step?.action && typeof step?.action === 'function') {
      step?.action(target)
    }

    if (step?.disableActions !== undefined) {
      setDisabledActions(step?.disableActions)
    }

    return () => {
      if (step?.actionAfter && typeof step?.actionAfter === 'function') {
        step?.actionAfter(target)
      }
    }
  }, [step])

  const memoizedCallback = useCallback(
      (event: MouseEvent) => {
        const outsideElement = step?.outsideSelector ? document.querySelector(step.outsideSelector) : target;
        if (outsideElement && !outsideElement.contains(event.target as Element)) {
          if(step.cancelOnOutside) {
            setTutorialStarted(false);
            setIsOpen(false);
            setCurrentStep(0);
          }
          if(step.hideOnOutside) {
            setIsOpen(false);
          }
        }
      },
      [target, step]
  );

  useEffect(() => {
      document.addEventListener("click", memoizedCallback, true);
      document.addEventListener("ontouchstart", memoizedCallback, true);

      return () => {
        document.removeEventListener("click", memoizedCallback, true);
        document.removeEventListener("ontouchstart", memoizedCallback, true);
      };
  }, [step, target]);


  // bug
  // const popoverPosition = transition
  //   ? onTransition
  //   : step?.position ? step?.position : position

 const popoverPosition = step?.position ? step?.position : position;

  const TourWrapper = Wrapper ? Wrapper : React.Fragment

  useEffect(() => {
    let time;
    if (step && autoupdatePositionTime && observableRefresher) {
      clearInterval(time);
      time = setInterval(() => {
        observableRefresher();
      }, autoupdatePositionTime);
    }
    return () => {
      clearInterval(time);
    }
  }, [autoupdatePositionTime, step, observableRefresher]);

  return step ? (
    <TourWrapper>
      <Observables
        mutationObservables={step?.mutationObservables}
        resizeObservables={step?.resizeObservables}
        refresh={observableRefresher}
      />

      {(!disableWhenSelectorFalsy || target) && (
        <Popover
          sizes={sizes}
          styles={styles}
          position={popoverPosition}
          padding={popoverPadding as number}
          aria-labelledby={accessibilityOptions?.ariaLabelledBy}
          className={className}
          refresher={currentStep}
          rtl={rtl}
        >
          {ContentComponent ? (
            <ContentComponent
              tutorial={tutorial}
              styles={styles}
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
              setIsOpen={setIsOpen}
              steps={steps}
              accessibilityOptions={accessibilityOptions}
              disabledActions={disabledActions}
              transition={transition}
              isHighlightingObserved={isHighlightingObserved}
              rtl={rtl}
              tutorialStarted={tutorialStarted}
              setTutorialStarted={setTutorialStarted}
              {...popoverProps}
            />
          ) : (
            <PopoverContent
              tutorial={tutorial}
              styles={styles}
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
              setIsOpen={setIsOpen}
              steps={steps}
              setSteps={setSteps}
              accessibilityOptions={accessibilityOptions}
              disabledActions={disabledActions}
              transition={transition}
              isHighlightingObserved={isHighlightingObserved}
              rtl={rtl}
              meta={meta}
              setMeta={setMeta}
              tutorialStarted={tutorialStarted}
              setTutorialStarted={setTutorialStarted}
              {...popoverProps}
            />
          )}
        </Popover>
      )}
    </TourWrapper>
  ) : null
}

export {Tour}

export interface CustomCSS extends React.CSSProperties {
  rx: number
}

function getPadding(padding?: Padding) {
  if (typeof padding === 'object' && padding !== null) {
    return {
      maskPadding: padding.mask,
      popoverPadding: padding.popover,
      wrapperPadding: padding.wrapper,
    }
  }
  return {
    maskPadding: padding,
    popoverPadding: padding,
    wrapperPadding: 0,
  }
}

